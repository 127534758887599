import React, {Component} from 'react';
import Popup from 'reactjs-popup';
import Activity1 from './V1ActivityButton/V1ActivityButton';
import Activity2 from './V2ActivityButton/V2ActivityButton';
import DeviceCount from '../DeviceCount/deviceCount'
import * as actions from '../redux/actions';
import './table.css';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DateParsingUtil from "../../../../utils/DateParsingUtil";
import { getMacDigitsFromDeviceId } from '../../../../commonFunctions/updateDeviceId';
import styled from 'styled-components';

let arrowMap = new Map(
    [["device_id", ''],
        ["last_hb_at", ''],
        ["is_online", ''],
        ["room_no", ''],
        ["is_checked_in", ''],
        ["has_staff_entry", ''],
        ["relay_state", ''],
        ["is_enabled", ''],
        ['desired_relay_state','']]
);

class Table extends Component {

    sortBy = async (key) => {
        let i;
        let copyArray = [];
        let len = this.props.roomData.length;
        for(i=0;i<len;i++) {
            copyArray[i] = this.props.roomData[i];
        }
        let payload = {
          room_details : [],
          device_type : this.props.deviceType,
          hotel_id : this.props.hotelId
        }
        let arrayKey = Array.from(arrowMap.keys());
        if (key === arrayKey[arrayKey.indexOf(key)]) {
            if (arrowMap.get(key) === '') {
                arrowMap.set(key, true);
                copyArray.sort(this.ascendingOrder(key));
                payload.room_details = copyArray;
                await this.props.setRoomList(payload);
            } else if (arrowMap.get(key) === true) {
                arrowMap.set(key, false);
                copyArray.sort(this.descendingOrder(key));
                payload.room_details = copyArray;
                await this.props.setRoomList(payload);
            } else if (arrowMap.get(key) === false) {
                arrowMap.set(key, true);
                copyArray.sort(this.ascendingOrder(key));
                payload.room_details = copyArray;
                await this.props.setRoomList(payload);
            }
        }
    };

    ascendingOrder = key => {
        if (key === "room_no") {
            return function (a, b) {
                if (Number(a[key]) < Number(b[key])) {
                    return -1;
                }
                if (Number(a[key]) > Number(b[key])) {
                    return 1;
                }
                return 0;
            };
        } else {
            return function (a, b) {
                if (a[key] < b[key]) {
                    return -1;
                }
                if (a[key] > b[key]) {
                    return 1;
                }
                return 0;
            };
        }
    };

    descendingOrder = key => {
        if (key === "room_no") {
            return function (a, b) {
                if (Number(a[key]) > Number(b[key])) {
                    return -1;
                }
                if (Number(a[key]) < Number(b[key])) {
                    return 1;
                }
                return 0;
            };
        } else {
            return function (a, b) {
                if (a[key] > b[key]) {
                    return -1;
                }
                if (a[key] < b[key]) {
                    return 1;
                }
                return 0;
            };
        }
    };

    handleRefresh = () => {
        let hotelId = this.props.selectedHotel.hotelId;
        if (hotelId !== null && hotelId !== undefined && hotelId !== "") {
            this.props.getRooms(hotelId);
        } else {
            alert("Please select a hotel first");
        }
    };
    arrow = keyValue => (
        arrowMap.get(keyValue)
            ? <FontAwesomeIcon icon="sort-alpha-down" style={{color: 'grey'}} className="arrow-sort"/>
            : <FontAwesomeIcon icon="sort-alpha-up" style={{color: 'grey'}} className="arrow-sort"/>
    );

    render() {

    const TableRowWrapper = styled.tr`
        pointer-events: 'auto'
    `;
    
    if(this.props.roomData.length!==0) {
      return( 
          <div className="tableAlign">
              <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
              <div>
              <div className="table-option-heading">
                <h3 className="hotel-name">{this.props.selectedHotel.hotelName} | {this.props.selectedHotel.hotelId}</h3>
                <h3 className="hotel-name">HOTEL TYPE: {this.props.selectedHotel.hotelType}</h3>
                <h1>{
                        this.props.deviceType === '1-ch-v2' ? 
                            "Version 2 Device is installed" 
                        : this.props.device_type === '1-ch-v25' ?
                            "Version 2.5 Device is installed"
                            : this.props.device_type === 'multiple' || this.props.device_type === 'Multiple' ? 
                                    "Devices of multiple versions are installed"
                                :
                                    "Version 1 Device is installed"
                    }
                </h1>
              </div>
              <DeviceCount />
              {/* <table className={tableLayoutClass()}> */}
              <div className="hotel-Admin-layout-container-layout">
              <div className="hotel-Admin-layout-container">
                <div className="hotel-Admin-layout-container-header">
                    <button className="refresh-button" onClick={this.handleRefresh}>
                        Refresh <i className="fa fa-refresh"/>
                    </button>
                </div>
                <table className="hotel-Admin-layout">
                    
                    <thead className="tableHead">
                    <tr>
                        {<td className="table-heading" onClick={() => this.sortBy('room_no')}>Room
                            No {this.arrow('room_no')}</td>}
                        {<td className="table-heading" onClick={() => this.sortBy('device_id')}>Device
                            Id {this.arrow('device_id')}</td>}
                        {<td className="table-heading"
                                onClick={() => this.sortBy('is_enabled')}>Commission{this.arrow('is_enabled')}</td>}
                        {<td className="table-heading"
                                onClick={() => this.sortBy('is_connected')}>Online {this.arrow('is_online')}</td>}
                        {<td className="table-heading" onClick={() => this.sortBy('desired_relay_state')}>Desired
                            Relay State{this.arrow('desired_relay_state')}</td>}
                        {<td className="table-heading" onClick={() => this.sortBy('is_checked_in')}>Room
                            Status {this.arrow('is_checked_in')}</td>}
                        {<td className="table-heading" onClick={() => this.sortBy('relay_state')}>Light
                            Status{this.arrow('relay_state')}</td>}
                        {<td className="table-heading" onClick={() => this.sortBy('relay_state')}>keycard
                            Status{this.arrow('relay_state')}</td>}
                        {<td className="table-heading" onClick={() => this.sortBy('has_staff_entry')}>Staff
                            Entry{this.arrow('has_staff_entry')}</td>}
                        {<td className="table-heading" onClick={() => this.sortBy('last_hb_at')}>Last Heart Beat
                            Time{this.arrow('last_hb_at')}</td>}
                    </tr>
                    </thead>
                        <tbody className="body-layout">
                        {this.props.roomData.map(tableRow => {
                            return (
                                <Popup key={tableRow.room_no} trigger={
                                    <TableRowWrapper >
                                        {<td width="8%" className="tableColumn">{tableRow.room_no}</td>}
                                        {<td className="tableColumn">
                                            {getMacDigitsFromDeviceId(tableRow.device_id)}
                                        </td>}
                                        {<td className="tableColumn">{tableRow.is_enabled ? "true" : "false"}</td>}
                                        {<td className="tableColumn">{tableRow.is_connected ?
                                            <p style={{color: 'green'}}>Yes</p> :
                                            <p style={{color: 'red'}}>No</p>}</td>}
                                        {<td className="tableColumn">{tableRow.desired_relay_state}</td>}
                                        {<td className="tableColumn">{tableRow.is_checked_in ?
                                            <p style={{color: 'green'}}>Checked-in</p> :
                                            <p style={{color: 'red'}}>Checked-out</p>}</td>}
                                        {<td className="tableColumn">{tableRow.relay_state === '0' ?
                                            <p style={{color: 'red'}}>Off</p> :
                                            <p style={{color: "green"}}>On</p>}</td>}
                                        {<td className="tableColumn">{tableRow.keycard_state === '0' ?
                                            <p style={{color: 'red'}}>Removed</p> :
                                            <p style={{color: "green"}}>Inserted</p>}</td>}
                                        {<td
                                            className="tableColumn">{tableRow.has_staff_entry ? "true" : "false"}</td>}
                                        {<td className="tableColumn" style={{fontSize: "15px"}}>
                                            {DateParsingUtil.changeEpochToStandard(tableRow.last_hb_at).toString().slice(0, -34)}
                                        </td>}
                                    </TableRowWrapper>
                                }
                                        modal
                                >
                                    {close => (
                                        <div className="activityLayout">
                                            <div className="activity-heading">
                                                <div className="activity-label">Activity Button
                                                    <button className="close-button" onClick={close}>
                                                        <i className="fa fa-close"/>
                                                    </button>
                                                </div>
                                            </div>
                                            {this.props.deviceType === '1-ch-v2' ?
                                                <Activity2
                                                    hotelId={this.props.hotelId.trim()}
                                                    bookingId={tableRow.booking_id}
                                                    isCommissioned={tableRow.is_enabled}
                                                    roomId={tableRow.room_id}
                                                    roomNo={tableRow.room_no}
                                                    deviceId={tableRow.device_id}
                                                    wifiSsid={tableRow.wifi_ssid}
                                                    wifiPassword={tableRow.wifi_password}
                                                    deviceOnline={tableRow.is_connected}/>
                                                :
                                                <Activity1
                                                    hotelId={this.props.hotelId.trim()}
                                                    roomId={tableRow.room_id}
                                                    roomNo={tableRow.room_no}
                                                    deviceId={tableRow.device_id}
                                                    wifiSsid={tableRow.wifi_ssid}
                                                    wifiPassword={tableRow.wifi_password}/>
                                            }

                                        </div>
                                    )}
                                </Popup>
                            )
                        })}
                        </tbody>
                </table>
                </div>
                    </div>
                    {/* } */}
                </div>
                </div>
            )
        } else {
            return (
                <div></div>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        hotelId: state.hotel.hotelData.hotelId,
        roomData: state.hotel.hotelData.roomList,
        deviceType: state.hotel.hotelData.deviceType,
        selectedHotel: state.hotel.selectedHotel
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getRooms: async (hotelId) => await dispatch(actions.getRooms(hotelId)),
        clearHotelAndRooms: async () => dispatch(actions.clearHotelAndRooms()),
        setRoomList: async (payload) => dispatch(actions.getRoomList(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
