import { combineReducers } from "redux";

import searchBarReducer from "./../Components/Switch/_utilComponents/SearchBar/redux/reducer";
import hotelDetailsReducer from "./../Components/Switch/HotelDetails/redux/reducer";
import decommissionReducer from "./../Components/Switch/Decommission/redux/reducer";
import bleAppPasswordReducer from "./../Components/Switch/BleAppPassword/redux/reducer";
import installationReducer from "../Components/Switch/HotelInstallationStatus/redux/reducer";
import sonoffReducer from "./../Components/Switch/SonoffMac/redux/reducer";

import loginPageReducer from "./../Components/Login/redux/reducer";

import mortiseReducer from "./../Components/UKSmartlock/MortiseLock/redux/reducer";
import ukHotelsReducer from "./../Components/UKSmartlock/UKHotelDetails/redux/reducer";
import smartlockButtonsReducer from "./../CommonUI/SmartlockButtons/redux/reducer";
import ovhHotelDetailsReducer from "./../Components/VacationHomes/HotelDetails/redux/reducer";
import ovhDeviceDetailsReducer from "./../Components/VacationHomes/DeviceDetails/redux/reducer";
import ovhOyoLifeDetailsReducer from "./../Components/VacationHomes/OyoLifeDashboard/redux/reducer";
import ovhOyoLifeEspDetailsReducer from "./../Components/VacationHomes/OyoLifeEspDetails/redux/reducer";
import smartRoomReducer from "./../Components/SmartRoom/HomePage/redux/reducer";

const allReducers = combineReducers({
  // Common reducers
  login: loginPageReducer,

  // Switch reducers
  onLoad: searchBarReducer,
  hotel: hotelDetailsReducer,
  decomm: decommissionReducer,
  bleApp: bleAppPasswordReducer,
  installation: installationReducer,
  sonoff: sonoffReducer,

  // Smartlock reducers
  smartlockButtons : smartlockButtonsReducer,
  ukHotelDetails: ukHotelsReducer,
  mortise: mortiseReducer,

  //ovh
  ovhHotelDetails: ovhHotelDetailsReducer,
  ovhDeviceDetails: ovhDeviceDetailsReducer,
  ovhOyoLifeDetails: ovhOyoLifeDetailsReducer,
  ovhOyoLifeEspDetails: ovhOyoLifeEspDetailsReducer,

  //smartRoom
  smartRoomData: smartRoomReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "CLEAR") {
    localStorage.clear();
    state = undefined;
  } else if (action.type === "SET_STATE") {
    return {
      ...state,
      login: action.payload,
    };
  }
  return allReducers(state, action);
};

export default rootReducer;
