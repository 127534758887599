import * as actions from "./actionTypes";
import RESTApi from "../../../../utils/RESTApi";
import urlConstant from "../../../../Constants/urlConstant";
import { SmartRoomHeaders } from "../../../../Constants/HeadersConstants";
import logger from "utils/logger"

const _startFetchingHotelDetails = () => {
    return {
        type: actions.FETCH_HOTEL_DETAILS
    }
};

const _hotelDetailsFetched = (payload) => {
    return {
        type: actions.HOTEL_DETAILS_FETCHED,
        payload: payload
    }
};

const _errorFetchingHotelDetails = () => {
    return {
        type: actions.ERROR_HOTEL_DETAILS_FETCH
    }
};

const _startNewCheckInRequest = () => {
    return {
        type: actions.SMARTROOM_NEW_CHECKIN
    }
};

const _newCheckInRequestSuccess = () => {
    return {
        type: actions.SMARTROOM_NEW_CHECKIN_SUCCESS,
    }
};

const _newCheckInRequestError = () => {
    return {
        type: actions.ERROR_SMARTROOM_NEW_CHECKIN
    }
};

const _startNewCheckOutRequest = () => {
    return {
        type: actions.SMARTROOM_CHECKOUT
    }
};

const _newCheckOutRequestSuccess = () => {
    return {
        type: actions.SMARTROOM_CHECKOUT_SUCCESS
    }
};

const _newCheckOutRequestError = () => {
    return {
        type: actions.ERROR_SMARTROOM_CHECKOUT_SUCCESS
    }
};

const _startFetchingHotels = () => {
    return {
        type: actions.FETCH_HOTEL_LIST
    }
};

const _hotelFetchingSuccess = (payload) => {
    return {
        type: actions.HOTEL_LIST_FETCHED,
        payload: payload
    }
};

const _hotelFetchingError = () => {
    return {
        type: actions.ERROR_HOTEL_LIST_FETCH
    }
};


export const getAllHotels = () => {

    return async function (dispatch) {
        try {
            let response;
            dispatch(_startFetchingHotels());

            let relativeURL = urlConstant.APIURL.SMART_ROOM_GET_HOTELS;
            response = await RESTApi.GET(urlConstant.APIURL.SMART_ROOM_BASE_URL, relativeURL, SmartRoomHeaders);
            if (response.status === 200 && response.data.success) {
                dispatch(_hotelFetchingSuccess(response.data));
            } else {
                dispatch(_hotelFetchingError());
            }
        } catch (error) {
            dispatch(_hotelFetchingError());
        }
    }
};

export const getHotelDetails = (payload) => {

    return async function (dispatch) {
        try {
            let response;
            dispatch(_startFetchingHotelDetails());
        
            let relativeURL = urlConstant.APIURL.SMART_ROOM_GET_HOTEL_DETAILS + "?hotel-id=" + payload.hotelId;
            response = await RESTApi.GET(urlConstant.APIURL.SMART_ROOM_BASE_URL, relativeURL, SmartRoomHeaders);
            if (response.status === 200 && response.data.success) {
                dispatch(_hotelDetailsFetched(response.data));
            } else {
                dispatch(_errorFetchingHotelDetails());
            }
        } catch (error) {
            dispatch(_errorFetchingHotelDetails())
        }
    }
};

export const newGuestCheckIn = (payload) => {

    return async function (dispatch) {
        try {
            let response;
            dispatch(_startNewCheckInRequest());

            let httpPayload = {
                hotel_id: payload.hotelId,
                room_no: payload.roomNo,
                name: payload.checkInName
            };
            let relativeUrl = urlConstant.APIURL.SMART_ROOM_CHECK_IN;
            response = await RESTApi.POST(urlConstant.APIURL.SMART_ROOM_BASE_URL, relativeUrl, httpPayload, SmartRoomHeaders);
            if (response.status === 200 && response.data.success) {
                dispatch(_newCheckInRequestSuccess());
            } else {
                dispatch(_errorFetchingHotelDetails());
            }
        } catch (error) {
            logger.log("Error is: " + error);
            dispatch(_newCheckInRequestError());
        }
    }
};

export const guestCheckOut = (payload) => {

    return async function (dispatch) {
        try {
            let response;
            dispatch(_startNewCheckOutRequest());

            let httpPayload = {
                hotel_id: payload.hotelId,
                room_no: payload.roomNo,
                name: payload.checkInName
            };
            let relativeUrl = urlConstant.APIURL.SMART_ROOM_CHECK_OUT;
            response = await RESTApi.POST(urlConstant.APIURL.SMART_ROOM_BASE_URL, relativeUrl, httpPayload, SmartRoomHeaders);
            if (response.status === 200 && response.data.success) {
                dispatch(_newCheckOutRequestSuccess());
            } else {
                dispatch(_errorFetchingHotelDetails());
            }
        } catch (error) {
            dispatch(_newCheckOutRequestError());
        }
    }
};
