import React from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import * as actions from '../HotelDetails/redux/actions';
import GenStaffCode from '../../../CommonUI/SmartlockButtons/genStaffCode';
import './pspDetails.css';
import ViewCode from '../../../CommonUI/SmartlockButtons/viewCode';

class PspDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredItems: []
    };
  }

  componentDidMount = async () => {
    await this.props.getHotelList(this.props.userId);

    if (this.props.hotelList.length !== 0) {
      this.setState({ filteredItems: this.props.hotelList });
    }
  };

  filterItems = (text) => {
    if (text !== '') {
      let tempFilteredItems = this.props.hotelList.filter(
        (item) =>
          (item.property_label && item.property_label.toLowerCase().includes(text)) ||
          (item.property_id && item.property_id.toLowerCase().includes(text)) ||
          (item.device_id && item.device_id.toLowerCase().includes(text)) ||
          (item.property_type && item.property_type.toLowerCase().includes(text))
      );
      this.setState({ filteredItems: tempFilteredItems });
    } else {
      this.setState({ filteredItems: this.props.hotelList });
    }
  };

  render() {
    const { filteredItems } = this.state;
    return (
      <div className="tableAlign">
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <div className="hotel-Admin-layout-container-layout">
          <div className="OVH-Staff-input-container">
            <input
              placeholder="Enter Property name , Property code , City or Country"
              type="text"
              className="input-bar"
              onChange={(e) => {
                this.filterItems(e.target.value.toLowerCase());
              }}
            />
            {this.props.isLoading ? (
              <div className="sk-circle">
                <div className="sk-circle1 sk-child"></div>
                <div className="sk-circle2 sk-child"></div>
                <div className="sk-circle3 sk-child"></div>
                <div className="sk-circle4 sk-child"></div>
                <div className="sk-circle5 sk-child"></div>
                <div className="sk-circle6 sk-child"></div>
                <div className="sk-circle7 sk-child"></div>
                <div className="sk-circle8 sk-child"></div>
                <div className="sk-circle9 sk-child"></div>
                <div className="sk-circle10 sk-child"></div>
                <div className="sk-circle11 sk-child"></div>
                <div className="sk-circle12 sk-child"></div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="hotel-Admin-layout-container">
            {filteredItems.length !== 0 || this.props.hotelList.length !== 0 ? (
              <>
                <table className="hotel-Admin-layout">
                  <thead className="tableHead">
                    <tr>
                      <td className="table-heading">Property name</td>
                      <td className="table-heading">Property Code</td>
                      <td className="table-heading">Lock ID</td>
                      <td className="table-heading">Generate staff code</td>
                      <td className="table-heading">View code</td>
                    </tr>
                  </thead>
                  <tbody className="body-layout">
                    {filteredItems.map((hotel) => {
                      return (
                        <tr className="trColumn" key={hotel.property_id}>
                          <td className="tableColumn">{hotel.property_label}</td>
                          <td className="tableColumn">{hotel.property_id}</td>
                          <td className="tableColumn">{hotel.device_id}</td>
                          <td className="tableColumn">
                            <Popup
                              trigger={
                                <button className="staff-code-button">Generate Staff Code</button>
                              }
                              modal
                            >
                              {(close) => (
                                <GenStaffCode
                                  close={close}
                                  propertyId={hotel.property_id}
                                  userId={this.props.email}
                                  months={6}
                                />
                              )}
                            </Popup>
                          </td>
                          <td className="tableColumn">
                            <Popup
                              trigger={
                                <button className="staff-code-button">
                                  View Currently Active Staff Code
                                </button>
                              }
                              modal
                            >
                              {(close) => (
                                <ViewCode
                                  close={close}
                                  propId={hotel.property_id}
                                  userId={this.props.email}
                                  codeType="staff"
                                />
                              )}
                            </Popup>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : this.props.isLoading ? (
              <div></div>
            ) : (
              <div>No hotel data available</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hotelList: state.ovhHotelDetails.hotels,
    email: state.login.email,
    userId: state.login.userId,
    response: state.ovhHotelDetails.response,
    isLoading: state.ovhHotelDetails.isLoading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHotelList: async (userId) => await dispatch(actions.getHotels(userId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PspDetails);
