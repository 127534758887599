import RESTApi from '../../../../utils/RESTApi';
import {APIURL} from '../../../../Constants/urlConstant';
import { AuthenticatedSmartlockHeaders } from '../../../../Constants/HeadersConstants';
import * as actionType from './actionTypes';
import logger from 'utils/logger';

const _setIsLoadingTrue = () => {
    return {
        type: actionType.SET_IS_LOADING_TRUE,
    }
}
const _setIsLoadingFalse = () => {
    return {
        type: actionType.SET_IS_LOADING_FALSE,
    }
}

const _setHotelData = (payload) => {
    return {
        type: actionType.FETCH_ALL_HOTELS_SUCCESS,
        payload: payload.hotels
    }
}

export const getHotels = (userId) => {
    return async function(dispatch) {
        try {
            dispatch(_setIsLoadingTrue());
            let relativeUrl = APIURL.RENDER_HOTEL_DETAILS_URL + "?userId=" + userId + "&hotelType=OYO-VACATION-HOME";
            let response = await RESTApi.GET(APIURL.SMARTLOCK_BASE_URL, relativeUrl, AuthenticatedSmartlockHeaders());
            logger.log(response);
            if(response.status===200) {
                dispatch(_setHotelData(response.data));
                dispatch(_setIsLoadingFalse());
            }
        }
        catch(error) {
            alert("Unable to fetch hotel details");
            dispatch(_setIsLoadingFalse());
        }
    }
}