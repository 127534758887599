const initialState = {
  email: "",
  authToken: "",
  product: "",
  errorMessage: "",
  isLoggedIn: null,
  userId: "",
  userProfileId: ""
};

export default initialState;
