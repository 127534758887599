import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from './redux/actions';

class DeleteCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingId: '',
      userId: ''
    };
  }

  componentDidMount() {
    this.props.clearResponse();
  }

  handleBookingIdChange = (e) => {
    let inputValue = e.target.value;
    const regexAlphaNumeric = /^\w+$/;
    if (!inputValue.match(regexAlphaNumeric)) {
      e.target.value = '';
    }
    if (inputValue !== null || inputValue !== undefined) {
      this.setState({
        bookingId: e.target.value
      });
    }
  };
  changeUserId = (e) => {
    if (e.target.value !== null || e.target.value !== undefined || e.target.value !== '') {
      this.setState({
        userId: e.target.value
      });
    }
  };
  handleSubmit = async () => {
    if (this.state.bookingId === null || this.state.bookingId === '') {
      alert('Please enter a booking ID');
    } else {
      let payload = {
        property_id: this.props.propId,
        room_no: this.props.roomNo,
        booking_id: this.state.bookingId,
        user_id: this.props.userId
      };
      await this.props.deleteCode(payload);
    }
  };
  render() {
    return (
      <>
        <div className="activityLayout">
          <div className="activity-heading">
            <div className="activity-label">
              Delete Code
              <button className="close-button" onClick={this.props.close}>
                <i className="fa fa-close" />
              </button>
            </div>
          </div>
          <div>
            <label className="m-5">Enter Booking ID </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              onChange={this.handleBookingIdChange}
            />
          </div>
          <br />
          {/* <div>
                        <label>User Id: </label>
                        <input type="text" onChange={this.changeUserId}/>
                    </div> */}
          <button
            type="submit"
            className="ml-5 flex-wrap text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
            onClick={this.handleSubmit}
          >
            Submit
          </button>
          {this.props.isLoading ? <div className="loader"></div> : <div></div>}
          {this.props.response ? (
            <div>
              {this.props.response.success ? (
                <p style={{ color: 'green' }}>{this.props.response.message}</p>
              ) : (
                <p style={{ color: 'red' }}>{this.props.response.message}</p>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.smartlockButtons.response,
    isLoading: state.smartlockButtons.isLoading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteCode: async (payload) => dispatch(actions.deleteCode(payload)),
    clearResponse: async () => dispatch(actions.clearResponse())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCode);
