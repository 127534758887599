import {decommHotel as initialState} from './state';
import * as actionType from './actionTypes';

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionType.SET_DECOMM_LOADING_TRUE:
            return{
                ...state,
                isLoading: true
            }
        case actionType.SET_DECOMM_LOADING_FALSE:
            return {
                ...state,
                isLoading: false
            }
        case actionType.LOAD_HOTEL_LIST_DECOMM_TAB:
            return {
                ...state,
                hotelList: action.payload
            }
        case actionType.LOAD_DECOMM_HOTEL_LIST:
            return {
                ...state,
                decommHotelList : action.payload
            }
        case actionType.SET_SELECTED_HOTEL_DECOMM:
            return {
                ...state,
                selectedHotel: action.payload
            }
        case actionType.SET_SELECTED_CITY_DECOMM:
            return {
                ...state,
                selectedCity : action.payload
            }
        case actionType.CLEAR_DECOMM_ROOM_LIST:
            return{
                ...state,
                hotelData : {
                    roomList: [],
                    deviceType: "",
                    hotelId : ""
                }
            }
        case actionType.LOAD_DECOMM_ROOM_LIST_SUCCESS:
            return {
                ...state,
                hotelData : {
                    roomList : action.payload.room_details,
                    deviceType : action.payload.device_type,
                    hotelId : action.payload.hotel_id
                }
            }
        case actionType.SET_DECOMM_ROOMS_RESPONSE:
            return {
                ...state,
                decommRoomsResponse : action.payload
            }
        case actionType.SET_RECOMM_ROOMS_RESPONSE:
            return {
                ...state,
                recommRoomsResponse : action.payload
            }
        case actionType.CLEAR_DECOMM_RECOMM_RESPONSE:
            return {
                ...state,
                decommRoomsResponse : "",
                recommRoomsResponse : ""
            }
        default:
            return state;
    }
}

export default reducer;