import React, {Component} from 'react';
import {Constants, SwitchMenuOptionsMap} from "../../../../Constants/Constants";
import { Link } from 'react-router-dom';
import urlConstant from "../../../../Constants/urlConstant";
import { decrypt } from "../../../../utils/EncryptDecryptUtil";
import './menubar.css';

class MenuBar extends Component {

    state = {
        unselectedName : "link-hover-switch",
        selectedName : "link-hover-switch-selected"
    }

    handleClick = (e) => {
        let elementList = document.getElementsByClassName('menubar-switch')[0];
        let classList = elementList.getElementsByClassName(this.state.selectedName);
        while(classList && classList.length) {
            classList[0].className = this.state.unselectedName
        }
        e.currentTarget.className = this.state.selectedName
    }

    render() {
        const userAccess = JSON.parse(decrypt(localStorage.getItem(Constants.localStorageKeys.USER_ACCESS)));
        // User can have multiple roles for same product, Choosing 0th because allowed roles should be sorted by priority
        // userAccess.features is actually an array of role object
        let menuList = [];
        if(userAccess !== undefined && userAccess !== null && userAccess[0] !== undefined && userAccess[0] !== null) {
            menuList = userAccess[0].menuList;
        }
        let menuOptions = (
            <ul className = "menubar-switch">
                {
                    menuList.map(eachOption => {
                        if(eachOption!=="Decommission" && eachOption!=="Mst-Number") {
                            return <li  key={eachOption} className={this.state.unselectedName} onClick={this.handleClick}>
                                        <Link to={SwitchMenuOptionsMap.get(eachOption)} className="link-hover-switch">{eachOption.replace(/-/g, " ")}</Link>
                                    </li>
                        } 
                        else if(eachOption==="Mst-Number") {
                            return <li key={eachOption}><a target="_blank" 
                                            href={SwitchMenuOptionsMap.get(eachOption)} 
                                            className="link-hover-switch"
                                            rel="noopener noreferrer">{eachOption.replace(/-/g, " ")}</a></li>
                        }
                        else {
                            return (
                            <li className="dropdown-menubar" key={eachOption}>{eachOption}
                                <ul className="dropdown-content">
                                    {/* This could be set according to subMenus in user_access,
                                    but since decommission has no actual deviation from this behaviour,
                                    thus not changed as of now, but if that changes, we might need to that here also. */}
                                    <li className={this.state.unselectedName} onClick={this.handleClick}><Link to={urlConstant.routeURL.URL_SWITCH_DECOMMISSION_ROOM} >By Room</Link></li>
                                    <li className={this.state.unselectedName} onClick={this.handleClick}><Link to={urlConstant.routeURL.URL_SWITCH_DECOMMISSION_HOTEL}>By Hotel</Link></li>
                                    <li className={this.state.unselectedName} onClick={this.handleClick}><Link to={urlConstant.routeURL.URL_SWITCH_DECOMMISSION_CITY}>By City</Link></li>
                                    <li className={this.state.unselectedName} onClick={this.handleClick}><Link to={urlConstant.routeURL.URL_SWITCH_DECOMMISSION_GLOBAL}>Global</Link></li>
                                    <li className={this.state.unselectedName} onClick={this.handleClick}><Link to={urlConstant.routeURL.URL_SWITCH_DECOMMISSION_HISTORY}>Decommission History</Link></li>
                                </ul>
                            </li> )
                        }
                    })
                }
            </ul>
        );
        return (
            <div>
                {menuOptions}
            </div>
        )
    }
}

export default MenuBar;