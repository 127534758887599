import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from './redux/actions';
import './sonoffMac.css';

class SonoffMac extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data : [
        {st_mac : "",ap_mac : "", index: 0},
        {st_mac : "",ap_mac : "", index: 1},
        {st_mac : "",ap_mac : "", index: 2},
        {st_mac : "",ap_mac : "", index: 3}
      ],
    }
  }

  componentDidMount(){
    this.props.clearResponse();
  }

  addRow = () => {
    this.setState({
      data : [
        ...this.state.data,
        {st_mac : "",ap_mac : "", index: this.state.data.length}
      ]
    })
  }

  handleSubmit = async () => {
    let flag = this.validateEntries();
    if(flag) {
      let st_macs = [];
      let ap_macs = [];
      for(let i=0; i<this.state.data.length; i++) {
        if(this.state.data[i].st_mac!=="" && this.state.data[i].ap_mac!=="") {
          st_macs.push(this.state.data[i].st_mac);
          ap_macs.push(this.state.data[i].ap_mac);
        }
      }
      let payload = {
        st_macs : st_macs,
        ap_macs : ap_macs
      }
      await this.props.submitMacs(payload);
      this.setState({
        data : [
          {st_mac : "",ap_mac : "", index: 0},
          {st_mac : "",ap_mac : "", index: 1},
          {st_mac : "",ap_mac : "", index: 2},
          {st_mac : "",ap_mac : "", index: 3}
        ]
      })
    }
    return;
  }

  handleFocusOut = (e) => {
    const input = e.target.value;
    const hexFlag = input.match(/[a-fA-F0-9]/g);
    if(hexFlag!==null && hexFlag.length!==12) {
      alert("Only the following chracters are allowed: a-f, A-F, 0-9")
    }
  }

  validateEntries = () => {
    for(let i=0; i<this.state.data.length; i++) {
      let row = this.state.data[i].index + 1;
      const hexFlagST = this.state.data[i].st_mac.match(/[a-fA-F0-9]/g);
      const hexFlagAP = this.state.data[i].ap_mac.match(/[a-fA-F0-9]/g);
      if(hexFlagST!==null && hexFlagST.length!==12) {
        alert("Only the following chracters are allowed in the MAC addresses: a-f, A-F, 0-9 in ST MAC");
        return 0;
      }
      if(hexFlagAP!==null && hexFlagAP.length!==12) {
        alert("Only the following chracters are allowed in the MAC addresses: a-f, A-F, 0-9 in AP MAC");
        return 0;
      }
      if(this.state.data[i].st_mac===null || this.state.data[i].ap_mac===null) {
        alert("Please input valid entries for ST MAC and AP MAC")
        return 0;
      }
      else if(this.state.data[i].st_mac!=="" && this.state.data[i].ap_mac==="") {
        alert("Please enter AP Mac for the corresponding SP Mac in row ", row);
        return 0;
      }
      else if(this.state.data[i].ap_mac!=="" && this.state.data[i].st_mac==="") {
        alert("Please enter ST Mac for the corresponding AP Mac in row ", row);
        return 0;
      }
      else if(this.state.data[i].st_mac!=="" && this.state.data[i].st_mac.length!==12) {
        alert("The ST Mac entered in row " + row + " is not of length 12");
        return 0;
      }
      else if(this.state.data[i].ap_mac!=="" && this.state.data[i].ap_mac.length!==12) {
        alert("The AP Mac entered in row " + row + " is not of length 12");
        return 0;
      }
    }
    return 1;
  }

  addMac = (e, index) => {
    // logger.log(e.target.value, e.target.name, index);
    if(e.target.value!==null || e.target.value!==undefined || e.target.value!=="") {
      let temp = [...this.state.data];
      if(e.target.name==="st_mac") {
        temp[index].st_mac = e.target.value;
      }
      else if(e.target.name==="ap_mac") {
        temp[index].ap_mac = e.target.value;
      }
      this.setState({
        data : temp
      });
    }
  }

  render() {
    return (
      <>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <div className="hotel-Admin-layout-container-layout">
        <div className="global-decomm-response">
          <b>
          If the MAC is ab:cd:ef:12:34:56, enter abcdef123456
          <br />
          Length of MAC address should be 12
          </b>
        </div>
        <div className="hotel-Admin-layout-container">
        <table className="hotel-Admin-layout">
          <thead className="tableHead">
            <tr>
              <td className="table-heading">ST Mac</td>
              <td className="table-heading">AP Mac</td>
            </tr>
          </thead>
          <tbody className="body-layout">
            {
              this.state.data.map(value => {
                return (
                  <tr className="trColumn" key={value.index}>
                    <td className="tableColumn">
                      <input onBlur={this.handleFocusOut} className="text-input" type="text" value={value.st_mac} name="st_mac" onChange={(e)=>{this.addMac(e, value.index)}}></input>
                    </td>
                    <td className="tableColumn">
                      <input onBlur={this.handleFocusOut} className="text-input" type="text" value={value.ap_mac} name="ap_mac" onChange={(e)=>{this.addMac(e, value.index)}}></input>
                    </td>
                  </tr>
                )
              })
            }
            <tr className="trColumn" onClick={this.addRow}>
              <td className="add-button">
                Add Row  <i className="fa fa-plus-square"></i>
              </td>
            </tr>
          </tbody>
        </table>
        <button className="submit-button" onClick={this.handleSubmit}>Submit</button>
        <br></br>
        <br></br>
        {
          this.props.response ? 
          <div className="global-decomm-response" style={{color: "green"}}>The list of MACs has been successfully added</div>
          :
          this.props.response!=="" ?
          <div className="global-decomm-response" style={{color: "red"}}>The above list of MACs couldn't be sent. Please add them to the CSV file and send</div>
          :
          <div></div>
        }
        {
          this.props.isLoading ?
          <div className="loader"></div>
          :
          <div></div>
        }
        </div>
       </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading : state.sonoff.isLoading,
    response : state.sonoff.response
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    submitMacs : async (payload) => await dispatch(actions.submitMacs(payload)),
    clearResponse : async () => await dispatch(actions.clearResponse())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SonoffMac);