import React, {Component} from 'react';
import Popup from 'reactjs-popup';
import {connect} from 'react-redux';
import * as actions from '../redux/actions';
import './decommGlobal.css';
import logger from "utils/logger"

class DecommGlobal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reason : ""
        }
    }

    componentDidMount() {
        this.props.clearResponse();
    }
    handleClick = async (close) => {
        if(this.state.reason === "") {
            alert("Enter reason to proceed")
        } else {
            let body = {
                "allProperties":true,
                "oyoIds":[],
                "cities":[],
                "reason": this.state.reason,
                "requester": this.props.email
            }
            logger.log(body);
            close();
            await this.props.decommissionGlobal(body);
        }
    }
    setReason = (e) => {
        logger.log(e.target.value);
        this.setState({
            reason: e.target.value
        })
    }
    render() {
        logger.log("In global decommission");
        return (
            <React.Fragment>
            <div className="search-container">
            </div>
            <div>
                <div className="decomm-button">
                    <Popup 
                    trigger={<button className="global-decomm-button" onClick={this.clearReason}>Global Decommission</button>} modal>
                        { close => (
                            <div className="activityLayout">
                                <div className="activity-heading">
                                    <div className="activity-label">Activity Button
                                        <button className="close-button" onClick={close}>
                                            <i className="fa fa-close"/>
                                        </button>
                                    </div>
                                </div>
                                If you are sure you want to decommission, enter reason :
                                <br></br>
                                <input className = "text-box" type="text" placeholder="Enter reason" onChange={(e)=>{this.setReason(e)}} value={this.state.reason}></input>
                                <br></br>
                                <div>
                                    <button className="submit-button-decomm" onClick={()=>{this.handleClick(close)}}>Decommission</button>
                                    <button className="cancel-button-decomm" onClick={close}>Cancel</button>
                                </div>
                            </div>
                        )}
                    </Popup>
                </div>
            </div>
            <br></br>
            <br></br>
            <div className="loading-container">
                {this.props.isLoading ? <div className="loader"></div> : <div></div>}
            </div>
            <div className="response-container">
                {this.props.decommResponse!=="" ? 
                <div className="global-decomm-response" style={{color: "green"}}>{this.props.decommResponse}</div>
                :
                <div></div>}
            </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        email : state.login.email,
        decommResponse : state.decomm.decommRoomsResponse,
        isLoading : state.decomm.isLoading
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        decommissionGlobal : async (payload) => dispatch(actions.decommissionHotels(payload)),
        clearResponse : async () => dispatch(actions.clearDecommRecommResponse())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DecommGlobal);