import React, { Component } from 'react';
import SearchBar from '../_utilComponents/SearchBar/searchBar';
import { findHotelType } from '../../../utils/HotelTypeUtil';
import { connect } from 'react-redux';
import Table from './Table/table';
import * as actions from './redux/actions';
import logger from 'utils/logger';

class HotelInstallationStatus extends Component {
  handleChange = (selectedOption) => {
    if (selectedOption === null) {
      logger.log('Field has been cleared');
    } else {
      logger.log(selectedOption.value.isEnabled);
      const hotelId = selectedOption.value.hotelId;
      const name = selectedOption.label;
      const isCommissioned = selectedOption.value.isEnabled;
      let type;
      if (selectedOption.value !== null && selectedOption.value !== undefined)
        type = findHotelType(selectedOption.value.hotelType);
      // this.setState({
      //     selectedHotelId: hotelId,
      //     selectedHotelName: name,
      //     selectedHotelType: type,
      //     selectedHotelComm: isCommissioned
      // });
      let selectedHotel = {
        hotelId: hotelId,
        hotelName: name,
        hotelType: type,
        isCommissioned: isCommissioned
      };
      this.props.setSelectedHotelRedux(selectedHotel);
      this.props.getRooms(hotelId);
    }
  };
  render() {
    return (
      <React.Fragment>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        ></link>
        <div className="search-container">
          <SearchBar handleChange={this.handleChange} />
          {this.props.isLoading ? <div className="loader"></div> : <div></div>}
          <Table />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    hotelData: state.hotel.onLoadHotelData,
    isLoading: state.installation.isLoading
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRooms: async (hotelId) => await dispatch(actions.getRooms(hotelId)),
    setSelectedHotelRedux: async (payload) => await dispatch(actions.setSelectedHotel(payload))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HotelInstallationStatus);
