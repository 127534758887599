import { Constants } from '../Constants/Constants';
import { encrypt, decrypt } from '../utils/EncryptDecryptUtil';

export const saveLoginCredentials = async (data) => {
  await localStorage.setItem(Constants.localStorageKeys.EMAIL, encrypt(data.email));
  await localStorage.setItem(Constants.localStorageKeys.AUTH_TOKEN, encrypt(data.authToken));
  await localStorage.setItem(Constants.localStorageKeys.PRODUCT, encrypt(data.productType));
  await localStorage.setItem(
    Constants.localStorageKeys.APP_VERSION,
    encrypt(Constants.APP_VERSION)
  );
  await localStorage.setItem(Constants.localStorageKeys.USERID, encrypt(data.userId));
  await localStorage.setItem(Constants.localStorageKeys.USER_PROFILE_ID, encrypt(data.userProfileId));
  await localStorage.setItem(Constants.localStorageKeys.SSO_TOKEN, encrypt(data.ssoToken));
  await localStorage.setItem(
    Constants.localStorageKeys.IOT_ROLES,
    encrypt(JSON.stringify(data.iotRoles))
  );
  await localStorage.setItem(
    Constants.localStorageKeys.USER_ACCESS,
    encrypt(JSON.stringify(data.userAccess))
  );
};

export const clearLocalStorage = async () => {
  await localStorage.clear();
};

export const checkLoggedIn = async () => {
  const email = await decrypt(localStorage.getItem(Constants.localStorageKeys.EMAIL));
  // const password = await decrypt(localStorage.getItem(Constants.localStorageKeys.PASSWORD));
  const authToken = await decrypt(localStorage.getItem(Constants.localStorageKeys.AUTH_TOKEN));
  const product = await decrypt(localStorage.getItem(Constants.localStorageKeys.PRODUCT));
  const role = await decrypt(localStorage.getItem(Constants.localStorageKeys.ROLE));
  const iotRoles = await decrypt(localStorage.getItem(Constants.localStorageKeys.IOT_ROLES));
  if (
    email != null /*&& password != null*/ &&
    authToken != null &&
    product != null &&
    (role != null || iotRoles != null)
  ) {
    return true;
  }
  return false;
};

export const saveSsoLoginAttempt = async (product) => {
  await localStorage.setItem(Constants.localStorageKeys.SSO_LOGIN_ATTEMPTED, encrypt(true));
  await localStorage.setItem(Constants.localStorageKeys.PRODUCT, encrypt(product));
};

export const clearSsoLoginAttempt = async () => {
  await localStorage.removeItem(Constants.localStorageKeys.SSO_LOGIN_ATTEMPTED);
  await localStorage.removeItem(Constants.localStorageKeys.PRODUCT);
};

export const clearSpecificKeys = (...keys) => {
  for (let i = 0; i < keys.length; i++) {
    if (localStorage.getItem(keys[i]) != null) {
      localStorage.removeItem(keys[i]);
    }
  }
};
