import initialState from "./state";
import * as actionTypes from "./actionTypes";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_LOGIN:
      return {
        ...state,
        errorMessage: "LOGGING IN"
      };
    case actionTypes.START_SSO_LOGIN:
      return {
        ...state,
        errorMessage: "LOGGING IN VIA SSO"
      };
    case actionTypes.SUCCESSFUL_LOGIN:
      return {
        ...state,
        email: action.payload.email,
        authToken: action.payload.authToken,
        userId: action.payload.userId,
        userProfileId: action.payload.userProfileId,
        errorMessage: "LOGIN SUCCESSFUL",
        product: action.payload.productType,
        isLoggedIn: true
      };
    case actionTypes.SUCCESSFUL_LOGIN_OVH:
      return {
        ...state,
        name: action.payload.name,
        email: action.payload.email,
        authToken: action.payload.authToken,
        userId: action.payload.userId,
        userProfileId: action.payload.userProfileId,
        errorMessage: "LOGIN SUCCESSFUL",
        product: action.payload.productType,
        isLoggedIn: true
      };
    case actionTypes.FAILED_LOGIN:
      return {
        ...state,
        errorMessage: action.payload.message,
        isLoggedIn: false
      };
    case actionTypes.LOGOUT_USER:
      return {
        ...state,
        isLoggedIn: false,
        errorMessage: "",
        email: "",
        authToken: "",
        password: "",
        product: ""
      };
    case actionTypes.FAILED_LOGOUT:
        return {
          ...state,
          errorMessage: action.payload.message
        };
    default:
      return state;
  }
};

export default reducer;
