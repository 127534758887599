import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import Login from './Components/Login/login';
import { checkLoggedIn, clearLocalStorage } from './utils/localStorageUtil';
import * as loginActions from './Components/Login/redux/actions';
import { decrypt } from './utils/EncryptDecryptUtil';
import urlConstant from './Constants/urlConstant';
import HomePage from './Components/UKSmartlock/Homepage/homepage';
import SwitchHomePage from './Components/Switch/Homepage/homePage';
import OVHHomePage from './Components/VacationHomes/HomePage/homePage';
import SmartRoomHomepage from './Components/SmartRoom/HomePage/homepage';
import { Constants } from './Constants/Constants';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSortAlphaUp,
  faSortAlphaDown,
  faSortNumericUp,
  faSortNumericDown,
  faExclamationTriangle,
  faBackspace,
  faCheckDouble,
  faTimes,
  faSync,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import './App.css';

library.add(
  faSortAlphaUp,
  faSortAlphaDown,
  faSortNumericUp,
  faSortNumericDown,
  faExclamationTriangle,
  faBackspace,
  faCheckDouble,
  faTimes,
  faSync,
  faUser
);

class App extends Component {
  state = {
    loggedIn: false
  };

  async componentDidMount() {
    const loggedIn = await checkLoggedIn();
    this.setState({
      loggedIn: loggedIn
    });
  }

  render() {
    let pageToRender;
    let loggedIn = this.props.isLoggedIn;
    if (loggedIn === null) {
      loggedIn = this.state.loggedIn;
    }
    if (loggedIn) {
      let product = decrypt(localStorage.getItem(Constants.localStorageKeys.PRODUCT));
      let storedAppVersion = decrypt(localStorage.getItem(Constants.localStorageKeys.APP_VERSION));

      if (storedAppVersion !== Constants.APP_VERSION) {
        this.props.logoutUser();
        clearLocalStorage();
        pageToRender = urlConstant.routeURL.URL_LOGIN;
      } else {
        let newState;
        const role = decrypt(localStorage.getItem(Constants.localStorageKeys.ROLE));
        if (role !== null) {
          this.props.logoutUser();
          clearLocalStorage();
          pageToRender = urlConstant.routeURL.URL_LOGIN;
          newState = {
            isLoggedIn: false
          };
        } else {
          newState = {
            email: decrypt(localStorage.getItem(Constants.localStorageKeys.EMAIL)),
            authToken: decrypt(localStorage.getItem(Constants.localStorageKeys.AUTH_TOKEN)),
            userId: decrypt(localStorage.getItem(Constants.localStorageKeys.USERID)),
            userProfileId: decrypt(
              localStorage.getItem(Constants.localStorageKeys.USER_PROFILE_ID)
            ),
            isLoggedIn: true,
            iotRoles: JSON.parse(
              decrypt(localStorage.getItem(Constants.localStorageKeys.IOT_ROLES))
            ),
            userAccess: JSON.parse(
              decrypt(localStorage.getItem(Constants.localStorageKeys.USER_ACCESS))
            ),
            product: product
          };
        }
        this.props.setReduxStore(newState);
        if (product === Constants.PRODUCT_TYPE_UK_SMARTLOCK) {
          pageToRender = urlConstant.routeURL.URL_SMARTLOCK_HOMEPAGE;
        } else if (product === Constants.PRODUCT_TYPE_SWITCH) {
          pageToRender = urlConstant.routeURL.URL_SWITCH_HOMEPAGE;
        } else if (product === Constants.PRODUCT_TYPE_VACATIONHOMES) {
          pageToRender = urlConstant.routeURL.URL_OVH_HOMEPAGE;
        } else if (product === Constants.PRODUCT_TYPE_SMARTROOM) {
          pageToRender = urlConstant.routeURL.URL_SMARTROOM_HOMEPAGE;
        } else {
          pageToRender = urlConstant.routeURL.URL_NOT_FOUND;
        }
      }
    } else {
      pageToRender = urlConstant.routeURL.URL_LOGIN;
    }

    return (
      <BrowserRouter>
        <React.Fragment>
          <Redirect to={pageToRender} />
          <Route path={urlConstant.routeURL.URL_LOGIN} component={Login} />
          <Route path={urlConstant.routeURL.URL_SMARTLOCK_HOMEPAGE} component={HomePage} />
          <Route path={urlConstant.routeURL.URL_SWITCH_HOMEPAGE} component={SwitchHomePage} />
          <Route path={urlConstant.routeURL.URL_OVH_HOMEPAGE} component={OVHHomePage} />
          <Route path={urlConstant.routeURL.URL_SMARTROOM_HOMEPAGE} component={SmartRoomHomepage} />
        </React.Fragment>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login.isLoggedIn
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setReduxStore: (payload) => dispatch({ type: 'SET_STATE', payload: payload }),
    logoutUser: () => dispatch(loginActions.logoutUser())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
