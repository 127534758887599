import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../OyoLifeEspDetails/redux/actions';

import './deviceDetails.css';
import 'react-toastify/dist/ReactToastify.css';
import { Constants } from '../../../Constants/Constants';
import { decrypt } from '../../../utils/EncryptDecryptUtil';
import DateParsingUtil from "../../../utils/DateParsingUtil";

class DeviceDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredItems: []
        };
    }

    componentDidMount = async () => {
        let userId = decrypt(localStorage.getItem(Constants.localStorageKeys.USERID));
        await this.props.getDeviceList(userId);

        if (this.props.deviceList.length !== 0) {
            this.setState({ filteredItems: this.props.deviceList });
        }
    };


    render() {
        const { filteredItems } = this.state;
        return (
            <div className="tableAlign">
                <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
                ></link>
                <div className="hotel-Admin-layout-container-layout">
                    {/*<div className="OVH-input-container">*/}
                        {/*<input*/}
                            {/*placeholder="Enter Lock ID, Property Code"*/}
                            {/*type="text"*/}
                            {/*className="input-bar"*/}
                            {/*onChange={(e) => {*/}
                                {/*this.filterItems(e.target.value.toLowerCase());*/}
                            {/*}}*/}
                        {/*/>*/}

                        {/*<input*/}
                            {/*placeholder="Enter Booking Id"*/}
                            {/*type="text"*/}
                            {/*className="booking-input-bar"*/}
                            {/*onChange={(e) => {*/}
                                {/*this.setState({ bookingId: e.target.value });*/}
                            {/*}}*/}
                        {/*/>*/}

                        {/*<Popup trigger={<button className="search-button">Search</button>} modal>*/}
                            {/*{(close) => <BookingIdSearch close={close} bookingId={bookingId} />}*/}
                        {/*</Popup>*/}

                        {/* <Popup trigger={<button className="staff-code-button">Generate 6 Month Staff Code</button>} modal>
                        {close=>(
                            <GenStaffCode close={close} propList={this.props.hotelList} userId={this.props.email} months=6/>
                        )}
                    </Popup> */}

                        {/*<button*/}
                            {/*className="search-button"*/}
                            {/*onClick={() => {*/}
                                {/*if (window.confirm('Are you Sure?')) {*/}
                                    {/*this.handleExportToCSV();*/}
                                {/*}*/}
                            {/*}}*/}
                        {/*>*/}
                            {/*Export to CSV*/}

                        {/*</button>*/}
                        {/*<ToastContainer />*/}

                        {/*{this.props.isLoading ? (*/}
                            {/*<div className="sk-circle">*/}
                                {/*<div className="sk-circle1 sk-child"></div>*/}
                                {/*<div className="sk-circle2 sk-child"></div>*/}
                                {/*<div className="sk-circle3 sk-child"></div>*/}
                                {/*<div className="sk-circle4 sk-child"></div>*/}
                                {/*<div className="sk-circle5 sk-child"></div>*/}
                                {/*<div className="sk-circle6 sk-child"></div>*/}
                                {/*<div className="sk-circle7 sk-child"></div>*/}
                                {/*<div className="sk-circle8 sk-child"></div>*/}
                                {/*<div className="sk-circle9 sk-child"></div>*/}
                                {/*<div className="sk-circle10 sk-child"></div>*/}
                                {/*<div className="sk-circle11 sk-child"></div>*/}
                                {/*<div className="sk-circle12 sk-child"></div>*/}
                            {/*</div>*/}
                        {/*) : (*/}
                            {/*<></>*/}
                        {/*)}*/}
                    {/*</div>*/}

                    {/*<div className="form-label">*/}
                        {/*<label>Enter Authenticated User MAC Address: </label>*/}
                        {/*<input type="text"  />*/}

                        {/*<button*/}
                            {/*type="submit"*/}
                            {/*className="submit-button"*/}
                        {/*>*/}
                            {/*Submit*/}
                        {/*</button>*/}

                    {/*</div>*/}
                    <br></br>
                    <div className="hotel-Admin-layout-container">
                        {filteredItems.length !== 0 || this.props.deviceList.length !== 0 ? (
                            <>
                                <table className="hotel-Admin-layout">
                                    <thead className="tableHead">
                                    <tr>
                                        <td className="table-heading">Device ID</td>
                                        <td className="table-heading">Device Mac Address</td>
                                        <td className="table-heading">Gateway Mac Address</td>
                                        <td className="table-heading">Firmware Version</td>
                                        <td className="table-heading">Connect Status</td>
                                        <td className="table-heading">Last Sync Time</td>
                                        <td className="table-heading">Last Heartbeat At</td>
                                        {/*<td className="table-heading">Estimated Next Battery Change Date</td>*/}
                                        {/*<td className="table-heading">Service partner PIN</td>*/}
                                        {/*<td className="table-heading">Master PIN</td>*/}
                                        {/*<td className="table-heading">Edit Last Battery Changed Date</td>*/}
                                        {/*<td className="table-heading">Edit Service Partner Pin</td>*/}
                                        {/*<td className="table-heading">Edit Master Pin</td>*/}
                                    </tr>
                                    </thead>
                                    <tbody className="body-layout">
                                    {filteredItems.map((device) => {
                                        return (
                                            <tr className="trColumn" key={device.device_id}>
                                                <td className="tableColumn">{device.device_id}</td>
                                                <td className="tableColumn">{device.device_mac}</td>
                                                <td className="tableColumn">{device.gateway_mac}</td>
                                                <td className="tableColumn">{device.firmware_version}</td>
                                                <td className="tableColumn">
                                                    {device.is_connected === true ? "Connected" : "Disconnected"}
                                                </td>
                                                <td className="tableColumn">
                                                    {device.last_sync_time !== undefined && device.last_sync_time !== null
                                                        ? DateParsingUtil.changeEpochToStandard(device.last_sync_time)
                                                            .toString()
                                                            .slice(0, -34)
                                                        : 'NOT AVAILABLE'}
                                                </td>
                                                <td className="tableColumn">
                                                    {device.last_heartbeat_at !== undefined && device.last_heartbeat_at !== null
                                                        ? DateParsingUtil.changeEpochToStandard(device.last_heartbeat_at)
                                                            .toString()
                                                            .slice(0, -34)
                                                        : 'NOT AVAILABLE'}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </>
                        ) : this.props.isLoading ? (
                            <div></div>
                        ) : (
                            <div>No Device data available</div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        deviceList: state.ovhOyoLifeEspDetails.devices,
        email: state.login.email,
        userId: state.login.userId,
        userProfileId: state.login.userProfileId,
        response: state.ovhOyoLifeEspDetails.response,
        isLoading: state.ovhOyoLifeEspDetails.isLoading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDeviceList: async (userId) => await dispatch(actions.getDevices(userId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetails);
