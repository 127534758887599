import {installationDetails as initialState} from './state';
import * as actionTypes from './actionTypes';

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_INSTALLATION_IS_LOADING_TRUE:
            return {
                ...state,
                isLoading : true
            }
        case actionTypes.SET_INSTALLATION_IS_LOADING_FALSE:
            return {
                ...state,
                isLoading : false
            }
        case actionTypes.CLEAR_INSTALLATION_ROOM_LIST:
            return {
                ...state,
                hotelData : {
                    roomList: [],
                    deviceType: "",
                    hotelId : ""
                }
            }
        case actionTypes.CLEAR_INSTALLATION_HOTEL_AND_ROOMS:
            return {
                ...state,
                selectedHotel : {},
                hotelData: {
                    roomList: [],
                    deviceType: "",
                    hotelId : ""
                }
            }
        case actionTypes.LOAD_INSTALLATION_ROOM_LIST_SUCCESS:
            return {
                ...state,
                hotelData: {
                    roomList: action.payload.room_details,
                    deviceType : action.payload.device_type,
                    hotelId : action.payload.hotel_id,
                }
            }
        case actionTypes.SET_INSTALLATION_SELECTED_HOTEL:
            return {
                ...state,
                selectedHotel: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer;