const intialState = {
    hotel:[],
    rooms:[],
    checkins:[],
    error: false,
    loading: false,
    checkInOut: false
};

export default intialState;
