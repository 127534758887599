import React, {Component} from 'react';
import Select from 'react-select';
import {connect} from 'react-redux';
import * as actions from './redux/actions';

class SearchBar extends Component {
    async componentDidMount() {
        await this.props.getHotelList();
        // logger.log(this.props.hotelData)
    }
    render() {
        let options = this.props.hotelData.map(hotel => {
            return {value: {hotelId:hotel.hotel_id, oyoId:hotel.oyo_id, hotelType:hotel.hotel_type, isEnabled:hotel.is_enabled}, label: hotel.hotel_name + '(' + hotel.oyo_id + ')'}
        })
        if(this.props.options) {
            options = this.props.options;
        }
        return (
            <Select 
                className = "search-bar"
                placeholder = "Enter Hotel Name or OYO ID"
                name = "Hotel List"
                options = {options}
                onChange ={this.props.handleChange}
                isClearable = "true"
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        hotelData: state.onLoad.onLoadHotelData
    }
}
const mapDispacthToProps = (dispatch) => {
    return {
        getHotelList : async () => await dispatch(actions.getHotels())
    }
}
export default connect(mapStateToProps, mapDispacthToProps)(SearchBar);