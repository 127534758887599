import * as actionTypes from './actionTypes';
import RESTApi from '../../../utils/RESTApi';
import {
  AuthenticatedDefaultHeaders,
  DefaultHeaders,
  refreshAuthHeaders
} from '../../../Constants/HeadersConstants';
import { Constants, productStringsMap, ProductIoTRolesMap } from '../../../Constants/Constants';
import {
  saveLoginCredentials,
  saveSsoLoginAttempt,
  clearSsoLoginAttempt,
  clearLocalStorage,
} from '../../../utils/localStorageUtil';
import logger from "utils/logger"

const _startLogin = () => {
  return {
    type: actionTypes.START_LOGIN
  };
};

const _startSSOLogin = () => {
  return {
    type: actionTypes.START_SSO_LOGIN
  };
};

const _loginSuccess = (payload) => {
  refreshAuthHeaders();
  if (payload.productType === Constants.PRODUCT_TYPE_VACATIONHOMES) {
    return {
      type: actionTypes.SUCCESSFUL_LOGIN_OVH,
      payload
    };
  } else {
    return {
      type: actionTypes.SUCCESSFUL_LOGIN,
      payload
    };
  }
};

const _loginFailed = (payload) => {
  return {
    type: actionTypes.FAILED_LOGIN,
    payload
  };
};

export const logoutUser = () => {
  return async function (dispatch) {
    try {
      let response = await RESTApi.GET(Constants.LOGOUT_URL,'',AuthenticatedDefaultHeaders());
      logger.log(response);
      if (response.status === 200) {
        if (response.data && response.data.success) {
          await clearLocalStorage();
          dispatch(_logoutSuccess());
        }
      } else {
        dispatch(_logoutFailed());
      }
    } catch (error) {
      logger.error(error);
      let payload = {
        message: 'LOGOUT FAILED'
      };
      dispatch(_logoutFailed(payload));
    }
  };
};

const _logoutSuccess = () => {
  return {
    type: actionTypes.LOGOUT_USER
  };
};

const _logoutFailed = (payload) => {
  return {
    type: actionTypes.FAILED_LOGOUT,
    payload
  };
};

export const genericUserLogin = (email, password, productType) => {
  return async function (dispatch) {
    dispatch(_startLogin());
    try {
      const body = {
        email: email,
        password: password
      };
      let response = await RESTApi.POST(Constants.LOGIN_URL, '', body, DefaultHeaders);

      if (response.status === 200) {
        const userIoTRoles = response.data.iot_roles;

        const allowedRolesForProduct = ProductIoTRolesMap.get(productType);
        const userAllowedRole = allowedRolesForProduct.filter((element) =>
          userIoTRoles.includes(element)
        );

        const userAccess = response.data.user_access;
        let allowedUserAccess = [];

        userAccess.features.forEach((eachUserAccess) => {
          let userAccessProduct = eachUserAccess.product;
          if (productStringsMap.get(productType).includes(userAccessProduct)) {
            allowedUserAccess.push(eachUserAccess);
          }
        });
        if (Array.isArray(userAllowedRole) && userAllowedRole.length) {
          let data = {
            ...body,
            authToken: response.data.auth_token,
            productType: productType,
            userProfileId : response.data.user_profile_id,
            userId: response.data.user_id,
            iotRoles: userIoTRoles,
            userAccess: allowedUserAccess
          };
          await saveLoginCredentials(data);
          dispatch(_loginSuccess(data));
        } else {
          dispatch(_loginFailed({ message: 'Not Authorized for Selected Product.' }));
        }
      }
    } catch (error) {
      logger.error(error);
      let payload = {
        message: 'LOGIN FAILED'
      };
      dispatch(_loginFailed(payload));
    }
  };
};

export const loginUserViaSso = (product) => {
  return async function (dispatch) {
    dispatch(_startSSOLogin());
    await saveSsoLoginAttempt(product);
    window.location = Constants.PLATFORM_SSO_REDIRECT_URL;
  };
};

export const getSsoRole = (ssoToken, productType) => {
  return async function (dispatch) {
    try {
      let response = await RESTApi.GET(
        Constants.GET_SSO_ROLE_URL,
        '?ssoToken=' + ssoToken + '&client=' + Constants.CLIENT_NAME,
        DefaultHeaders
      );

      if (response.status === 200) {
        const userIoTRoles = response.data.iot_roles;

        const allowedRolesForProduct = ProductIoTRolesMap.get(productType);
        const userAllowedRole = allowedRolesForProduct.filter((element) =>
          userIoTRoles.includes(element)
        );

        const userAccess = response.data.user_access;
        let allowedUserAccess = [];

        userAccess.features.forEach((eachUserAccess) => {
          let userAccessProduct = eachUserAccess.product;
          if (productStringsMap.get(productType).includes(userAccessProduct)) {
            allowedUserAccess.push(eachUserAccess);
          }
        });
        if (Array.isArray(userAllowedRole) && userAllowedRole.length) {
          let data = {
            email: response.data.email,
            authToken: response.data.auth_token,
            userId: response.data.user_id,
            userProfileId: response.data.user_profile_id,
            userAccess: allowedUserAccess,
            productType: productType,
            ssoToken: ssoToken,
            iotRoles: userIoTRoles
          };
          await saveLoginCredentials(data);
          dispatch(_loginSuccess(data));
        } else {
          dispatch(_loginFailed({ message: 'Not Authorized for Selected Product.' }));
        }
      }
    } catch (error) {
      logger.log(error);
      let payload = {
        message: 'LOGIN FAILED'
      };
      await clearSsoLoginAttempt();
      dispatch(_loginFailed(payload));
    }
  };
};
