import urlConstant from "./urlConstant";
import finalConfig from "../server/config";
import { encrypt } from "../utils/EncryptDecryptUtil";

export const Constants = {
  PRODUCT_TYPE_UK_SMARTLOCK: "uk-smartlock",
  PRODUCT_TYPE_SWITCH: "switch",
  PRODUCT_TYPE_VACATIONHOMES: "vacationhomes",
  PRODUCT_TYPE_SMARTROOM: "smartroom",
  LOGIN_URL:
    finalConfig.SWITCH_BASE_URL + urlConstant.APIURL.GENERIC_LOGIN_USER,
  LOGOUT_URL:
    finalConfig.SWITCH_BASE_URL + urlConstant.APIURL.GENERIC_LOGOUT_USER,
  GET_SSO_ROLE_URL:
    finalConfig.SWITCH_BASE_URL + urlConstant.APIURL.GENERIC_GET_SSO_ROLE,
  PLATFORM_SSO_REDIRECT_URL: finalConfig.PLATFORM_SSO_REDIRECT_URL,
  CLIENT_ID: 7,
  SERVICE_KEY: "4028f8ab-a87c-497c-aa50-feacc5fc70dc",
  APP_VERSION: "1.2", // Used in headers & to check website version in local storage
  CLIENT_NAME: "Tech-Support",
  localStorageKeys: {
    APP_VERSION: encrypt("app_version"),
    EMAIL: encrypt("email"),
    AUTH_TOKEN: encrypt("authToken"),
    SSO_TOKEN: encrypt("ssoToken"),
    PASSWORD: encrypt("password"),
    PRODUCT: encrypt("product"),
    ROLE: encrypt("role"),
    IOT_ROLES: encrypt("iotRoles"),
    USER_ACCESS: encrypt("userAccess"),
    USERID: encrypt("userId"),
    USER_PROFILE_ID: encrypt("userProfileId"),
    SSO_LOGIN_ATTEMPTED: encrypt("ssoLoginAttempted"),
  },
  CustomerType: {
    agm: "agm",
    owner: "owner",
    staff: "staff",
    guest: "guest",
  },
  HotelType: {
    SOB: "SOB",
    SMART: "Smart",
    FLAGSHIP: "Flagship",
    TOWNHOUSE: "Townhouse",
    SILVERKEY: "Silverkey",
    EDITION_O: "Edition O",
  },
  decommConstants: {
    DECOMM: "decommission",
    RECOMM: "recommission",
    ROOM: "room",
    HOTEL: "hotel",
  },
  videoLink: {
    INSTALLATION_AND_CONFIGURATION:
      "https://mesh-switch-stage.s3-ap-southeast-1.amazonaws.com/oyo-switch-support-videos/Installation+%2B+Configuration.mp4",
    SWITCH_V2_PRESENTATION:
      "https://mesh-switch-stage.s3-ap-southeast-1.amazonaws.com/oyo-switch-support-videos/OTO+SWITCH+V2+-+PRESENTATION.mp4",
    SWITCH_INSTALLATION_GUIDE:
      "https://mesh-switch-stage.s3-ap-southeast-1.amazonaws.com/oyo-switch-support-videos/OYO+SWITCH+INSTALLATION+GUIDE.mp4",
    STAFF_ENTRY_WORKING:
      "https://mesh-switch-stage.s3-ap-southeast-1.amazonaws.com/oyo-switch-support-videos/Staff+entry+Working.mp4",
    TEST_MAPPING_INTRANET:
      "https://mesh-switch-stage.s3-ap-southeast-1.amazonaws.com/oyo-switch-support-videos/Test+Mapping+Intranet.mp4",
  },
};

const iotAccessTypes = [
  "Admin",
  "Tech-Support-L2",
  "Tech-Support",
  "Employee",
  "OYO-Business",
  "Installation",
  "Commissioning",
  "Hotel-Staff",
  "OEM",
  "QC-Vendor",
];

export const productStringsMap = new Map();
productStringsMap.set(Constants.PRODUCT_TYPE_SWITCH, ["Switch"]);
productStringsMap.set(Constants.PRODUCT_TYPE_UK_SMARTLOCK, ["UK_Keybox"]);
productStringsMap.set(Constants.PRODUCT_TYPE_VACATIONHOMES, ["Europe_Keybox"]);

export const ProductIoTRolesMap = new Map();
for (const [key, value] of productStringsMap.entries()) {
  var iotRoles = [];
  for (var i = 0; i < value.length; i++) {
    for (var j = 0; j < iotAccessTypes.length; j++) {
      iotRoles.push(value[i] + "-" + iotAccessTypes[j]);
    }
  }
  ProductIoTRolesMap.set(key, iotRoles);
}
ProductIoTRolesMap.set(Constants.PRODUCT_TYPE_SMARTROOM, [
  "Switch-Admin",
  "Switch-Employee",
]);

export const SmartlockMenuOptionsMap = new Map();
SmartlockMenuOptionsMap.set(
  "UK-Hotel-Details",
  urlConstant.routeURL.URL_SMARTLOCK_UK_HOTELS
);
SmartlockMenuOptionsMap.set(
  "Mortise-Lock",
  urlConstant.routeURL.URL_SMARTLOCK_MORTISE_LOCK
);

// Smartlock disabled Components as of now - Live Feed, Lock Management
// After completion the MenuOptions.Smartlock.Admin should be Admin: ["Basic Overview", "Live Feed", "Activity Logs", "Uptime Details", "Adoption Details", "Lock Management"]
// After completion the MenuOptions.Smartlock.TechSupport should be TechSupport: ["Property Details", "Uptime Details"]

export const SwitchMenuOptionsMap = new Map();
SwitchMenuOptionsMap.set(
  "Decommission",
  urlConstant.routeURL.URL_SWITCH_DECOMMISSION
);
SwitchMenuOptionsMap.set(
  "By-Hotel",
  urlConstant.routeURL.URL_SWITCH_DECOMMISSION_HOTEL
);
SwitchMenuOptionsMap.set(
  "By-City",
  urlConstant.routeURL.URL_SWITCH_DECOMMISSION_CITY
);
SwitchMenuOptionsMap.set(
  "Global",
  urlConstant.routeURL.URL_SWITCH_DECOMMISSION_GLOBAL
);
SwitchMenuOptionsMap.set(
  "Hotel-Details",
  urlConstant.routeURL.URL_SWITCH_HOTEL_DETAILS
);
SwitchMenuOptionsMap.set(
  "Ble-App-Password",
  urlConstant.routeURL.URL_SWITCH_GET_PASSWORD
);
SwitchMenuOptionsMap.set(
  "Hotel-Installation-Status",
  urlConstant.routeURL.URL_SWITCH_PRE_INSTALLATION
);
SwitchMenuOptionsMap.set(
  "Post-Commissioning-Health",
  urlConstant.routeURL.URL_SWITCH_POST_INSTALLATION
);
SwitchMenuOptionsMap.set(
  "Mst-Number",
  urlConstant.routeURL.URL_SWITCH_MST_NUMBER
);
SwitchMenuOptionsMap.set(
  "Support-Videos",
  urlConstant.routeURL.URL_SWITCH_SUPPORT_VIDEOS
);
SwitchMenuOptionsMap.set(
  "Sonoff-Mac",
  urlConstant.routeURL.URL_SWITCH_SONOFF_MAC
);
SwitchMenuOptionsMap.set(
  "Installation-Status",
  urlConstant.routeURL.URL_SWITCH_OFFLINE_DEVICES
);

export const OVHMenuOptionsMap = new Map();
OVHMenuOptionsMap.set(
  "Hotel-Details",
  urlConstant.routeURL.URL_OVH_HOTEL_DETAILS
);
OVHMenuOptionsMap.set("PSP-Details", urlConstant.routeURL.URL_OVH_PSP_DETAILS);
OVHMenuOptionsMap.set(
  "Device-Details",
  urlConstant.routeURL.URL_OVH_DEVICE_DETAILS
);
OVHMenuOptionsMap.set(
  "OyoLife-User-Details",
  urlConstant.routeURL.URL_OVH_OYOLIFE_DETAILS
);
OVHMenuOptionsMap.set(
  "OyoLife-Esp-Details",
  urlConstant.routeURL.URL_OVH_OYOLIFE_ESP_DETAILS
);
