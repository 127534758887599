import finalConfig from '../server/config';

const routeURL = {
  //  for smartlock
  URL_SMARTLOCK_HOMEPAGE: '/smartlock-home-page',
  URL_SMARTLOCK_MORTISE_LOCK: '/smartlock-mortise-lock',
  URL_SMARTLOCK_UK_HOTELS: '/smartlock-uk-hotels',

  //  for switch
  URL_SWITCH_HOMEPAGE: '/switch-home-page',
  URL_SWITCH_BULK_ACCESS: '/switch-bulk-access',
  URL_SWITCH_DECOMMISSION: '/switch-decommission',
  URL_SWITCH_DECOMMISSION_ROOM: '/switch-decommission-room',
  URL_SWITCH_DECOMMISSION_HOTEL: '/switch-decommission-hotel',
  URL_SWITCH_DECOMMISSION_CITY: '/switch-decommission-city',
  URL_SWITCH_DECOMMISSION_GLOBAL: '/switch-decommission-global',
  URL_SWITCH_DECOMMISSION_HISTORY: '/switch-decommission-history',
  URL_SWITCH_HOTEL_DETAILS: '/switch-hotel-details',
  URL_SWITCH_OFFLINE_DEVICES: '/switch-offline-devices',
  URL_SWITCH_GET_PASSWORD: '/switch-get-password',
  URL_SWITCH_MST_NUMBER:
    'https://docs.google.com/spreadsheets/d/17Whnp6PJspp0-jiwIr5B9KZYQ2ee208E1xmsNllJMmo/',
  URL_SWITCH_SUPPORT_VIDEOS: '/switch-support-videos',
  URL_SWITCH_SONOFF_MAC: '/switch-sonoff-mac',
  URL_SWITCH_PRE_INSTALLATION: '/switch-pre-installation-status',
  URL_SWITCH_POST_INSTALLATION: '/switch-post-installation-status',

  //for Vacation Homes
  URL_OVH_HOMEPAGE: '/ovh-hotel-details',
  URL_OVH_HOTEL_DETAILS: '/ovh-hotel-details',
  URL_OVH_PSP_DETAILS: '/ovh-psp-details',
  URL_OVH_DEVICE_DETAILS: '/ovh-device-details',
  URL_OVH_OYOLIFE_DETAILS: '/ovh-oyolife-details',
  URL_OVH_OYOLIFE_ESP_DETAILS: '/ovh-oyolife-esp-details',

  //for Smart Room
  URL_SMARTROOM_HOMEPAGE: '/smartroom-home-page',

  //  others
  URL_NOT_FOUND: '/not-found',
  URL_LOGIN: '/login'
};

export const APIURL = {
  //  for smartlock
  SMARTLOCK_BASE_URL: finalConfig.SMARTLOCK_BASE_URL,
  SMARTLOCK_GET_MORTISE_DETAILS: '/v1/mortise-support/get-all-mortise',
  SMARTLOCK_MORTISE_GEN_CODE: '/v1/mortise/generate-mortise-internal-code',
  SMARTLOCK_MORTISE_VIEW_CODE: '/v1/mortise/get-params',

  //  for switch
  SWITCH_BASE_URL: finalConfig.SWITCH_BASE_URL,
  GENERIC_LOGIN_USER: '/generic/v1/login-user',
  GENERIC_LOGOUT_USER: '/generic/v1/logout-user',
  GENERIC_GET_SSO_ROLE: '/generic/v1/get-sso-role',
  SWITCH_GET_HOTELS: '/support/get-hotels',
  SWITCH_GET_ROOMS: '/support/get-all-room-details',
  SWITCH_GET_ACTIVITY_REASONS: '/support/get-duration-reason',
  SWITCH_GET_V2_ACTIVITY_RESPONSE: '/support/turn-lights-on',
  SWITCH_CRS_BOOKING_SYNC: '/support/booking-crs-sync',
  SWITCH_V1_STAFF_ENTRY: '/oyo/staff-entry',
  SWITCH_V1_OFFLINE_CODE: '/oyo/offline-code',
  SWITCH_V1_CHECK_IN: '/oyo/check-in',
  SWITCH_BULK_DURATIONS_AND_REASONS: '/support/bulk-duration-reason',
  SWITCH_BULK_CHECK_IN: '/support/bulk-check-in',
  SWITCH_BLE_PASSWORD: '/ble-relayapp/get-password-ble-staff-app',
  SWITCH_DECOMM_ROOMS: '/internal/decommission',
  SWITCH_RECOMM_ROOMS: '/internal/recommission',
  SWITCH_DECOMM_GLOBAL: '/internal/global-decommission',
  SWITCH_RECOMM_GLOBAL: '/internal/global-recommission',
  SWITCH_DECOMM_HOTEL_LIST: '/internal/get-global-decommissions',
  SWITCH_SUBMIT_SONOFF_MAC_LIST: '/support/add-st-ap-mac',
  SWITCH_GET_METABASE_IFRAME_TOKEN: '/support/get-metabase-iframe-token',
  SWITCH_UPDATE_USER_MAC_ADDRESS: '/support/update-user-mac-address',

  //  others
  SMARTLOCK_LOCK_MANAGEMENT_PAGE_URL: finalConfig.SMARTLOCK_LOCK_MANAGEMENT,
  RENDER_HOTEL_DETAILS_URL: '/v1/support/render-hotel-details',
  RENDER_DEVICE_DETAILS_URL: '/v1/support/render-device-details',
  RENDER_OYOLIFE_DEVICE_DETAILS_URL: '/support/get-oyolife-user-details',
  RENDER_OYOLIFE_ESP_DETAILS_URL: '/support/get-oyolife-device-details',

  //for OVH
  OVH_BASE_URL: finalConfig.SMARTLOCK_BASE_URL,
  OVH_GEN_NEW_CODE: '/v1/keybox/generate-code',
  OVH_GEN_STAFF_CODE: '/v1/keybox/generate-internal-code',
  OVH_VIEW_CODE: '/v1/keybox/get-codes',
  OVH_DELETE_CODE: '/v1/keybox/deactivate-code',
  OVH_UPDATE_LOCKS: '/v1/keybox-support/update-locks',
  OVH_UPDATE_DEVICE_MASTER_PIN: '/v1/keybox-support/update-device-master-pin',
  OVH_UPDATE_DEVICE_SERVICE_PARTNER_PIN: '/v1/keybox-support/update-device-service-partner-pin',
  OVH_UPDATE_DEVICE_LAST_BATTERY_CHANGED_DATE:
    '/v1/keybox-support/update-device-last-battery-changed-date',

  // for SmartRoom
  SMART_ROOM_BASE_URL: finalConfig.SMART_ROOM_BASE_URL,
  SMART_ROOM_GET_HOTELS: '/get-hotels',
  SMART_ROOM_GET_HOTEL_DETAILS: '/hotel-details',
  SMART_ROOM_CHECK_IN: '/check-in',
  SMART_ROOM_CHECK_OUT: '/check-out'
};

export default {
  routeURL,
  APIURL
};
