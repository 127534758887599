import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import urlConstant from '../../../Constants/urlConstant';
import * as actions from '../../Login/redux/actions';
import TopBar from '../../../CommonUI/TopBar/topBar';
import MenuBar from '../_utilComponents/MenuBar/menubar';
import HotelDetails from '../HotelDetails/hotelDetails';
import PspDetails from '../PspDetails/pspDetails';
import DeviceDetails from '../DeviceDetails/deviceDetails';
import OyoLife from '../OyoLifeDashboard/deviceDetails';
import EspDetails from '../OyoLifeEspDetails/deviceDetails';

class OVHHomePage extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <div>
            <TopBar />
          </div>
          <div>
            <nav>
              <MenuBar />
            </nav>
            <article>{/*Some Content*/}</article>
          </div>
          {/* All Routes for OVH Product */}
          <React.Fragment>
            <Route path={urlConstant.routeURL.URL_OVH_HOTEL_DETAILS} component={HotelDetails} />
            <Route path={urlConstant.routeURL.URL_OVH_PSP_DETAILS} component={PspDetails} />
            <Route path={urlConstant.routeURL.URL_OVH_DEVICE_DETAILS} component={DeviceDetails} />
            <Route path={urlConstant.routeURL.URL_OVH_OYOLIFE_DETAILS} component={OyoLife} />
            <Route path={urlConstant.routeURL.URL_OVH_OYOLIFE_ESP_DETAILS} component={EspDetails} />
          </React.Fragment>
        </div>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(actions.logoutUser())
  };
};

export default connect(null, mapDispatchToProps)(OVHHomePage);
