import RESTApi from '../../../../utils/RESTApi';
import {APIURL} from '../../../../Constants/urlConstant';
import {AuthenticatedBleAppHeaders, AuthenticatedDefaultHeaders} from '../../../../Constants/HeadersConstants'; 
import logger from "utils/logger"

const _setIsLoadingTrue = () => {
    return {
        type: "SET_IS_LOADING_TRUE"
    }
};
const _setIsLoadingFalse = () => {
    return {
        type: "SET_IS_LOADING_FALSE" 
    }
};

const _getHotelList = (payload) => {
    return {
        // type: actionType.LOAD_HOTELS_LIST_SUCCESS,
        type: "LOAD_HOTELS_LIST_SUCCESS",
        payload: payload
    }
}
export const getHotels = () => {
    return async function (dispatch) {
        try {
            let response = await RESTApi.GET(APIURL.SWITCH_BASE_URL, APIURL.SWITCH_GET_HOTELS, AuthenticatedDefaultHeaders())
            if(response.status === 200) {
                await dispatch(_getHotelList(response.data))
            }
        }
        catch(error) {
            alert("Unable to load hotel list. Please try later")
        }
    }   
}

const _setSelectedHotel = (payload) => {
    logger.log("Setting selected hotel to ", payload)
    return {
        type : "SET_SELECTED_HOTEL_BLE",
        payload : payload
    }
}
export const setSelectedHotelRedux = (payload) => {
    return async function (dispatch) {
        dispatch(_setSelectedHotel(payload));
    }
}

const _getBlePassword = (payload) => {
    return {
        type : "SET_BLE_APP_PASSWORD",
        payload : payload
    }
}
export const getBlePassword = (payload) => {
    return async function (dispatch) {
        dispatch(_setIsLoadingTrue());
        
        try {
            let relativeURL = APIURL.SWITCH_BLE_PASSWORD + "?hotel-id=" + payload;
            let response = await RESTApi.GET(APIURL.SWITCH_BASE_URL,relativeURL, AuthenticatedBleAppHeaders())
            dispatch(_setIsLoadingFalse());
            // logger.log(response);
            if(response.status===200) {
                dispatch(_getBlePassword(response.data));
            }
        }
        catch(error) {
            alert("Unable to retrieve password at the moment. Please try later");
            dispatch(_setIsLoadingFalse());
        }
    }
}