import React from 'react';
import * as actions from './redux/actions';
import Popup from 'reactjs-popup';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import GenCode from './Buttons/generate-code';
import ViewCode from './Buttons/view-code';
import DateParsingUtil from '../../../utils/DateParsingUtil';
import ProgressBar from '../../../CommonUI/ProgressBar/progressBar';
import './mortise-lock.css';

class MortiseLock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      propId: '',
      filteredItems: []
    };
  }

  componentDidMount() {
    this.props.hideInputFields();
    if (this.props.propList.rooms) {
      this.setState({ filteredItems: this.props.propList.rooms });
    }
  }

  handleChange = (e) => {
    if (e.target.value !== null && e.target.value !== undefined) {
      this.setState({ propId: e.target.value });
    }
  };

  handleClick = () => {
    this.props.getRooms(this.state.propId.toUpperCase());
  };

  filterItems = (text) => {
    if (text !== '') {
      let tempFilteredItems = this.props.roomList.rooms.filter(
        (item) =>
          (item.room_no && item.room_no.toString().toLowerCase().includes(text)) ||
          (item.battery && item.battery.toString().toLowerCase().concat('%').includes(text)) ||
          (item.rtc_time &&
            DateParsingUtil.changeEpochToStandard(item.rtc_time)
              .toString()
              .slice(0, -34)
              .toLowerCase()
              .includes(text)) ||
          (item.last_sync_time &&
            DateParsingUtil.changeEpochToStandard(item.last_sync_time)
              .toString()
              .slice(0, -34)
              .toLowerCase()
              .includes(text))
      );
      this.setState({ filteredItems: tempFilteredItems });
    } else {
      this.setState({ filteredItems: this.props.roomList.rooms });
    }
  };

  handleBatteryStatus = (batteryStatus) => {
    if (batteryStatus < 0 || batteryStatus === null) {
      return '0%';
    } else {
      return `${batteryStatus}%`;
    }
  };

  handleEpochTime = (epochTime) => {
    if (epochTime === null) {
      const hyphen = (
        <div className="hyphen-container">
          <div className="hyphen-line"></div>
        </div>
      );
      return hyphen;
    } else {
      return DateParsingUtil.changeEpochToStandard(epochTime).toString().slice(0, -34);
    }
  };

  render() {
    return (
      <div>
        <div className="mortise-lock-container">
          <div className="input-button">
            <input
              type="text"
              className="input-box"
              onChange={this.handleChange}
              placeholder="Enter property Id"
            ></input>
            <Button id="mortise-button" onClick={this.handleClick}>
              Fetch Data
            </Button>
          </div>
        </div>
        {this.props.isLoading ? <div className="loader"></div> : <div></div>}
        {this.props.roomList.rooms !== undefined ? (
          this.props.roomList.rooms.length === 0 ? (
            <div className="password-details-error">No room data available</div>
          ) : (
            <div className="tableAlign">
              <div className="table-option-heading">
                <h3 className="hotel-name">
                  {this.props.roomList.hotel_name} ({this.props.roomList.property_id})
                </h3>
              </div>
              <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
              ></link>
              <div className="hotel-Admin-layout-container-layout">
                <div className="hotel-Admin-layout-container">
                  <div className="filter-container">
                    <div className="filter-heading">Search</div>
                    <div className="filter-input">
                      <input
                        title="Enter Room No, Battery Percentage, RTC Time or Last SYNC Time"
                        type="text"
                        className="filter-bar"
                        onChange={(e) => {
                          this.filterItems(e.target.value.toLowerCase());
                        }}
                      />
                    </div>
                  </div>

                  <table className="hotel-Admin-layout mortise-table-container">
                    <thead className="mortise-table-head">
                      <tr>
                        <td>Room Number</td>
                        <td>Battery</td>
                        <td>Guest Code</td>
                        <td>Staff code</td>
                        <td>RTC Time</td>
                        <td>Created At</td>
                      </tr>
                    </thead>
                    <tbody className="body-layout">
                      {this.state.filteredItems.map((room) => {
                        return (
                          <tr className="trColumn" key={room.room_no}>
                            <td className="tableColumn">{room.room_no}</td>
                            <td className="tableColumn battery-align">
                              <div className="progress-container">
                                <div className="progress-bar">
                                  <ProgressBar battery={room.battery} />
                                </div>
                                <div>{this.handleBatteryStatus(room.battery)}</div>
                              </div>
                            </td>
                            <td className="tableColumn">
                              <Popup
                                trigger={<button className="submit-button">Generate</button>}
                                modal
                              >
                                {(close) => (
                                  <GenCode
                                    close={close}
                                    heading={'Generate Guest Code'}
                                    room={room.room_no}
                                    propId={this.props.roomList.property_id}
                                  />
                                )}
                              </Popup>
                              <Popup
                                trigger={<button className="submit-button">View</button>}
                                modal
                              >
                                {(close) => (
                                  <ViewCode
                                    close={close}
                                    heading={'View Guest Code'}
                                    type="guest"
                                    room={room.room_no}
                                    propId={this.props.roomList.property_id}
                                  />
                                )}
                              </Popup>
                            </td>
                            <td className="tableColumn">
                              <Popup
                                trigger={<button className="submit-button">Generate</button>}
                                modal
                              >
                                {(close) => (
                                  <GenCode
                                    close={close}
                                    heading={'Generate Staff Code'}
                                    type="staff"
                                    room={room.room_no}
                                    propId={this.props.roomList.property_id}
                                  />
                                )}
                              </Popup>
                              <Popup
                                trigger={<button className="submit-button">View</button>}
                                modal
                              >
                                {(close) => (
                                  <ViewCode
                                    close={close}
                                    heading={'View Staff Code'}
                                    room={room.room_no}
                                    propId={this.props.roomList.property_id}
                                  />
                                )}
                              </Popup>
                            </td>
                            {/* <td className="tableColumn"> */}
                            {/* </td> */}

                            <td className="tableColumn">{this.handleEpochTime(room.rtc_time)}</td>
                            <td className="tableColumn">
                              {this.handleEpochTime(room.last_sync_time)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roomList: state.mortise.roomList,
    isLoading: state.mortise.isLoading
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    hideInputFields: () => dispatch(actions.hideHotelRoomBar()),
    getRooms: (payload) => dispatch(actions.getRoomList(payload))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MortiseLock);
