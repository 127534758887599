import React from 'react';
import DatePicker from 'react-date-picker';
import DateParsingUtil from '../../../../utils/DateParsingUtil';
import TimePicker from 'react-time-picker';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';

class GenerateMortiseCode extends React.Component {
  constructor(props) {
    super(props);

    let today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    let tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);
    tomorrow.setSeconds(0);
    tomorrow.setMilliseconds(0);

    this.state = {
      startDate: today,
      endDate: tomorrow,
      bookingId: '',
      userId: '',
      startTime: '12:00:00',
      duration: 1
    };
  }

  type = this.props.heading.match('Guest') ? 1 : 0;

  componentDidMount() {
    this.props.clearResponse();
  }

  changeStartDate = (date) => {
    const { endDate } = this.state;
    if (date !== null) {
      if (endDate !== '' && endDate < date && this.type === 1) {
        alert('Checkout Date cannot be earlier than Check In Date. Please enter the correct date');
      } else {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        this.setState({ startDate: date });
      }
    } else {
      this.setState({ startDate: '' });
    }
  };

  changeEndDate = (date) => {
    const { startDate } = this.state;
    if (date !== null) {
      if (startDate !== '' && startDate > date) {
        alert('Check In Date cannot be after Check Out Date. Please enter the correct date');
      } else {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        this.setState({ endDate: date });
      }
    } else {
      this.setState({ endDate: '' });
    }
  };

  handleTime = (time) => {
    this.setState({ startTime: time });
  };

  handleDuration = (e) => {
    switch (e.target.value) {
      case '30 minutes':
        this.setState({ duration: 1 });
        break;
      case '1 hour':
        this.setState({ duration: 2 });
        break;
      case '1.5 hours':
        this.setState({ duration: 3 });
        break;
      case '2 hours':
        this.setState({ duration: 4 });
        break;
      case '2.5 hours':
        this.setState({ duration: 5 });
        break;
      case '3 hours':
        this.setState({ duration: 6 });
        break;
      case '3.5 hours':
        this.setState({ duration: 7 });
        break;
      case '4 hours':
        this.setState({ duration: 8 });
        break;
      default:
        this.setState({ duration: '' });
    }
  };

  handleSubmit = () => {
    const { duration, startDate, endDate, startTime, userId, bookingId } = this.state;
    if (this.type === 0) {
      //staff
      if (duration === null || duration === undefined || duration === '' || duration === 0) {
        alert('Please enter a valid duration');
        return;
      }
      if (startDate === null || startDate === undefined || startDate === '') {
        alert('Please enter a valid start date');
        return;
      }

      let start = startTime.slice(0, 2);
      start = parseInt(start);
      startDate.setHours(start);
      let finalStartDate = DateParsingUtil.changeStandardToEpoch(startDate);

      let payload = {
        property_id: this.props.propId,
        room_no: this.props.room,
        user_type: this.type,
        duration: duration,
        staff_name: userId,
        valid_from: finalStartDate
      };
      this.props.genCode(payload);
    } else {
      //guest
      if (bookingId === null || bookingId === undefined || bookingId === '') {
        alert('Please enter a valid booking ID');
        return;
      }
      // if(userId===null||userId===undefined||userId==="") {
      //     alert("Please enter a valid user ID");
      //     return;
      // }
      if (startDate === null || startDate === undefined || startDate === '') {
        alert('Please enter a valid start date');
        return;
      }
      if (endDate === null || endDate === undefined || endDate === '') {
        alert('Please enter a valid end date');
        return;
      }

      startDate.setHours(12);
      let finalStartDate = DateParsingUtil.changeStandardToEpoch(startDate);

      endDate.setHours(12);
      let finalEndDate = DateParsingUtil.changeStandardToEpoch(endDate);

      let payload = {
        property_id: this.props.propId,
        room_no: this.props.room,
        user_type: this.type,
        booking_id: bookingId,
        user_id: 'NA',
        valid_from: finalStartDate,
        valid_till: finalEndDate
      };
      this.props.genCode(payload);
    }
  };

  render() {
    const { startDate, endDate, startTime } = this.state;
    return (
      <div className="activityLayout">
        <div className="activity-heading">
          <div className="activity-label">
            {this.props.heading}
            <button className="close-button" onClick={this.props.close}>
              <i className="fa fa-close" />
            </button>
          </div>
        </div>
        <div>
          {this.type === 0 ? (
            <>
              <div className="date-picker-1">
                Select Start Date:
                <DatePicker
                  onChange={this.changeStartDate}
                  value={startDate}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  required
                />
              </div>
              <div>
                <label>Staff Name: </label>
                <input
                  type="text"
                  onChange={(e) => {
                    this.setState({ userId: e.target.value });
                  }}
                />
              </div>
              <br />
              <div>
                <label>Start Time: </label>
                <TimePicker
                  autofocus={true}
                  format={'hh a'}
                  clockIcon={null}
                  value={startTime}
                  onChange={this.handleTime}
                />
              </div>
              <br />
              <div>
                <label>Duration(in hours): </label>
                {/* <input type="text" onChange={(e)=>{this.setState({duration: e.target.value})}}/> */}
                <select className="dropdown" name="duration" onChange={this.handleDuration}>
                  <option>30 minutes</option>
                  <option>1 hour</option>
                  <option>1.5 hours</option>
                  <option>2 hours</option>
                  <option>2.5 hours</option>
                  <option>3 hours</option>
                  <option>3.5 hours</option>
                  <option>4 hours</option>
                </select>
              </div>
              <button type="submit" className="submit-button" onClick={this.handleSubmit}>
                Submit
              </button>
              {this.props.isLoading ? <div className="loader"></div> : <div></div>}
              {this.props.response.success !== undefined ? (
                <div>
                  {this.props.response.success ? (
                    <>
                      <p style={{ color: 'green' }}>{this.props.response.message}</p>
                      <p>Code: {this.props.response.code}</p>
                    </>
                  ) : (
                    <p style={{ color: 'red' }}>{this.props.response.message}</p>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </>
          ) : (
            <>
              <div className="date-picker">
                <div className="date-picker-1">
                  Select Start Date:
                  <DatePicker
                    onChange={this.changeStartDate}
                    value={startDate}
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                    required
                  />
                </div>
                <div className="date-picker-2">
                  Select End Date:
                  <DatePicker
                    onChange={this.changeEndDate}
                    value={endDate}
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                    required
                  />
                </div>
              </div>
              {/* <div>
                        <label>User Id: </label>
                        <input type="text" onChange={(e)=>{changeUserId(e.target.value)}}/>
                    </div> */}
              <br />
              <div>
                <label>Booking Id: </label>
                <input
                  type="text"
                  onChange={(e) => {
                    this.setState({ bookingId: e.target.value });
                  }}
                />
              </div>
              <button type="submit" className="submit-button" onClick={this.handleSubmit}>
                Submit
              </button>
              {this.props.isLoading ? <div className="loader"></div> : <div></div>}
              {this.props.response.success !== undefined ? (
                <div>
                  {this.props.response.success ? (
                    <>
                      <p style={{ color: 'green' }}>{this.props.response.message}</p>
                      <p>Code: {this.props.response.code}</p>
                    </>
                  ) : (
                    <p style={{ color: 'red' }}>{this.props.response.message}</p>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.mortise.response,
    isLoading: state.mortise.isLoading
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    clearResponse: async () => dispatch(actions.clearResponse()),
    genCode: async (payload) => dispatch(actions.genCode(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateMortiseCode);
