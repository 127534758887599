import { createStore, applyMiddleware, compose } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { ENV_PRODUCTION } from "server/config";
import AllReducers from "./reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let store;
if(process.env.REACT_APP_MESH_ENV === ENV_PRODUCTION ) {
  store = createStore(
    AllReducers,
    composeEnhancers(applyMiddleware(thunk))
  );
} else {
  store = createStore(
    AllReducers,
    composeEnhancers(applyMiddleware(thunk, logger))
  );
}

export {store};