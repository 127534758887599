import React, {Component} from 'react';
import SearchBar from '../../_utilComponents/SearchBar/searchBar';
import {connect} from 'react-redux';
import {findHotelType} from '../../../../utils/HotelTypeUtil';
import Recomm from '../recomm';
import Decomm from '../decomm';
import * as actions from '../redux/actions';
import './decommByRoom.css';

class DecommByRoom extends Component {

    constructor(props) {
        super(props);
        this.state = {
            decommClass : "decomm-link",
            commClass : "decomm-link",
            msg : ""
        }
    }

    componentDidMount(){
        this.props.clearDecommRecommResponse();
    }

    handleChange = async (selectedOption) => {
        if(selectedOption!==null) {
            await this.props.getRoomList(selectedOption.value.hotelId);
            let type;
            if(this.props.roomList.length!==0) {
                if(selectedOption.value!==null || selectedOption.value!==undefined) {
                    type = findHotelType(selectedOption.value.hotelType);
                }
                let hotel = {
                    hotelId : selectedOption.value.hotelId,
                    oyoId : selectedOption.value.oyoId, 
                    hotelName : selectedOption.label,
                    hotelType : type
                }
                this.props.setSelectedHotelRedux(hotel);
                this.setState({
                    msg: ""
                })
            } else {
                this.setState({
                    msg : "No rooms to display for the selected hotel"
                })
            }
        }
    }

    render(){
        return (
            <React.Fragment>
                <div className="search-container">
                    <SearchBar handleChange={this.handleChange}/>
                    { this.props.isLoading ? <div className="loader"></div> : <div></div>}
                    {this.props.recommResponse!=="" ? 
                     <div className="global-decomm-response" style={{color: "green"}}>{this.props.recommResponse}</div>
                     :
                     <div></div>}
                    {this.props.decommResponse!=="" ? 
                     <div className="global-decomm-response" style={{color: "green"}}>{this.props.decommResponse}</div>
                     :
                     <div></div>}
                    { this.props.roomList.length!==0 ?
                    <React.Fragment>
                        <div className="decommission-container">
                            <h2 className="hotel-name">
                                {this.props.selectedHotel.hotelName}|{this.props.selectedHotel.hotelId}|({this.props.selectedHotel.hotelType} property)
                            </h2>
                            <div className="tabs">
                                    <input type="radio" name="tabs" id="tab1" />
                                    <label htmlFor="tab1">Decommission</label>

                                    <input type="radio" name="tabs" id="tab2" />
                                    <label htmlFor="tab2">Recommission</label>
                                <div class="tab content1">
                                    <Decomm type={"room"} />
                                </div>

                                <div className="tab content2">
                                    <Recomm type={"room"} />
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    :
                    <h2 className="hotel-name">
                        {this.state.msg}
                    </h2>
                    }
                </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        isLoading : state.decomm.isLoading,
        roomList : state.decomm.hotelData.roomList,
        selectedHotel : state.decomm.selectedHotel,
        decommResponse : state.decomm.decommRoomsResponse,
        recommResponse : state.decomm.recommRoomsResponse
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getRoomList : async (hotelId) => await dispatch(actions.getRooms(hotelId)),
        setSelectedHotelRedux : async (payload) => await dispatch(actions.setSelectedHotelRedux(payload)),
        clearDecommRecommResponse : async () => await dispatch(actions.clearDecommRecommResponse())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DecommByRoom);