import {hotels as initialState} from './state';

const reducer = (state=initialState, action) => {
    switch(action.type) {
        case "LOAD_HOTEL_LIST_SUCCESS":
            return {
                ...state,
                onLoadHotelData : action.payload
            }
        default:
            return state;
    }
}
export default reducer;