import RESTApi from '../../../../utils/RESTApi';
 import {AuthenticatedDefaultHeaders, DefaultHeaders} from '../../../../Constants/HeadersConstants'; 
import {APIURL} from '../../../../Constants/urlConstant';
import * as actionType from './actionTypes';
import logger from "utils/logger"


const _setIsLoadingTrue = () => {
    return {
        type: actionType.SET_IS_LOADING_TRUE
    }
};
const _setIsLoadingFalse = () => {
    return {
        type: actionType.SET_IS_LOADING_FALSE 
    }
};
const _clearRoomList = () => {
    return {
        type : actionType.CLEAR_ROOM_LIST
    }
};

const _setSelectedHotel = (payload) => {
    return {
        type : actionType.SET_SELECTED_HOTEL,
        payload : payload
    }
};
export const setSelectedHotel = (payload) => {
    return async function(dispatch) {
        dispatch(_setSelectedHotel(payload));
    }
};

export const getRoomList = (payload) => {
    return({
            type: actionType.LOAD_ROOM_LIST_SUCCESS,
            payload: payload
    })
};
export const getRooms = (hotelId) => {
    return async function (dispatch) {
        try{
            await dispatch(_setIsLoadingTrue());
            await dispatch(_clearRoomList());
            let relativeUrl = APIURL.SWITCH_GET_ROOMS + "?hotel-id=" + hotelId;
            let response = await RESTApi.GET(APIURL.SWITCH_BASE_URL, relativeUrl, AuthenticatedDefaultHeaders())
            // logger.log(response);
            if(response.status === 200) {
                await dispatch (getRoomList(response.data));
                dispatch(_setIsLoadingFalse());
            }
        }
        catch(error){
            logger.log(error);
            alert("Error loading list of rooms")
            dispatch(_setIsLoadingFalse());
        }
    }
};

const _clearSelctedHotelAndRooms = () => {
    return {
        type : actionType.CLEAR_HOTEL_AND_ROOMS
    }
};
export const clearHotelAndRooms = () => {
    return async function (dispatch) {
        dispatch(_clearSelctedHotelAndRooms());
    }
};

const _getActivityReasonList = (payload) => {
    return ({
        type: actionType.LOAD_ACTIVITY_REASONS,
        payload: payload
    })
};

export const getActivityReasons = () => {
    return async function (dispatch) {
        try {
            let response = await RESTApi.GET(APIURL.SWITCH_BASE_URL, APIURL.SWITCH_GET_ACTIVITY_REASONS, AuthenticatedDefaultHeaders())
            if(response.status === 200) {
                dispatch (_getActivityReasonList(response.data.entries))
            }
        }
        catch(error){
            logger.log(error);
            // if(error.response.status===504 || error.response.status===503) {
            //     alert("Server temporarily unavailable. Please try later")
            // }
            alert("The list of reasons could not be loaded for the activity you are trying to perform")
        }
    }
};

const _clearV2ActivityResponse = () => {
    return {
        type : actionType.CLEAR_V2_RESPONSE
    }
}
export const clearV2ActivityResponse = () => {
    return async function (dispatch) {
        dispatch(_clearV2ActivityResponse())
    }
}

const _v2ActivityResponse = (payload) => {
    return {
        type : actionType.SET_ACTIVITY_RESPONSE_DATA,
        payload : payload
    }
};
export const getV2ActivityResponse = (payload) => {
    return async function (dispatch) {
        try {
            let response = await RESTApi.POST(APIURL.SWITCH_BASE_URL, APIURL.SWITCH_GET_V2_ACTIVITY_RESPONSE, payload, AuthenticatedDefaultHeaders())
            // logger.log(response)
            if(response.status === 200) {
                dispatch (_v2ActivityResponse(response.data))
            }
        }
        catch(error) {
            // logger.log(error);
            let err_obj = {
                is_success: false,
                message: "Unable to Turn Lights On in the room. Please try later"
            }
            dispatch(_v2ActivityResponse(err_obj))
        }
    }
}

export const crsCheckInSync = (payload) => {
    return async function(dispatch) {
        try {
            let response = await RESTApi.POST(APIURL.SWITCH_BASE_URL, APIURL.SWITCH_CRS_BOOKING_SYNC, payload, AuthenticatedDefaultHeaders());
            logger.log(response);
            if (response.status===200) {
                dispatch(_v2ActivityResponse(response.data));
            }
        }
        catch(error) {
            logger.log(error);
            alert("Unable to check in at the moment");
        }
    }
}

const _v1StaffEntry = (payload) => {
    return {
        type : actionType.SET_STAFF_ENTRY_RESPONSE,
        payload : payload
    }
}
export const getV1ActivityResponse_Staff_Entry = (hotelId, roomNo) => {
    return async function (dispatch) {
        try {
            let relativeUrl = APIURL.SWITCH_V1_STAFF_ENTRY + "?hotel-id=" + hotelId + "&room-no=" + roomNo
            let response = await RESTApi.GET(APIURL.SWITCH_BASE_URL,relativeUrl, DefaultHeaders);
            if(response.status===200) {
                dispatch (_v1StaffEntry(response))
            }
        }
        catch(error) {
            // logger.log(error);
            if(error.response!==undefined && error.response.status===409) {
                dispatch (_v1StaffEntry(error.response))
            } else {
                alert("Unable to turn on switch for staff entry")
            }
        }
    }
}

const _v1OfflineCodes = (payload) => {
    return {
        type: actionType.SET_OFFLINE_CODES_RESPONSE,
        payload: payload
    }
}
export const getV1ActivityResponse_Offline_Codes = (hotelId, roomNo) => {
    return async function (dispatch) {
        try {
            let relativeUrl = APIURL.SWITCH_V1_OFFLINE_CODE + "?hotel-id=" + hotelId + "&room-no=" + roomNo;
            let response = await RESTApi.GET(APIURL.SWITCH_BASE_URL, relativeUrl, DefaultHeaders);
            if(response.status===200) {
                dispatch(_v1OfflineCodes(response));
            }
        }
        catch(error) {
            if(error.response!==undefined && error.response.status === 400) {
                // logger.log(error.response)
                dispatch(_v1OfflineCodes(error.response));
            } else {
                alert("Unable to retrieve Offline Codes at the moment")
            }
            // logger.log(error);
        }
    }
}

const _v1CheckIn = (payload) => {
    return {
        type : actionType.V1_CHECK_IN,
        payload : payload
    }
}
export const getV1ActivityResponse_Check_In = (payload) => {
    return async function (dispatch) {
        try {
            let response = await RESTApi.POST(APIURL.SWITCH_BASE_URL, APIURL.SWITCH_V1_CHECK_IN, payload, AuthenticatedDefaultHeaders());
            // logger.log("Inside Check In")
            if(response.status===200) {
                if(response.data===""){
                    response.data = "Check In Successful";
                }
                dispatch(_v1CheckIn(response))
            }
        }
        catch(error) {
            // logger.log(error);
            alert("Unable to check in to the room at the moment")
        }
    }
}

const _bulkDurationsAndReasonsList = (payload) => {
    return {
        type: actionType.SET_BULK_CHECKIN_DATA,
        payload: payload
    }
}
export const getBulkDurationsAndReasons = () => {
    return async function (dispatch) {
        try {
            let response = await RESTApi.GET(APIURL.SWITCH_BASE_URL, APIURL.SWITCH_BULK_DURATIONS_AND_REASONS, AuthenticatedDefaultHeaders())
            if(response.status === 200) {
                dispatch(_bulkDurationsAndReasonsList(response.data))
            }
        }
        catch(error) {
            // logger.log(error);
            alert("List of allowed reasons and durations for bulk check in could not be loaded")
        }
    }
}

const _clearBulkResponse = () => {
    return {
        type : actionType.CLEAR_BULK_RESPONSE
    }
}
export const clearBulkResponse = () => {
    // logger.log("Calling Clear Bulk Response")
    return async function (dispatch) {
        dispatch(_clearBulkResponse);
    }
}

const _postBulkCheckInData = (payload) => {
    return {
        type : actionType.BULK_CHECK_IN_RESPONSE,
        payload : payload
    }
}
export const postBulkCheckIn = (payload) => {
    return async function (dispatch) {
        dispatch(_setIsLoadingTrue())
        try {
            let response = await RESTApi.POST(APIURL.SWITCH_BASE_URL, APIURL.SWITCH_BULK_CHECK_IN, payload, AuthenticatedDefaultHeaders());
            if(response.status===200) {
                dispatch(_postBulkCheckInData(response.data));
                dispatch(_setIsLoadingFalse())
            }
        }
        catch(error) {
            alert("Unable to perform bulk check in at the moment");
            dispatch(_setIsLoadingFalse());
        }
    }
}