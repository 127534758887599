import { decrypt } from "./../utils/EncryptDecryptUtil";
import { Constants } from "./Constants";

let email, authToken, ssoToken;
try {
  email = decrypt(localStorage.getItem(Constants.localStorageKeys.EMAIL));
  authToken = decrypt(
    localStorage.getItem(Constants.localStorageKeys.AUTH_TOKEN)
  );
} catch (error) {}

try {
  ssoToken = decrypt(
    localStorage.getItem(Constants.localStorageKeys.SSO_TOKEN)
  );
} catch (error) {}

export const refreshAuthHeaders = () => {
  try {
    email = decrypt(localStorage.getItem(Constants.localStorageKeys.EMAIL));
    authToken = decrypt(
      localStorage.getItem(Constants.localStorageKeys.AUTH_TOKEN)
    );
    ssoToken = decrypt(
      localStorage.getItem(Constants.localStorageKeys.SSO_TOKEN)
    );
  } catch (error) {}
};

export const V1Headers = {
  clientId: 4,
  serviceKey: "45ae56c8-2972-439c-b289-7eb0d480d6f2",
};

export const AuthenticatedV1Headers = () => {
  return {
    email: email,
    authToken: authToken,
    clientId: 4,
    serviceKey: "45ae56c8-2972-439c-b289-7eb0d480d6f2",
  };
};

export const DefaultHeaders = {
  clientId: Constants.CLIENT_ID,
  serviceKey: Constants.SERVICE_KEY,
  appVersion: Constants.APP_VERSION,
  "Access-Control-Allow-Origin": "*",
};

let isLoggedInViaSSO = () => {
  if (ssoToken !== undefined && ssoToken !== null) {
    return true;
  } else {
    return false;
  }
};

export const AuthenticatedDefaultHeaders = () => {
  if (isLoggedInViaSSO()) {
    return {
      clientId: Constants.CLIENT_ID,
      serviceKey: Constants.SERVICE_KEY,
      appVersion: Constants.APP_VERSION,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      ssoToken: ssoToken,
    };
  } else {
    return {
      clientId: Constants.CLIENT_ID,
      serviceKey: Constants.SERVICE_KEY,
      appVersion: Constants.APP_VERSION,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      email: email,
      authToken: authToken,
    };
  }
};

export const AuthenticatedSmartlockHeaders = () => {
  return {
    clientId: 4,
    serviceKey: "45ae56c8-2972-439c-b289-7eb0d480d6f2",
    email: email,
    authToken: authToken,
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
};

export const SmartRoomHeaders = {
  clientId: 2,
  serviceKey: "2ojfUq08BKYUhlKo1U7V",
};

export const AuthenticatedBleAppHeaders = () => {
  if (isLoggedInViaSSO()) {
    return {
      clientId: 10,
      serviceKey: "2328f8ab-d47c-445c-ag50-fehjdhdc700c",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      ssoToken: ssoToken,
    };
  } else {
    return {
      clientId: 10,
      serviceKey: "2328f8ab-d47c-445c-ag50-fehjdhdc700c",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      email: email,
      authToken: authToken,
    };
  }
};
