import React, {Component} from 'react';
import {connect} from 'react-redux';
import Table from './Table/table';
import SearchBar from '../_utilComponents/SearchBar/searchBar';
import Bulk from './BulkCheckIn/bulkCheckIn';
import Popup from 'reactjs-popup';
import {findHotelType} from '../../../utils/HotelTypeUtil';
import * as actions from './redux/actions';
import './hotelDetails.css';
import logger from "utils/logger"

class HotelDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedHotelId: "",
            selectedHotelName: "",
        };
    }
    componentDidMount() {
        this.props.getActivityReasons();
    }
    handleChange = async (selectedOption) => {
        if(selectedOption===null) {
            logger.log("Field has been cleared");
        } else {
            const value = selectedOption.value.hotelId;
            const label = selectedOption.label;
            let type;
            if(selectedOption.value!==null && selectedOption.value!==undefined)
                type = findHotelType(selectedOption.value.hotelType);
            await this.setState({
                selectedHotelId: value,
                selectedHotelName: label,
                selectedHotelType: type
            });
            let selectedHotel = {
                hotelId : this.state.selectedHotelId,
                hotelName : this.state.selectedHotelName,
                hotelType : this.state.selectedHotelType
            }
            this.props.setSelectedHotelRedux(selectedHotel) //So that the data stays even when switching tabs
            this.props.getRooms(this.state.selectedHotelId)
        }
    }
    render() {
        return (
            <React.Fragment>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            <div className="search-container">
                <SearchBar handleChange={this.handleChange} />
                {this.props.isLoading===true ? <div className="loader"></div> : ""}
                { this.props.roomList.length !==0 &&
                <div className="bulk-button">
                <Popup trigger={
                    <button className="bulk-access-button">Bulk Check In</button>
                }modal>
                   { close => (
                            <div className="activityLayout">
                                <div className="activity-heading">
                                    <div className="activity-label">Bulk Check In
                                        <button className="close-button" onClick={close}>
                                            <i className="fa fa-close"/>
                                        </button>
                                    </div>
                                </div> 
                                {<Bulk />}
                            </div> )
                    }
                </Popup>
                </div> }
                <Table />
            </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    const {hotel} = state
    return {
        hotelData : hotel.onLoadHotelData,
        isLoading : hotel.isLoading,
        roomList : hotel.hotelData.roomList,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getActivityReasons : async () => await dispatch(actions.getActivityReasons()),
        getRooms : async (hotelId) => await dispatch(actions.getRooms(hotelId)),
        setSelectedHotelRedux : async (payload) => dispatch(actions.setSelectedHotel(payload)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HotelDetails);