import React,{Component} from 'react';
import './V1ActivityButton.css';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';

class ActivityButton extends Component
{
  state={
    value:'',
    data:'',
    color:false
  }

  staffEntry = async (e) => {
    e.preventDefault();
    let hotelId = this.props.hotelId;
    let roomNo = this.props.roomNo;
    await this.props.staffEntry(hotelId, roomNo);

    let val = this.props.staffEntryData.status === 200 ? true : false;
    this.setState({
      value: this.props.staffEntryData.data,
      color: val,
      data:'Staff Entry'
    }) 
    setTimeout(await this.props.getRoomList, 5000, this.props.hotelId);
  }
  offlineCode = async (e) => {
    e.preventDefault();
    let hotelId = this.props.hotelId;
    let roomNo = this.props.roomNo;
    await this.props.offlineCode(hotelId, roomNo);
    if (this.props.offlineCodeData) {
      let val = this.props.offlineCodeData.status === 200 ? true : false;
      if (!val) {
        this.setState({
          value: this.props.offlineCodeData.data,
          color: val,
          data:'Offline Code'
        })   
      } else {
        this.setState({
          value: "Offline code: " + this.props.offlineCodeData.data.offline_code,
          color: val,
          data:'Offline Code'
        })
      }
    } else {
      this.setState({
        value: "Error loading offline code",
        color: false,
        data:'Offline Code'
      })
    }
  }

  checkIn = async (e) => {
    e.preventDefault();
    // logger.log("room Id: ",this.props.roomId)
    let payload = {
      "hotel_id" : this.props.hotelId,
      "room_id" : this.props.roomId
    }
    await this.props.checkIn(payload);
    let val = this.props.checkInData.status === 200 ? true : false;
    this.setState({
      value: this.props.checkInData.data,
      color: val,
      data : 'Check In'
    })
    setTimeout(await this.props.getRoomList, 5000, this.props.hotelId);
  }
   
  render() {
    return(
      <div>
        <div className="Hotel-Details">
          <div className="Hotel-Details-Text">Hotel Id: <strong>{this.props.hotelId}</strong></div>
          <div className="Hotel-Details-Text">Room No: <strong>{this.props.roomNo}</strong></div>
          <div className="Hotel-Details-Text">Device Id: <strong>{this.props.deviceId}</strong></div>
        </div>
        <div className="Wifi-Details">
          <div className="Wifi-Details-Text">Wifi Name: <strong>{this.props.wifiSsid}</strong></div>
          <div className="Wifi-Details-Text">Wifi Password: <strong>{this.props.wifiPassword}</strong></div>
        </div>
        <form className="activity-form">
          <div className="activity-text"style={this.state.color?{color:'green'}:{color:'red'}}>
            {this.state.data!=="Offline Code" && this.state.data!=="" ? this.state.value + ". Refreshing room list in 5s": this.state.value}
          </div>
          <div className="activity-button-layout">
            <button type='submit' className="submit-button" name="staff" value="Hello" onClick={this.staffEntry}>Staff Entry</button>
            <button type='submit' className="submit-button" name="" value="bye" onClick={this.offlineCode}>Offline codes</button>
            <button type='submit' className="submit-button" name='' value="check-in" onClick={this.checkIn}>Turn Lights On</button>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    staffEntryData: state.hotel.v1ActivityResponse.staffEntry,
    offlineCodeData: state.hotel.v1ActivityResponse.offlineCodes,
    checkInData: state.hotel.v1ActivityResponse.checkIn
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    staffEntry : async (hotelId, roomNo) => await dispatch(actions.getV1ActivityResponse_Staff_Entry(hotelId, roomNo)),
    offlineCode : async (hotelId, roomNo) => await dispatch(actions.getV1ActivityResponse_Offline_Codes(hotelId, roomNo)),
    checkIn : async (payload) => await dispatch(actions.getV1ActivityResponse_Check_In(payload)),
    getRoomList : async (hotelId) => await dispatch(actions.getRooms(hotelId))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ActivityButton);