import React, {Component} from 'react';
import {connect} from 'react-redux';
import './deviceCount.css'

class DeviceCount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            installedDevices : "",
            commissionedDevices : "",
            onlineDevices : ""
        }
    }
    componentDidMount(){
        if(this.props.roomList.length!==0) {
            let installedArray = this.props.roomList.filter(room => {
                if(room.device_id) {
                    return room.device_id
                }
                return null;
            })
            let commissionedArray = this.props.roomList.filter(room => {
                if(room.is_enabled) {
                    return room.device_id
                }
                return null;
            })
            let onlineArray = this.props.roomList.filter(room=>{
                if(room.is_connected) {
                    return room.device_id
                }
                return null;
            })
            this.setState({
                installedDevices : installedArray.length,
                commissionedDevices : commissionedArray.length,
                onlineDevices : onlineArray.length
            })
        }
    }
    render() {
        return ( this.props.roomList &&
            <div className="device-details flex-column-center">
                    <div className="device-details-field">
                        <span className="device-details-label">No. of Installed Devices</span>
                        <span className="device-details-value">{this.state.installedDevices}</span>
                    </div>
                    <div className="device-details-field">
                        <span className="device-details-label">No. of Commissioned Devices</span>
                        <span className="device-details-value">{this.state.commissionedDevices}</span>
                    </div>
                    <div className="device-details-field">
                        <span className="device-details-label">No. of Online Devices</span>
                        <span className="device-details-value">{this.state.onlineDevices}</span>
                    </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        roomList : state.hotel.hotelData.roomList
    }
}

export default connect(mapStateToProps)(DeviceCount);