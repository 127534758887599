import React, {Component} from 'react';
import CheckBox from '../../../_utilComponents/Checkboxes/Checkboxes';
import {connect} from 'react-redux';
import Popup from 'reactjs-popup';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as actions from '../../redux/actions';
import './table.css';
import logger from "utils/logger"

let arrowMap = new Map(
    [
        ["hotel_id", ''],
        ["hotel_name", ''],
        ["hotel_type", ''],
        ["oyo_id", '']
    ]
);

let listLength;

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedItems: new Map(),
            checkedAll: false,
            reason: "",
            city : "",
        }
        this.handleHeadChange = this.handleHeadChange.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
    }

    async componentDidMount(){
        await this.props.getHotelList();
        // this.props.clearResponse();
        let finalHotelList;
        if(this.props.selectedCity!==null) {
            let cityHotelList = this.props.hotelList.filter(hotel => {
                if(hotel.oyo_id.slice(0,3)===this.props.selectedCity)
                    return hotel;
                return null;
            })
            logger.log(cityHotelList);
            let tableData = cityHotelList.map(tableRow => {
                if(tableRow.is_enabled===this.props.isCommissioned) {
                    return tableRow;
                }
                else {
                    return null;
                }
            });
            logger.log(tableData);
            finalHotelList = tableData.filter(tableRow => {
                if(tableRow !== null)
                    return tableRow;
                return null;
            })
            logger.log(finalHotelList);
        }
        listLength = finalHotelList.length;
        this.setState({
            checkedAll: false,
            checkedItems: new Map()
        })
    }

    sortBy = async (key) => {
        let i;
        let copyArray = [];
        let len = this.props.hotelList.length;
        for(i=0;i<len;i++) {
            copyArray[i] = this.props.hotelList[i];
        }
        logger.log("Before: ", copyArray)
        let arrayKey = Array.from(arrowMap.keys());
        if (key === arrayKey[arrayKey.indexOf(key)]) {
            if (arrowMap.get(key) === '') {
                arrowMap.set(key, true);
                copyArray.sort(this.ascendingOrder(key));
                logger.log("After: ", copyArray)
                await this.props.setHotelList(copyArray);
            } else if (arrowMap.get(key) === true) {
                arrowMap.set(key, false);
                copyArray.sort(this.descendingOrder(key));
                logger.log("After: ", copyArray);
                await this.props.setHotelList(copyArray);
            } else if (arrowMap.get(key) === false) {
                arrowMap.set(key, true);
                copyArray.sort(this.ascendingOrder(key));
                logger.log("After: ", copyArray);
                await this.props.setHotelList(copyArray);
            }
        }
    };

    ascendingOrder = key => {
        if (key === "room_no") {
            return function (a, b) {
                if (Number(a[key]) < Number(b[key])) {
                    return -1;
                }
                if (Number(a[key]) > Number(b[key])) {
                    return 1;
                }
                return 0;
            };
        } else {
            return function (a, b) {
                if (a[key] < b[key]) {
                    return -1;
                }
                if (a[key] > b[key]) {
                    return 1;
                }
                return 0;
            };
        }
    };

    descendingOrder = key => {
        if (key === "room_no") {
            return function (a, b) {
                if (Number(a[key]) > Number(b[key])) {
                    return -1;
                }
                if (Number(a[key]) < Number(b[key])) {
                    return 1;
                }
                return 0;
            };
        } else {
            return function (a, b) {
                if (a[key] > b[key]) {
                    return -1;
                }
                if (a[key] < b[key]) {
                    return 1;
                }
                return 0;
            };
        }
    };
    
    setCheckedAll = () => {
        let valueArray = this.state.checkedItems.values();
        let ele;
        let count = 0;
        let flag = 0;
        for(ele of valueArray) {
            if(ele === false) {
                flag = 1;
                break;
            }
            else {
                count++;
            }
        }
        logger.log(flag, count)
        if(flag===1 && this.state.checkedAll===true) {
            logger.log("Came here and set it to false")
            this.setState({
                checkedAll: false
            })
        } 
        else if(flag===0 && this.state.checkedAll===false && count===listLength) {
            this.setState({
                checkedAll: true
            })
        }
    }
    
    handleHeadChange = () => {
        let val;
        let i;
        let newCheckedItems = new Map();
        let cityHotelList = this.props.hotelList.filter(hotel => {
                if(hotel.oyo_id.slice(0,3)===this.props.selectedCity)
                    return hotel;
                return null;
            })
            let tableData = cityHotelList.map(tableRow => {
                if(tableRow.is_enabled===this.props.isCommissioned) {
                    return tableRow;
                }
                else {
                    return null;
                }
            });
            let finalHotelList = tableData.filter(tableRow => {
                if(tableRow !== null)
                    return tableRow;
                return null;
            })
        if(!this.state.checkedAll) {
            let len = finalHotelList.length;
            for(i=0;i<len;i++) {
                if(finalHotelList[i].is_enabled === this.props.isCommissioned) {
                    newCheckedItems.set(finalHotelList[i].oyo_id, true)
                }
            }
            val = true;
        } else {
            val = false;
        }
        logger.log(newCheckedItems);
        this.setState ({
            checkedItems: newCheckedItems,
            checkedAll: val
        })
    }

    handleRowClick = async (oyoId) => {
        const item = oyoId;
        let isChecked = this.state.checkedItems.get(oyoId);
        if(isChecked === undefined||isChecked === false) {
            isChecked = true;
        }
        else if(isChecked===true) {
            isChecked = false;
            if(this.state.checkedAll===true) {
                await this.setState({
                    checkedAll:false
                })
            }
        }
        if (item !== undefined && item !== null) {
            await this.setState(prevState => 
                ({checkedItems: prevState.checkedItems.set(item, isChecked)}));
        }
        this.setCheckedAll();
    }

    setReason = (e) => {
        logger.log(e.target.value);
        if(e.target.value) {
            this.setState({
                reason: e.target.value
            })
        }
    }

    handleSubmit = async (arr, close) => {
        close();
        await this.props.onSubmit(arr, this.state.checkedAll, this.state.reason, "hotel");
        this.componentDidMount();
    }

    arrow = (keyValue) => (
        arrowMap.get(keyValue)
            ? <FontAwesomeIcon icon="sort-alpha-down" style={{color: 'grey'}} className="arrow-sort"/>
            : <FontAwesomeIcon icon="sort-alpha-up" style={{color: 'grey'}} className="arrow-sort"/>
    )

    render(){
        logger.log("Rendering table");
        listLength = 0;
        let finalHotelList;
        if(this.props.selectedCity!==null) {
            let cityHotelList = this.props.hotelList.filter(hotel => {
                if(hotel.oyo_id.slice(0,3)===this.props.selectedCity)
                    return hotel;
                return null;
            })
            logger.log(cityHotelList);
            let tableData = cityHotelList.map(tableRow => {
                if(tableRow.is_enabled===this.props.isCommissioned) {
                    return tableRow;
                }
                else {
                    return null;
                }
            });
            finalHotelList = tableData.filter(tableRow => {
                if(tableRow !== null)
                    return tableRow;
                return null;
            })
        }
        listLength = finalHotelList.length;
        let buttonText = this.props.isCommissioned ? "Decommission Hotels" : "Recommission Hotels";
        let arr=[];
        if(this.state.checkedItems.length!==0) {
            this.state.checkedItems.forEach((value, key) => {
                if(value === true) {
                    arr.push(key);
                }
            })
        } 
        // else {
        //     this.componentDidMount();
        // }
        if(this.props.selectedCity.length!==0) {
            if(finalHotelList.length!==0) {
                return (
                    <div className="tableAlign">
                        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                        <div className="decomm-button-container">
                            <Popup trigger = {
                            <button className="refresh-button">
                                {buttonText}
                            </button> } modal>
                            {close => (
                                <div className="activityLayout">
                                    <div className="activity-heading">
                                        <div className="activity-label">{buttonText}
                                            <button className="close-button" onClick={close}>
                                                <i className="fa fa-close"/>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        {arr.length===0 && this.state.checkedAll===false ?
                                            <div className="selected-error">Please select hotels to {buttonText}</div>
                                            :
                                            <React.Fragment>
                                            <div className="selected">{this.state.checkedAll ? "All hotels have been selected" : "Selected hotels are:  " + JSON.stringify(arr).slice(1,-1)}</div>
                                            <div>If you want to {buttonText}, enter the reason and click Confirm</div>
                                            <br></br>
                                            <label>Enter Reason: </label><input className="text-box" type="text" name="reason" onChange={this.setReason} placeholder="Enter reason here" required></input>
                                            <br></br>
                                            <button type="submit" className="submit-button-decomm" onClick={() => {this.handleSubmit(arr, close)}}>
                                                Confirm
                                            </button>
                                            <button className="cancel-button-decomm" onClick={close}>
                                                Cancel
                                            </button>
                                            </React.Fragment>
                                        }
                                        {this.props.isLoading ? <div className="bulk-loader"></div> : <div></div>}
                                    </div>
                                </div>
                            )}
                            </Popup>
                        </div>
                        <table className="hotel-Admin-layout" width="20% !important">
                            <thead className="tableHead">
                                <tr>
                                    <td className="table-heading"><CheckBox name="All" onChange={this.handleHeadChange} checked={this.state.checkedAll}/></td>
                                    <td className="table-heading" onClick={()=>{this.sortBy('hotel_id')}}>Hotel Id {this.arrow('hotel_id')}</td>
                                    <td className="table-heading" onClick={()=>{this.sortBy('hotel_name')}}>Hotel Name {this.arrow('hotel_name')}</td>
                                    <td className="table-heading" onClick={()=>{this.sortBy('hotel_type')}}>Hotel Type {this.arrow('hotel_type')}</td>
                                    <td className="table-heading" onClick={()=>{this.sortBy('oyo_id')}}>OYO Id {this.arrow('oyo_id')}</td>
                                </tr>
                            </thead>
                            <tbody className="body-layout">
                                {finalHotelList.map(hotel => {
                                    return (
                                        <tr className="trColumn" onClick={()=>{this.handleRowClick(hotel.oyo_id)}}>
                                            <td className="tableColumn"><CheckBox name={hotel.room_no} checked={this.state.checkedItems.get(hotel.oyo_id)}/></td>
                                            <td className="tableColumn">{hotel.hotel_id}</td>
                                            <td className="tableColumn">{hotel.hotel_name}</td>
                                            <td className="tableColumn">{hotel.hotel_type}</td>
                                            <td className="tableColumn">{hotel.oyo_id}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                )
            } else {
                if(this.props.isCommissioned===true) {
                    return (
                        <div className="table-option-heading">
                            {/* <h2 className="hotel-name">{this.props.selectedCity}</h2> */}
                            <div className="decommission-error">This City has no commissioned hotels to decommission</div>
                        </div>
                    )
                }
                else {
                    return (
                        <div className="table-option-heading">
                            {/* <h2 className="hotel-name">{this.props.selectedCity}</h2> */}
                            <div className="decommission-error">This City has no decommissioned hotels to commission</div>
                        </div>                
                    )
                }
            }
        } 
        else {
            return (
                <div></div>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        hotelList: state.decomm.hotelList,
        isLoading : state.decomm.isLoading,
        selectedCity : state.decomm.selectedCity,
        decommResponse : state.decomm.decommRoomsResponse,
        recommResponse : state.decomm.recommRoomsResponse
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getHotelList : async () => dispatch(actions.getHotelList()),
        clearResponse : async () => dispatch(actions.clearDecommRecommResponse()),
        setHotelList : async (payload) => dispatch(actions.setHotelList(payload)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Table);