import React from 'react';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import logger from 'utils/logger';

class UpdateMasterPin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMasterPin: this.props.currMasterPin,
      newMasterPin: '',
      deviceId: this.props.deviceId,
      propertrId: this.props.propId
    };
  }

  componentDidMount() {
    this.props.clearResponse();
  }

  changeMasterPin = (e) => {
    let inputValue = e.target.value;
    const regexAlphaNumeric = /^\w+$/;
    if (!inputValue.match(regexAlphaNumeric)) {
      e.target.value = '';
    }
    if (inputValue !== null || inputValue !== undefined || inputValue !== '') {
      this.setState({ newMasterPin: inputValue });
    }
  };

  handleSubmit = (deviceId, propId) => {
    const masterPin = this.state.newMasterPin;

    if (masterPin === '' || masterPin === null) {
      alert('Please enter a valid Master PIN');
      return;
    }

    let payload = {
      user_id: this.props.userId,
      userProfileId: this.props.userProfileId,
      email: this.props.email,
      deviceId: deviceId,
      property_id: propId,
      master_pin: masterPin
    };
    logger.log('payload for api is ', JSON.stringify(payload));
    this.props.updateMasterPin(payload);
  };

  render() {
    return (
      <>
        <div className="activityLayout">
          <div className="activity-heading">
            <div className="activity-label">
              Update Master PIN
              <button className="close-button" onClick={this.props.close}>
                <i className="fa fa-close" />
              </button>
            </div>
          </div>
          <div>
            <div>
              <label className="title-text">Lock ID: {this.state.deviceId}</label>
            </div>
            <div>
              <div className="title-text">Property ID: {this.state.propertrId}</div>
              <div className="title-text">Current Master PIN: {this.state.currentMasterPin}</div>
            </div>
            <div className="form-label">
              <label>New Master PIN </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                onChange={this.changeMasterPin}
              />
            </div>
            <br></br>
            {/* <div>
                        <label>User Id: </label>
                        <input type="text" onChange={changeUserId}/>
                    </div> */}
            <button
              type="submit"
              className="ml-5 flex-wrap text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              onClick={() => {
                this.handleSubmit(this.props.deviceId, this.props.propId);
              }}
            >
              Submit
            </button>
            {this.props.response ? (
              <div>
                {this.props.response.success ? (
                  <>
                    <p style={{ color: 'green' }}>{this.props.response.message}</p>
                  </>
                ) : (
                  <p style={{ color: 'red' }}>{this.props.response.message}</p>
                )}
              </div>
            ) : (
              <div></div>
            )}
            {this.props.isLoading ? <div className="loader"></div> : <div></div>}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.smartlockButtons.response,
    isLoading: state.smartlockButtons.isLoading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMasterPin: async (payload) => await dispatch(actions.updateMasterPin(payload)),
    clearResponse: async () => dispatch(actions.clearResponse())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateMasterPin);
