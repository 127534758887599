import React, { Component } from 'react';
import { Constants, OVHMenuOptionsMap } from '../../../../Constants/Constants';
import { Link } from 'react-router-dom';
import { decrypt } from '../../../../utils/EncryptDecryptUtil';

class menubar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unselectedName: 'link-hover',
      selectedName: 'link-hover-selected'
    };
  }

  handleClick = (e) => {
    let elementList = document.getElementsByClassName('menubar')[0];
    let classList = elementList.getElementsByClassName(this.state.selectedName);
    while (classList && classList.length) {
      classList[0].className = this.state.unselectedName;
    }
    e.target.className = this.state.selectedName;
  };

  render() {
    const userAccess = JSON.parse(
      decrypt(localStorage.getItem(Constants.localStorageKeys.USER_ACCESS))
    );
    // User can have multiple roles for same product, Choosing 0th because allowed roles should be sorted by priority
    // userAccess.features is actually an array of role object
    let menuList = [];
    if (
      userAccess !== undefined &&
      userAccess !== null &&
      userAccess[0] !== undefined &&
      userAccess[0] !== null
    ) {
      // menuList = userAccess[0].menuList;
      menuList.push('Hotel-Details');
      // Commented as not been in use
      // menuList.push("PSP-Details");
      menuList.push('Device-Details');
    }
    let menuOptions = (
      <ul className="menubar">
        {menuList.map((eachOption) => {
          return (
            <li key={eachOption}>
              <Link
                to={OVHMenuOptionsMap.get(eachOption)}
                className={this.state.unselectedName}
                onClick={this.handleClick}
              >
                {eachOption}
              </Link>
            </li>
          );
        })}
      </ul>
    );
    return <div>{menuOptions}</div>;
  }
}

export default menubar;
