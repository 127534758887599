import React from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-date-picker';
import DateParsingUtil from './../../utils/DateParsingUtil';
import * as actions from './redux/actions';
import * as TimeZoneConstants from '../../Constants/TimeZoneConstants';
import logger from 'utils/logger';

class GenNewCode extends React.Component {
  constructor(props) {
    super(props);

    let today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    let tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);
    tomorrow.setSeconds(0);
    tomorrow.setMilliseconds(0);

    this.state = {
      checkInTime: today,
      checkOutTime: tomorrow,
      bookingId: '',
      selectedTimeZone: 0
    };
  }

  componentDidMount() {
    this.props.clearResponse();
    this.setState({ selectedTimeZone: this.props.timeZoneOffset });
    logger.log('time zone offset is ', this.state.selectedTimeZone);
  }

  handleTimeZoneChange = (e) => {
    this.setState({ selectedTimeZone: e.target.value });
  };

  changeBookingId = (e) => {
    let inputValue = e.target.value;
    const regexAlphaNumeric = /^\w+$/;
    if (!inputValue.match(regexAlphaNumeric)) {
      e.target.value = '';
    }
    if (inputValue !== null || inputValue !== undefined || inputValue !== '') {
      this.setState({ bookingId: inputValue });
    }
  };

  // const changeUserId = (e) => {
  //   if (e.target.value !== null || e.target.value !== undefined || e.target.value !== "") {
  //     setUserId(e.target.value);
  //   }
  // };

  changeStartDate = (date) => {
    if (date !== null) {
      if (this.state.checkOutTime !== '' && this.state.checkOutTime < date) {
        alert('Checkout Date cannot be earlier than Check In Date. Please enter the correct date');
      } else {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        this.setState({ checkInTime: date });
      }
    } else {
      this.setState({ checkInTime: '' });
    }
  };

  changeEndDate = (date) => {
    if (date !== null) {
      if (this.state.checkInTime !== '' && this.state.checkInTime > date) {
        alert('Check In Date cannot be after Check Out Date. Please enter the correct date');
      } else {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        this.setState({ checkOutTime: date });
      }
    } else {
      this.setState({ checkOutTime: '' });
    }
  };

  handleSubmit = (propId, roomNo) => {
    const { selectedTimeZone, checkInTime, checkOutTime, bookingId } = this.state;

    logger.log('selected TimeZone is ', selectedTimeZone);

    if (checkInTime === '' || checkInTime === null) {
      alert('Please enter a valid check in time');
      return;
    }
    if (checkOutTime === '' || checkOutTime === null) {
      alert('Please enter a valid chekout time');
      return;
    }
    if (bookingId === '' || bookingId === null) {
      alert('Please enter a valid booking ID');
      return;
    }

    if (selectedTimeZone === '' || selectedTimeZone == null) {
      alert('Please Select a Valid Time Zone');
      return;
    }

    checkInTime.setHours(12);
    let startDate = DateParsingUtil.changeStandardToEpoch(checkInTime);
    let timeOffset = new Date().getTimezoneOffset(); //gives offset of local browser time from utc time

    startDate = DateParsingUtil.getTimeZoneOffsetEpoch(startDate, selectedTimeZone - timeOffset); //this subtraction is done to handle the case where i can make a buking of netherlands from india without affecting the time as new Date() takes the local browser time so have to offset that.

    checkOutTime.setHours(12);
    let endDate = DateParsingUtil.changeStandardToEpoch(checkOutTime);
    endDate = DateParsingUtil.getTimeZoneOffsetEpoch(endDate, selectedTimeZone - timeOffset);

    let payload = {
      property_id: propId,
      room_no: roomNo,
      booking_id: bookingId,
      checkin_time: startDate,
      checkout_time: endDate,
      user_id: this.props.userId
    };
    logger.log('payload for api is ', JSON.stringify(payload));
    this.props.genNewCode(payload);
  };

  render() {
    return (
      <>
        <div className="activityLayout">
          <div className="activity-heading">
            <div className="activity-label">
              Generate New Code
              <button className="close-button" onClick={this.props.close}>
                <i className="fa fa-close" />
              </button>
            </div>
          </div>
          <div>
            <div>
              <select
                defaultValue={this.state.selectedTimeZone}
                value={this.state.selectedTimeZone}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={this.handleTimeZoneChange}
              >
                {TimeZoneConstants.allTimeZones.map((ele) => (
                  <option value={ele.value}>{ele.text}</option>
                ))}
              </select>
            </div>
            <div>
              <div className="form-label">
                Select Start Date:
                <DatePicker
                  onChange={this.changeStartDate}
                  value={this.state.checkInTime}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  required
                />
              </div>
              <div className="form-label">
                Select End Date:
                <DatePicker
                  onChange={this.changeEndDate}
                  value={this.state.checkOutTime}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  required
                />
              </div>
            </div>
            <div className="form-label">
              <label>Booking Id: </label>
              <input
                type="text"
                className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={this.changeBookingId}
              />
            </div>
            <br></br>
            {/* <div>
                        <label>User Id: </label>
                        <input type="text" onChange={changeUserId}/>
                    </div> */}
            <button
              type="submit"
              className="submit-button text-gray-900 mt-2 bg-white border border-gray-300 focus:outline-none  focus:ring-gray-200 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              onClick={() => {
                this.handleSubmit(this.props.propId, this.props.roomNo);
              }}
            >
              Submit
            </button>
            {this.props.response ? (
              <div>
                {this.props.response.success ? (
                  <>
                    <p style={{ color: 'green' }}>{this.props.response.message}</p>
                    <p style={{ color: 'green' }}>Code: {this.props.response.code}</p>{' '}
                  </>
                ) : (
                  <p style={{ color: 'red' }}>{this.props.response.message}</p>
                )}
              </div>
            ) : (
              <div></div>
            )}
            {this.props.isLoading ? <div className="loader"></div> : <div></div>}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.smartlockButtons.response,
    isLoading: state.smartlockButtons.isLoading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    genNewCode: async (payload) => await dispatch(actions.genNewCode(payload)),
    clearResponse: async () => dispatch(actions.clearResponse())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenNewCode);
