import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import * as actions from './redux/actions';
import * as smartlockButtonActions from './../../../CommonUI/SmartlockButtons/redux/actions';
import 'react-toastify/dist/ReactToastify.css';
import { Constants } from '../../../Constants/Constants';
import { decrypt } from '../../../utils/EncryptDecryptUtil';
import { AuthenticatedSmartlockHeaders } from '../../../Constants/HeadersConstants';
import { APIURL } from '../../../Constants/urlConstant';
import RESTApi from '../../../utils/RESTApi';
import { COLUMNS_HOTEL_DETAILS } from './column';
import Loader from './Loader';
import ViewCode from './ViewCode';
import './hotelDetails.css';
import { useIdleTimer } from 'react-idle-timer';
import Modal from 'react-modal';
import * as loginActions from '../../../Components/Login/redux/actions';
import { clearLocalStorage } from '../../../utils/localStorageUtil';

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div className="m-5">
      <input
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="username"
        type="text"
        placeholder="Filter using Property Name or Property Code or Type"
        value={filter || ''}
        onChange={(e) => setFilter(e.target.value.trim())}
      />
    </div>
  );
};

const BookingSearch = () => {
  const [bookingID, setBookingID] = useState('');
  return (
    <>
      <div className="m-5 flex ">
        <div className="flex-wrap">
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="bookingID"
            type="text"
            onChange={(e) => setBookingID(e.target.value)}
            placeholder="Booking ID"
          />
        </div>
        <div>
          <ViewCode bookingID={bookingID.trim()} />
        </div>
        <div>
          <button
            className="ml-5 flex-wrap text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
            onClick={() => window.location.reload()}
          >
            Refresh
          </button>
        </div>
      </div>
    </>
  );
};

const HotelDetails = ({ logoutUser }) => {
  const [response, setResponse] = useState([]);
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const IDLE_TIMEOUT = 300000; // 5 minutes in milliseconds
  const MODAL_LOGOUT_TIMEOUT = 45000; // 45 seconds in milliseconds

  /* This code is using the `useIdleTimer` hook from the `react-idle-timer` library to set a timeout for
  when the user is idle for a certain amount of time. The `timeout` property is set to `IDLE_TIMEOUT`,
  which is 5 minutes in milliseconds. When the user is idle for this amount of time, the `onIdle`
  function is called, which sets the `isModalOpen` state to `true`, displaying a modal asking the user
  if they want to stay logged in or be logged out due to inactivity. The `debounce` property is set to
  500 milliseconds, which means that the `onIdle` function will only be called once during a period of
  inactivity, even if there are multiple events triggering the `onIdle` function. The `reset` function
  returned by the `useIdleTimer` hook is used to reset the idle timer when the user interacts with the
  page again. */
  const { reset } = useIdleTimer({
    timeout: IDLE_TIMEOUT,
    onIdle: () => setIsModalOpen(true),
    debounce: 500
  });
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const columns = useMemo(() => COLUMNS_HOTEL_DETAILS, []);
  const data = useMemo(() => [...response], [response]);
  let userId = decrypt(localStorage.getItem(Constants.localStorageKeys.USERID));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const relativeUrl = `${APIURL.RENDER_HOTEL_DETAILS_URL}?userId=${userId}&hotelType=OYO-VACATION-HOME`;
        const response = await RESTApi.GET(APIURL.SMARTLOCK_BASE_URL, relativeUrl, AuthenticatedSmartlockHeaders());

        if (response.status === 200) {
          setLoader(false);
          setResponse(response?.data?.hotels);
        }
      } catch (error) {
        setLoader(false);
        setError(true);
      }
    };

    fetchData();
  }, []);

  /* This is a useEffect hook that sets a timeout to log out the user if they are idle for a certain
  amount of time. It listens for changes in the `isModalOpen` and `reset` variables. If `isModalOpen`
  is true, it sets a timeout using `setTimeout` to call the `handleLogout` function after
  `MODAL_LOGOUT_TIMEOUT` milliseconds. The `handleLogout` function sets `isModalOpen` to false, resets the
  idle timer using `reset`, logs out the user using `logoutUser`, and clears the local storage using
  `clearLocalStorage`. The `return` statement in the useEffect hook clears the timeout using
  `clearTimeout` when the component unmounts or when `isModalOpen` or `reset` changes. */
  useEffect(() => {
    let timeoutId;

    const handleLogout = () => {
      setIsModalOpen(false);
      reset();
      logoutUser();
      clearLocalStorage();
    };

    if (isModalOpen) {
      timeoutId = setTimeout(handleLogout, MODAL_LOGOUT_TIMEOUT);
    }

    return () => clearTimeout(timeoutId);
  }, [isModalOpen, reset]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    page,
    pageOptions,
    state,
    setGlobalFilter,
    prepareRow
  } = useTable({ columns, data }, useGlobalFilter, usePagination);

  const { pageIndex, globalFilter } = state;

  /**
   * The function handles the closing of a modal and resets its state.
   */
  function handleModalClose() {
    setIsModalOpen(false);
    reset();
  }

  /**
   * The function returns a modal component with a message asking the user if they want to stay logged
   * in or be logged out due to inactivity.
   * @returns A React component that renders a modal with a message asking the user if they want to
   * stay logged in or be logged out due to inactivity. The modal has a button to stay logged in and a
   * function to handle the modal close.
   */
  const LoadModal = () => {
    return (
      <>
        <Modal isOpen={isModalOpen} style={customStyles}>
          <div className="text-center">
            <h2>You are about to be logged out due to inactivity.</h2>
            <p>Do you want to stay logged in?</p>
            <button onClick={handleModalClose} type="button" className="focus:outline-none text-white bg-green-500 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2">
              Stay Logged In
            </button>
          </div>
        </Modal>
      </>
    );
  };


  return (
    <>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      <BookingSearch />
      {error && <h2 className="m-5 text-red-500">Failed to fetch data, Please Reload the page</h2>}
      {loader ? (
        <div className="ml-5">
          <Loader />
        </div>
      ) : (
        <div>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="mt-10 ml-5">
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>

            <button
              className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              PREV
            </button>
            <button
              className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              onClick={nextPage}
              disabled={!canNextPage}
            >
              NEXT
            </button>
          </div>
        </div>
      )}
      <LoadModal />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    hotelList: state.ovhHotelDetails.hotels,
    email: state.login.email,
    userId: state.login.userId,
    userProfileId: state.login.userProfileId,
    response: state.ovhHotelDetails.response,
    isLoading: state.ovhHotelDetails.isLoading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHotelList: async (userId) => await dispatch(actions.getHotels(userId)),
    updateLocks: async (payload) => await dispatch(smartlockButtonActions.updateLocks(payload)),
    logoutUser: () => dispatch(loginActions.logoutUser())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HotelDetails);
