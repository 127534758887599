import RESTApi from '../../../../../utils/RESTApi';
import {APIURL} from '../../../../../Constants/urlConstant';
import {AuthenticatedDefaultHeaders} from '../../../../../Constants/HeadersConstants';
import logger from "utils/logger"

const _getHotelList = (payload) => {
    return {
        type: "LOAD_HOTEL_LIST_SUCCESS",
        payload: payload
    }
};
export const getHotels = () => {
    return async function (dispatch) {
        try {
            let response = await RESTApi.GET(APIURL.SWITCH_BASE_URL, APIURL.SWITCH_GET_HOTELS, AuthenticatedDefaultHeaders())
            // logger.log(response);
            if(response.status === 200) {
                await dispatch(_getHotelList(response.data))
            }
        }
        catch(error) {
            logger.log(error);
            alert("Unable to load hotel list. Please try later")
        }
    }
}
