import RESTApi from "../../../../utils/RESTApi";
import {APIURL} from '../../../../Constants/urlConstant';
import {DefaultHeaders} from '../../../../Constants/HeadersConstants';
import * as actionType from './actionTypes';
import logger from 'utils/logger';

const _setIsLoadingTrue = () => {
    return {
        type: actionType.SET_IS_LOADING_TRUE_SONOFF
    }
};
const _setIsLoadingFalse = () => {
    return {
        type: actionType.SET_IS_LOADING_FALSE_SONOFF
    }
};

export const clearResponse = () => {
    return {
        type: actionType.CLEAR_SONOFF_RESPONSE
    }
}
const _setResponse = (success) => {
    return {
        type: actionType.SET_SONOFF_RESPONSE,
        payload: success
    }
}

export const submitMacs = (payload) => {
    return async function(dispatch) {
        logger.log(payload);
        dispatch(clearResponse());
        
        try {
            dispatch(_setIsLoadingTrue());
            let response = await RESTApi.POST(APIURL.SWITCH_BASE_URL, APIURL.SWITCH_SUBMIT_SONOFF_MAC_LIST, payload, DefaultHeaders);
            logger.log(response);
            dispatch(_setIsLoadingFalse());
            if(response.status===200) {
                dispatch(_setResponse(true));
            } else {
                dispatch(_setResponse(false));
            }
        }
        catch(error) {
            logger.log(error);
            dispatch(_setResponse(false));
            dispatch(_setIsLoadingFalse());
        }
    }
}