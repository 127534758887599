import RESTApi from '../../../../utils/RESTApi';
import {APIURL} from '../../../../Constants/urlConstant'
import { AuthenticatedDefaultHeaders } from '../../../../Constants/HeadersConstants';
import {findHotelType} from '../../../../utils/HotelTypeUtil'
import * as actionType from './actionTypes';
import logger from "utils/logger"

const _setIsLoadingTrue = () => {
    return {
        type: actionType.SET_DECOMM_LOADING_TRUE
    }
};
const _setIsLoadingFalse = () => {
    return {
        type: actionType.SET_DECOMM_LOADING_FALSE
    }
};

const _clearRoomList = () => {
    return {
        type : actionType.CLEAR_DECOMM_ROOM_LIST
    }
};

export const setHotelList = (payload, flag=true) => {
    let i;
    if(!flag) { 
        for(i=0;i<payload.length;i++) {
            payload[i].hotel_type = findHotelType(payload[i].hotel_type);
        }
    }
    return {
        type: actionType.LOAD_HOTEL_LIST_DECOMM_TAB,
        payload: payload
    }
}
export const getHotelList = () => {
    return async function(dispatch) {
        try {
            let response = await RESTApi.GET(APIURL.SWITCH_BASE_URL, APIURL.SWITCH_GET_HOTELS, AuthenticatedDefaultHeaders());
            dispatch(setHotelList(response.data, false));
        }
        catch(error) {
            logger.log(error);
        }
    }
}

const _setSelectedHotel = (payload) => {
    return {
        type : actionType.SET_SELECTED_HOTEL_DECOMM,
        payload : payload
    }
}
export const setSelectedHotelRedux = (payload) => {
    return async function (dispatch) {
        dispatch(_setSelectedHotel(payload));
    }
}

const _setSelctedCity = (payload) => {
    return {
        type : actionType.SET_SELECTED_CITY_DECOMM,
        payload : payload
    }
}
export const setSelectedCity = (payload) => {
    return async function (dispatch) {
        dispatch(_setSelctedCity(payload));
    }
}

export const setDecommissionedHotelList = (payload) => {
    return {
        type: actionType.LOAD_DECOMM_HOTEL_LIST,
        payload: payload
    }
}
export const getDecommissionedHotelList = () => {
    return async function(dispatch) {
        try {
            let response = await RESTApi.GET(APIURL.SWITCH_BASE_URL, APIURL.SWITCH_DECOMM_HOTEL_LIST, AuthenticatedDefaultHeaders());
            logger.log(response);
            dispatch(setDecommissionedHotelList(response.data));
        }
        catch(error) {
            logger.log(error);
        }
    }
}

export const getRoomList = (payload) => {
    return({
            type : actionType.LOAD_DECOMM_ROOM_LIST_SUCCESS,
            payload: payload
    })
};
export const getRooms = (hotelId) => {
    return async function (dispatch) {
        try{
            await dispatch(_setIsLoadingTrue());
            await dispatch(_clearRoomList());
            let relativeUrl = APIURL.SWITCH_GET_ROOMS + "?hotel-id=" + hotelId;
            let response = await RESTApi.GET(APIURL.SWITCH_BASE_URL, relativeUrl, AuthenticatedDefaultHeaders())
            if(response.status === 200) {
                // logger.log(response)
                await dispatch (getRoomList(response.data))
                dispatch(_setIsLoadingFalse())
            }
        }
        catch(error){
            alert("Error loading list of rooms")
        }
    }
};

const _clearDecommRecommResponse = () => {
    return {
        type : actionType.CLEAR_DECOMM_RECOMM_RESPONSE
    }
}
export const clearDecommRecommResponse = () => {
    return async function(dispatch) {
        dispatch(_clearDecommRecommResponse());
    }
}

const _setDecommRoomsResponse = (message, type) => {
    if(type==="room") {
        return {
            type : actionType.SET_DECOMM_ROOMS_RESPONSE,
            payload : message
        }
    }
    else {
        let msg;
        if(message.length!==0)
            msg = JSON.stringify(message).slice(1,-1) + "have been decommissioned";
        else
            msg = "Already decommissioned"
        return {
            type : actionType.SET_DECOMM_ROOMS_RESPONSE,
            payload : msg
        }
    }
}
export const decommissionRooms = (payload) => {
    return async function (dispatch) {
        try{
            await dispatch(_setIsLoadingTrue());
            let response = await RESTApi.POST(APIURL.SWITCH_BASE_URL, APIURL.SWITCH_DECOMM_ROOMS, payload, AuthenticatedDefaultHeaders());
            logger.log(response)
            await dispatch(_setIsLoadingFalse());
            if(response.status===200 && response.data!==null && response.data.message!==undefined) {
                dispatch(_setDecommRoomsResponse(response.data.message, "room"))
            }
        } catch(error) {
            logger.log(error);
            await dispatch(_setIsLoadingFalse());
            alert("Unable to decommission rooms at the moment. Please try later");
        }
    }
}

const _setRecommRoomsResponse = (message, type) => {
    if(type==="room") {    
        return {
            type : actionType.SET_RECOMM_ROOMS_RESPONSE,
            payload : message
        }
    }
    else {
        let msg;
        if(message.length!==0)
            msg = JSON.stringify(message).slice(1,-1) + "have been recommissioned";
        else
            msg = "Already recommissioned"
        return {
            type : actionType.SET_RECOMM_ROOMS_RESPONSE,
            payload : msg
        }
    }
}
export const recommissionRooms = (payload) => {
    return async function (dispatch) {
        try {
            await dispatch(_setIsLoadingTrue());
            let response = await RESTApi.POST(APIURL.SWITCH_BASE_URL, APIURL.SWITCH_RECOMM_ROOMS, payload, AuthenticatedDefaultHeaders())
            logger.log(response);
            await dispatch(_setIsLoadingFalse());
            if(response.status===200) {
                dispatch(_setRecommRoomsResponse(response.data.message, "room"))
            }
        } catch(error) {
            logger.log(error);
            await dispatch(_setIsLoadingFalse());
            alert("Unable to recommission rooms at the moment. Please try later");
        }
    }
}

export const decommissionHotels = (payload) => {
    return async function(dispatch) {
        try {
            await dispatch(_setIsLoadingTrue());
            let response = await RESTApi.POST(APIURL.SWITCH_BASE_URL, APIURL.SWITCH_DECOMM_GLOBAL, payload, AuthenticatedDefaultHeaders());
            logger.log(response);
            await dispatch(_setIsLoadingFalse());
            if(response.status===200) {
                dispatch(_setDecommRoomsResponse(response.data, "hotel"));
            }
        }
        catch(error) {
            logger.log(error);
            await dispatch(_setIsLoadingFalse());
            alert("Unable to decommission hotels at the moment. Please try later");
        }
    }
}

export const recommissionHotels = (payload) => {
    return async function(dispatch) {
        try {
            await dispatch(_setIsLoadingTrue());
            let response = await RESTApi.POST(APIURL.SWITCH_BASE_URL, APIURL.SWITCH_RECOMM_GLOBAL, payload, AuthenticatedDefaultHeaders());
            logger.log(response);
            await dispatch(_setIsLoadingFalse());
            if(response.status===200) {
                dispatch(_setRecommRoomsResponse(response.data, "hotel"));
            }
        }
        catch(error) {
            logger.log(error);
            await dispatch(_setIsLoadingFalse());
            alert("Unable to recommission hotels at the moment. Please try later");
        }
    }
}