import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';

class ViewCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      staffName: '',
      bookingId: ''
    };
  }

  componentDidMount() {
    this.props.clearResponse();
  }

  handleSubmitGuest = () => {
    const { bookingId } = this.state;

    if (bookingId !== '' || bookingId !== undefined || bookingId !== null) {
      let payload = {
        property_id: this.props.propId,
        room_no: this.props.room,
        params_type: 'guest',
        booking_id: bookingId
      };
      this.props.viewCode(payload);
    } else {
      alert('Please enter a valid booking ID');
    }
  };

  handleSubmitStaff = () => {
    const { staffName } = this.state;

    if (staffName !== '' || staffName !== undefined || staffName !== null) {
      let payload = {
        property_id: this.props.propId,
        room_no: this.props.room,
        params_type: 'staff',
        user_id: staffName
      };
      this.props.viewCode(payload);
    } else {
      alert('Enter a valid staff name to view the code');
    }
  };

  render() {
    return (
      <div className="activityLayout">
        <div className="activity-heading">
          <div className="activity-label">
            {this.props.heading}
            <button className="close-button" onClick={this.props.close}>
              <i className="fa fa-close" />
            </button>
          </div>
        </div>
        {this.props.type === 'guest' ? (
          <>
            <div>
              <label>Booking ID: </label>
              <input
                type="text"
                onChange={(e) => {
                  this.setState({ bookingId: e.target.value });
                }}
                required
              />
            </div>
            <button type="submit" className="submit-button" onClick={this.handleSubmitGuest}>
              Submit
            </button>
            {this.props.isLoading ? <div className="loader"></div> : <div></div>}
            {this.props.response.success !== undefined ? (
              <div>
                {this.props.response.success ? (
                  <>
                    <p style={{ color: 'green' }}>{this.props.response.message}</p>
                    {this.props.response.code !== '' ? (
                      <p>Code: {this.props.response.code}</p>
                    ) : (
                      <p>No Code available for the above booking ID</p>
                    )}
                  </>
                ) : (
                  <p style={{ color: 'red' }}>{this.props.response.message}</p>
                )}
              </div>
            ) : (
              <div></div>
            )}
          </>
        ) : (
          <>
            <div>
              <label>Staff Name: </label>
              <input
                type="text"
                onChange={(e) => {
                  this.setState({ staffName: e.target.value });
                }}
                required
              />
            </div>
            <button type="submit" className="submit-button" onClick={this.handleSubmitStaff}>
              Submit
            </button>
            {this.props.isLoading ? <div className="loader"></div> : <div></div>}
            {this.props.response.success !== undefined ? (
              <div>
                {this.props.response.success ? (
                  <>
                    <p style={{ color: 'green' }}>{this.props.response.message}</p>
                    {this.props.response.code !== '' ? (
                      <p>Code: {this.props.response.code}</p>
                    ) : (
                      <p>No Code available for the above booking ID</p>
                    )}
                  </>
                ) : (
                  <p style={{ color: 'red' }}>{this.props.response.message}</p>
                )}
              </div>
            ) : (
              <div></div>
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.mortise.response,
    isLoading: state.mortise.isLoading
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    clearResponse: async () => dispatch(actions.clearResponse()),
    viewCode: async (payload) => dispatch(actions.viewCode(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewCode);
