import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import * as actions from "../../Login/redux/actions";
import TopBar from "../../../CommonUI/TopBar/topBar";
import MenuBar from "../_utilComponents/MenuBar/menubar";
import urlConstant from "../../../Constants/urlConstant";

import ErrorPage from "../../ErrorPage/error-page";
import DecommissionByRoom from "../Decommission/ByRoom/decommByRoom";
import DecommissionByHotel from "../Decommission/ByHotel/decommByHotel";
import DecommissionByCity from "../Decommission/ByCity/decommByCity";
import DecommissionGlobal from "../Decommission/DecommGlobal/decommGlobal";
import DecommissionHistory from "../Decommission/DecommHistory/decommHistory";
import HotelDetails from "../HotelDetails/hotelDetails";
import BleAppPassword from "../BleAppPassword/bleAppPassword";
import HotelInstallationStatus from "../HotelInstallationStatus/hotelInstallationStatus";
import InstallationStatus from "../InstallationStatus/installationStatus";
import SupportVideos from "../SupportVideos/supportVideos";
import SonoffMac from "../SonoffMac/sonoffMac";
import PostCommHealth from "../PostCommHealth/postCommHealth";

class SwitchHomePage extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <div>
            <TopBar />
          </div>
          <div>
            <nav>
              <MenuBar />
            </nav>
            <article>{/* Some content */}</article>
          </div>
          {/* All Routes for Switch Product */}
          <React.Fragment>
            <Route path={urlConstant.routeURL.URL_NOT_FOUND} component={ErrorPage} />
            <Route
              path={urlConstant.routeURL.URL_SWITCH_DECOMMISSION_ROOM}
              component={DecommissionByRoom}
            />
            <Route
              path={urlConstant.routeURL.URL_SWITCH_DECOMMISSION_HOTEL}
              component={DecommissionByHotel}
            />
            <Route
              path={urlConstant.routeURL.URL_SWITCH_DECOMMISSION_CITY}
              component={DecommissionByCity}
            />
            <Route
              path={urlConstant.routeURL.URL_SWITCH_DECOMMISSION_GLOBAL}
              component={DecommissionGlobal}
            />
            <Route
              path={urlConstant.routeURL.URL_SWITCH_DECOMMISSION_HISTORY}
              component={DecommissionHistory}
            />
            <Route path={urlConstant.routeURL.URL_SWITCH_HOTEL_DETAILS} component={HotelDetails} />
            <Route path={urlConstant.routeURL.URL_SWITCH_GET_PASSWORD} component={BleAppPassword} />
            <Route
              path={urlConstant.routeURL.URL_SWITCH_PRE_INSTALLATION}
              component={HotelInstallationStatus}
            />
            <Route
              path={urlConstant.routeURL.URL_SWITCH_SUPPORT_VIDEOS}
              component={SupportVideos}
            />
            <Route path={urlConstant.routeURL.URL_SWITCH_SONOFF_MAC} component={SonoffMac} />
            <Route
              path={urlConstant.routeURL.URL_SWITCH_OFFLINE_DEVICES}
              component={InstallationStatus}
            />
            <Route
              path={urlConstant.routeURL.URL_SWITCH_POST_INSTALLATION}
              component={PostCommHealth}
            />
          </React.Fragment>
        </div>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(actions.logoutUser()),
  };
};
export default connect(mapDispatchToProps)(SwitchHomePage);
