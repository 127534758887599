import {sonoffDetails as initialState} from "./state";
import * as actionType from "./actionTypes";

const reducer = (state=initialState, action) => {
    switch(action.type) {
        case actionType.SET_SONOFF_RESPONSE :
            return {
                ...state,
                response : action.payload
            }
        case actionType.CLEAR_SONOFF_RESPONSE :
            return {
                ...state,
                response : ""
            }
        case actionType.SET_IS_LOADING_TRUE_SONOFF : 
            return {
                ...state,
                isLoading : true
            }
        case actionType.SET_IS_LOADING_FALSE_SONOFF : 
            return {
                ...state,
                isLoading : false
            }
        default :
            return {
                ...state
            }
    }
}

export default reducer;