import React, {Component} from 'react';
import {connect} from 'react-redux';
import CheckBox from '../../../_utilComponents/Checkboxes/Checkboxes';
import DateParsingUtil from "../../../../../utils/DateParsingUtil";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Popup from 'reactjs-popup';
import * as actions from '../../redux/actions';
import './table.css';
import logger from "utils/logger"

let arrowMap = new Map(
    [["device_id", ''],
        ["last_hb_at", ''],
        ["is_online", ''],
        ["room_no", ''],
        ["is_checked_in", ''],
        ["relay_state", ''],
        ["is_enabled", ''],
        ['desired_relay_state','']]
);

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedItems: new Map(),
            checkedAll: false,
            reason: "",
            length : ""
        }
        this.handleHeadChange = this.handleHeadChange.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
    }
    componentDidMount(){
        let tableData = this.props.roomData.map(tableRow => {
            if(tableRow.is_enabled===this.props.isCommissioned) {
                return tableRow;
            }
            else {
                return null;
            }
        });
        let finalRoomList = tableData.filter(tableRow => {
            if(tableRow !== null)
                return tableRow;
            return null;
        })
        this.setState({
            length : finalRoomList.length
        })
    }

    sortBy = async (key) => {
        let i;
        let copyArray = [];
        let len = this.props.roomData.length;
        for(i=0;i<len;i++) {
            copyArray[i] = this.props.roomData[i];
        }
        let payload = {
          room_details : [],
          device_type : this.props.deviceType,
          hotel_id : this.props.hotelId
        }
        let arrayKey = Array.from(arrowMap.keys());
        if (key === arrayKey[arrayKey.indexOf(key)]) {
            if (arrowMap.get(key) === '') {
                arrowMap.set(key, true);
                copyArray.sort(this.ascendingOrder(key));
                payload.room_details = copyArray;
                await this.props.setRoomList(payload);
            } else if (arrowMap.get(key) === true) {
                arrowMap.set(key, false);
                copyArray.sort(this.descendingOrder(key));
                payload.room_details = copyArray;
                await this.props.setRoomList(payload);
            } else if (arrowMap.get(key) === false) {
                arrowMap.set(key, true);
                copyArray.sort(this.ascendingOrder(key));
                payload.room_details = copyArray;
                await this.props.setRoomList(payload);
            }
        }
    };

    ascendingOrder = key => {
        if (key === "room_no") {
            return function (a, b) {
                if (Number(a[key]) < Number(b[key])) {
                    return -1;
                }
                if (Number(a[key]) > Number(b[key])) {
                    return 1;
                }
                return 0;
            };
        } else {
            return function (a, b) {
                if (a[key] < b[key]) {
                    return -1;
                }
                if (a[key] > b[key]) {
                    return 1;
                }
                return 0;
            };
        }
    };

    descendingOrder = key => {
        if (key === "room_no") {
            return function (a, b) {
                if (Number(a[key]) > Number(b[key])) {
                    return -1;
                }
                if (Number(a[key]) < Number(b[key])) {
                    return 1;
                }
                return 0;
            };
        } else {
            return function (a, b) {
                if (a[key] > b[key]) {
                    return -1;
                }
                if (a[key] < b[key]) {
                    return 1;
                }
                return 0;
            };
        }
    };

    setCheckedAll = () => {
        let keyArray = this.state.checkedItems.values();
        let ele;
        let count = 0;
        let flag=0;
        for(ele of keyArray) {
            if(ele === false) {
                flag = 1;
                break;
            }
            else {
                count++;
            }
        }
        if(flag===1 && this.state.checkedAll===true) {
            this.setState({
                checkedAll: false
            })
        } 
        else if(flag===0 && this.state.checkedAll===false && count===this.state.length) {
            this.setState({
                checkedAll: true
            })
        }
    }

    handleHeadChange = () => {
        let val;
        let i;
        let newCheckedItems = new Map();
        if(!this.state.checkedAll) {
            let len = this.props.roomData.length;
            for(i=0;i<len;i++) {
                if(this.props.roomData[i].is_enabled === this.props.isCommissioned) {
                    newCheckedItems.set(this.props.roomData[i].room_no, true)
                }
            }
            val = true;
        } else {
            val = false;
        }
        this.setState ({
            checkedItems: newCheckedItems,
            checkedAll: val
        })
    }

    handleRowClick = async (roomNo) => {
        const item = roomNo;
        let isChecked = this.state.checkedItems.get(roomNo);
        if(isChecked === undefined||isChecked === false) {
            isChecked = true;
        }
        else if(isChecked===true) {
            isChecked = false;
            if(this.state.checkedAll===true) {
                await this.setState({
                    checkedAll:false
                })
            }
        }
        if (item !== undefined && item !== null) {
            await this.setState(prevState => 
                ({checkedItems: prevState.checkedItems.set(item, isChecked)}));
        }
        this.setCheckedAll();
    }

    setReason = (e) => {
        logger.log(e.target.value);
        if(e.target.value) {
            this.setState({
                reason: e.target.value
            })
        }
    }

    handleSubmit = (arr, close) => {
        close();
        this.props.onSubmit(arr, this.state.checkedAll, this.state.reason, "room")
    }

    arrow = keyValue => (
        arrowMap.get(keyValue)
            ? <FontAwesomeIcon icon="sort-alpha-down" style={{color: 'grey'}} className="arrow-sort"/>
            : <FontAwesomeIcon icon="sort-alpha-up" style={{color: 'grey'}} className="arrow-sort"/>
    );

    render() {
    let tableData = this.props.roomData.map(tableRow => {
        if(tableRow.is_enabled===this.props.isCommissioned) {
            return tableRow;
        }
        else {
            return null;
        }
    });
    let finalRoomList = tableData.filter(tableRow => {
        if(tableRow !== null)
            return tableRow;
        return null;
    })
    let buttonText = this.props.isCommissioned ? "Decommission Rooms" : "Recommission Rooms";
    let arr=[];
    if(this.state.checkedItems.length!==0) {
        this.state.checkedItems.forEach((value, key) => {
            if(value === true) {
                arr.push(key);
            }
        })
    }
    logger.log(arr);
    if(this.props.roomData.length!==0) {
        if(finalRoomList.length!==0) {
        return (
            <div className="tableAlign">
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
              <div className="decomm-button-container"> 
                <Popup trigger = {
                    <button className="refresh-button">
                        {buttonText}
                    </button> } modal>
                    {close => (
                        <div className="activityLayout">
                            <div className="activity-heading">
                                <div className="activity-label">{buttonText}
                                    <button className="close-button" onClick={close}>
                                        <i className="fa fa-close"/>
                                    </button>
                                </div>
                            </div>
                            <div>
                                {arr.length===0 && this.state.checkedAll===false ?
                                    <div className="selected-error">Please select rooms to {buttonText}</div>
                                    :
                                    <React.Fragment>
                                    <div className="selected">{this.state.checkedAll ? "All rooms have been selected" : "Selected rooms are:  " + JSON.stringify(arr).slice(1,-1)}</div>
                                    <div>If you want to {buttonText}, enter the reason and click Confirm</div>
                                    <br></br>
                                    <label>Enter Reason: </label><input className="text-box" type="text" name="reason" onChange={this.setReason} placeholder="Enter reason here" required></input>
                                    <br></br>
                                    <button type="submit" className="submit-button-decomm" onClick={() => {this.handleSubmit(arr,close)}}>
                                        Confirm
                                    </button>
                                    <button className="cancel-button-decomm" onClick={close}>
                                        Cancel
                                    </button>
                                    </React.Fragment>
                                }
                                {this.props.isLoading ? <div className="bulk-loader"></div> : <div></div>}
                            </div>
                        </div>
                    )}
                </Popup>
              </div>
              <table className="hotel-Admin-layout">
                    <thead className="tableHead">
                    <tr>
                        {<td className="table-heading"><CheckBox name="All" onChange={this.handleHeadChange} checked={this.state.checkedAll}/></td>}
                        {<td className="table-heading" onClick={() => this.sortBy('room_no')}>Room
                            No {this.arrow('room_no')}</td>}
                        {<td className="table-heading" onClick={() => this.sortBy('device_id')}>Device
                            Id {this.arrow('device_id')}</td>}
                        {<td className="table-heading"
                                onClick={() => this.sortBy('is_connected')}>Online {this.arrow('is_online')}</td>}
                        {<td className="table-heading" onClick={() => this.sortBy('desired_relay_state')}>Desired
                            Relay State{this.arrow('desired_relay_state')}</td>}
                        {<td className="table-heading" onClick={() => this.sortBy('is_checked_in')}>Room
                            Status {this.arrow('is_checked_in')}</td>}
                        {<td className="table-heading" onClick={() => this.sortBy('relay_state')}>Light
                            Status{this.arrow('relay_state')}</td>}
                        {<td className="table-heading" onClick={() => this.sortBy('relay_state')}>keycard
                            Status{this.arrow('relay_state')}</td>}
                        {<td className="table-heading" onClick={() => this.sortBy('last_hb_at')}>Last Heart Beat
                            Time{this.arrow('last_hb_at')}</td>}
                    </tr>
                    </thead>
                    <tbody className="body-layout">
                        {finalRoomList.map(tableRow => {
                            return(
                                <tr className="trColumn" onClick={()=>{this.handleRowClick(tableRow.room_no)}}>
                                    <td className="tableColumn">
                                        <CheckBox name={tableRow.room_no} checked={this.state.checkedItems.get(tableRow.room_no)}/></td>
                                    <td className="tableColumn">{tableRow.room_no}</td>
                                    <td className="tableColumn">{tableRow.device_id}</td>
                                    <td className="tableColumn">{tableRow.is_connected ?
                                                <p style={{color: 'green'}}>Yes</p> :
                                                <p style={{color: 'red'}}>No</p>}</td>
                                    {<td className="tableColumn">{tableRow.desired_relay_state}</td>}
                                    {<td className="tableColumn">{tableRow.is_checked_in ?
                                                <p style={{color: 'green'}}>Checked-in</p> :
                                                <p style={{color: 'red'}}>Checked-out</p>}</td>}
                                    {<td className="tableColumn">{tableRow.relay_state === '0' ?
                                                <p style={{color: 'red'}}>Off</p> :
                                                <p style={{color: "green"}}>On</p>}</td>}
                                    {<td className="tableColumn">{tableRow.keycard_state === '0' ?
                                                <p style={{color: 'red'}}>Removed</p> :
                                                <p style={{color: "green"}}>Inserted</p>}</td>}
                                    {<td className="tableColumn" style={{fontSize: "15px"}}>
                                                {DateParsingUtil.changeEpochToStandard(tableRow.last_hb_at).toString().slice(0, -34)}
                                    </td>}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
        } else {
            if(this.props.isCommissioned === true) {
                return (
                    <div className="table-option-heading">
                        {/* <h2 className="hotel-name">{this.props.selectedHotel.hotelName}|{this.props.selectedHotel.hotelId}</h2> */}
                        <div className="decommission-error">This Hotel has no commissioned rooms to decommission</div>
                    </div>
                )
            }
            else {
                return (
                    <div className="table-option-heading">
                        {/* <h2 className="hotel-name">{this.props.selectedHotel.hotelName}|{this.props.selectedHotel.hotelId}</h2> */}
                        <div className="decommission-error">This Hotel has no decommissioned rooms to commission</div>
                    </div>
                )
            }
        }
    } else {
        return(
        <div></div>
        )
    }
    }
}
const mapStateToProps = state => {
    return {
        roomData : state.decomm.hotelData.roomList,
        isLoading : state.decomm.isLoading,
        selectedHotel : state.decomm.selectedHotel
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setRoomList : async (payload) => dispatch(actions.getRoomList(payload))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Table);