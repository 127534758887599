import React from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import * as actions from './redux/actions';
import * as smartlockButtonActions from './../../../CommonUI/SmartlockButtons/redux/actions';

import DeleteCode from './../../../CommonUI/SmartlockButtons/deleteCode';
import GenNewCode from './../../../CommonUI/SmartlockButtons/genNewCode';
import GenStaffCode from './../../../CommonUI/SmartlockButtons/genStaffCode';
import ViewCode from './../../../CommonUI/SmartlockButtons/viewCode';
import BookingIdSearch from './../../../CommonUI/SmartlockButtons/bookingIdSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ukHotelDetails.css';
import DateParsingUtil from '../../../utils/DateParsingUtil';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Constants } from '../../../Constants/Constants';
import { decrypt } from '../../../utils/EncryptDecryptUtil';

class HotelDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filteredItems: [], bookingId: '' };
  }

  componentDidMount = async () => {
    let userId = decrypt(localStorage.getItem(Constants.localStorageKeys.USERID));
    await this.props.getHotelList(userId);
    if (this.props.hotelList && this.props.hotelList.length !== 0) {
      this.setState({ filteredItems: this.props.hotelList });
    }
  };

  handleSyncLocks = () => {
    let body = {};

    toast.success('Sync has started, you will receive a notification once it has completed');

    setTimeout(() => {
      this.props.updateLocks(body);
    }, 1000);
  };

  filterItems = (text) => {
    if (text !== '') {
      let tempFilteredItems = this.props.hotelList.filter(
        (item) =>
          (item.property_label && item.property_label.toLowerCase().includes(text)) ||
          (item.property_id && item.property_id.toLowerCase().includes(text)) ||
          (item.room_no && item.room_no.toLowerCase().includes(text)) ||
          (item.device_id && item.device_id.toLowerCase().includes(text)) ||
          (item.property_type && item.property_type.toLowerCase().includes(text))
      );
      this.setState({ filteredItems: tempFilteredItems });
    } else {
      this.setState({ filteredItems: this.props.hotelList });
    }
  };

  render() {
    const { filteredItems, bookingId } = this.state;

    return (
      <div className="tableAlign">
        <div className="hotel-Admin-layout-container-layout">
          <div className="hotels-input-container">
            <input
              placeholder="Enter Property name , Property code , City or Country"
              type="text"
              className="input-bar"
              onChange={(e) => {
                this.filterItems(e.target.value.toLowerCase());
              }}
            />

            <input
              placeholder="Enter Booking Id"
              type="text"
              className="booking-input-bar"
              onChange={(e) => {
                this.setState({ bookingId: e.target.value });
              }}
            />

            <Popup trigger={<button className="search-button">Search</button>} modal>
              {(close) => <BookingIdSearch close={close} bookingId={bookingId} />}
            </Popup>

            <button
              className="search-button"
              onClick={() => {
                if (window.confirm('Are you Sure?')) {
                  this.handleSyncLocks();
                }
              }}
            >
              Sync Locks
              <FontAwesomeIcon icon="sync" className="sync-icon" />
            </button>
            <ToastContainer />

            {this.props.isLoading ? (
              <div class="sk-circle">
                <div className="sk-circle1 sk-child"></div>
                <div className="sk-circle2 sk-child"></div>
                <div className="sk-circle3 sk-child"></div>
                <div className="sk-circle4 sk-child"></div>
                <div className="sk-circle5 sk-child"></div>
                <div className="sk-circle6 sk-child"></div>
                <div className="sk-circle7 sk-child"></div>
                <div className="sk-circle8 sk-child"></div>
                <div className="sk-circle9 sk-child"></div>
                <div className="sk-circle10 sk-child"></div>
                <div className="sk-circle11 sk-child"></div>
                <div className="sk-circle12 sk-child"></div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="hotel-Admin-layout-container">
            {filteredItems.length !== 0 ||
            (this.props.hotelList && this.props.hotelList.length !== 0) ? (
              <>
                <table className="hotel-Admin-layout">
                  <thead className="tableHead">
                    <tr>
                      <td className="table-heading">Property name</td>
                      <td className="table-heading">Property Code</td>
                      <td className="table-heading">Room Number</td>
                      <td className="table-heading">Lock ID</td>
                      <td className="table-heading">Booking ID</td>
                      <td className="table-heading">Last Sync Time</td>
                      <td className="table-heading">Generate New Code</td>
                      <td className="table-heading">View code</td>
                      <td className="table-heading">Generate Staff Code</td>
                      <td className="table-heading">View Staff Code</td>
                      <td className="table-heading">Delete code</td>
                    </tr>
                  </thead>
                  <tbody className="body-layout">
                    {filteredItems.map((room) => {
                      return (
                        <tr className="trColumn" key={room.property_id + '-' + room.room_no}>
                          <td className="tableColumn">{room.property_label}</td>
                          <td className="tableColumn">{room.property_id}</td>
                          <td className="tableColumn">{room.room_no}</td>
                          <td className="tableColumn">{room.device_id}</td>
                          <td className="tableColumn">{room.booking_id}</td>
                          <td className="tableColumn">
                            {room.updated_at !== undefined
                              ? DateParsingUtil.changeEpochToStandard(room.updated_at)
                                  .toString()
                                  .slice(0, -34)
                              : 'NOT AVAILABLE'}
                          </td>
                          <td className="tableColumn">
                            <Popup
                              trigger={<button className="submit-button">Generate New Code</button>}
                              modal
                            >
                              {(close) => (
                                <GenNewCode
                                  close={close}
                                  propId={room.property_id}
                                  roomNo={room.room_no}
                                  userId={this.props.email}
                                  timeZoneOffset={room.time_zone_offset}
                                />
                              )}
                            </Popup>
                          </td>
                          <td className="tableColumn">
                            <Popup
                              trigger={<button className="submit-button">View Code</button>}
                              modal
                            >
                              {(close) => (
                                <ViewCode
                                  close={close}
                                  propId={room.property_id}
                                  roomNo={room.room_no}
                                  userId={this.props.email}
                                  codeType="guest"
                                />
                              )}
                            </Popup>
                          </td>
                          <td className="tableColumn">
                            <Popup
                              trigger={
                                <button className="staff-code-button">
                                  Generate 1 Month Staff Code
                                </button>
                              }
                              modal
                            >
                              {(close) => (
                                <GenStaffCode
                                  close={close}
                                  propertyId={room.property_id}
                                  roomNo={room.room_no}
                                  userId={this.props.email}
                                  months={1}
                                />
                              )}
                            </Popup>
                          </td>
                          <td className="tableColumn">
                            <Popup
                              trigger={
                                <button className="staff-code-button">View Staff Code</button>
                              }
                              modal
                            >
                              {(close) => (
                                <ViewCode
                                  close={close}
                                  propId={room.property_id}
                                  roomNo={room.room_no}
                                  userId={this.props.email}
                                  codeType="staff"
                                />
                              )}
                            </Popup>
                          </td>
                          <td className="tableColumn">
                            <Popup
                              trigger={<button className="submit-button">Delete Code</button>}
                              modal
                            >
                              {(close) => (
                                <DeleteCode
                                  close={close}
                                  propId={room.property_id}
                                  roomNo={room.room_no}
                                  userId={this.props.email}
                                />
                              )}
                            </Popup>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : this.props.isLoading ? (
              <div></div>
            ) : (
              <div>No hotel data available</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hotelList: state.ovhHotelDetails.hotels,
    email: state.login.email,
    userId: state.login.userId,
    response: state.ovhHotelDetails.response,
    isLoading: state.ovhHotelDetails.isLoading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHotelList: async (userId) => await dispatch(actions.getHotels(userId)),
    updateLocks: async (payload) => await dispatch(smartlockButtonActions.updateLocks(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HotelDetails);
