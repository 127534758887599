export const installationDetails = {
    isLoading: false,
    selectedHotel : {
        hotelName : "",
        hotelId : "",
        isCommissioned : "",
        type : ""
    },
    hotelData : {
        roomList: [],
        deviceType: "",
        hotelId : ""
    },
}