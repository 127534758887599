import {hotelDetails as initialState} from './state';
import * as actionTypes from './actionTypes';

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_IS_LOADING_TRUE:
            return {
                ...state,
                isLoading : true
            }
        case actionTypes.SET_IS_LOADING_FALSE:
            return {
                ...state,
                isLoading : false
            }
        case actionTypes.CLEAR_ROOM_LIST:
            return {
                ...state,
                hotelData : {
                    roomList: [],
                    deviceType: "",
                    hotelId : ""
                }
            }
        case actionTypes.CLEAR_HOTEL_AND_ROOMS:
            return {
                ...state,
                selectedHotel : {},
                hotelData: {
                    roomList: [],
                    deviceType: "",
                    hotelId : ""
                }
            }
        case actionTypes.SET_SELECTED_HOTEL:
            return {
                ...state,
                selectedHotel: action.payload
            }
        case actionTypes.LOAD_HOTELS_LIST_SUCCESS:
            return {
                ...state,
                onLoadHotelData : action.payload,
            }
        case actionTypes.LOAD_ROOM_LIST_SUCCESS:
            return {
                ...state,
                hotelData: {
                    roomList: action.payload.room_details,
                    deviceType : action.payload.device_type,
                    hotelId : action.payload.hotel_id,
                }
            }
        case actionTypes.LOAD_ACTIVITY_REASONS:
            return {
                ...state,
                activityReasons: {
                    reasons: action.payload
                }
            }
        case actionTypes.SET_ACTIVITY_RESPONSE_DATA:
            return {
                ...state,
                v2ActivityResponse: action.payload
            }
        case actionTypes.CLEAR_V2_RESPONSE:
            return {
                ...state,
                v2ActivityResponse : {}
            }
        case actionTypes.SET_STAFF_ENTRY_RESPONSE:
            return {
                ...state,
                v1ActivityResponse: {
                    staffEntry : action.payload
                }
            }
        case actionTypes.SET_BULK_CHECKIN_DATA:
            return {
                ...state,
                bulkDurationsAndReasons : action.payload
            }
        case actionTypes.BULK_CHECK_IN_RESPONSE:
            return {
                ...state,
                bulkCheckInResponse : action.payload
            }
        case actionTypes.CLEAR_BULK_RESPONSE:
            return {
                ...state,
                bulkCheckInResponse : {}
            }
        case actionTypes.SET_OFFLINE_CODES_RESPONSE:
            return {
                ...state,
                v1ActivityResponse: {
                    offlineCodes : action.payload
                }
            }
        case actionTypes.V1_CHECK_IN:
            return {
                ...state,
                v1ActivityResponse: {
                    checkIn : action.payload
                }
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer;