export const LOAD_HOTEL_LIST_DECOMM_TAB = "LOAD_HOTEL_LIST_DECOMM_TAB";
export const LOAD_DECOMM_HOTEL_LIST = "LOAD_DECOMM_HOTEL_LIST";
export const SET_DECOMM_LOADING_TRUE = "SET_DECOMM_LOADING_TRUE";
export const SET_DECOMM_LOADING_FALSE = "SET_DECOMM_LOADING_FALSE";
export const CLEAR_DECOMM_ROOM_LIST = "CLEAR_DECOMM_ROOM_LIST";
export const SET_SELECTED_HOTEL_DECOMM = "SET_SELECTED_HOTEL_DECOMM";
export const SET_SELECTED_CITY_DECOMM = "SET_SELECTED_CITY_DECOMM";
export const LOAD_DECOMM_ROOM_LIST_SUCCESS = "LOAD_DECOMM_ROOM_LIST_SUCCESS";
export const SET_DECOMM_ROOMS_RESPONSE = "SET_DECOMM_ROOMS_RESPONSE";
export const SET_RECOMM_ROOMS_RESPONSE = "SET_RECOMM_ROOMS_RESPONSE";
export const CLEAR_DECOMM_RECOMM_RESPONSE = "CLEAR_DECOMM_RECOMM_RESPONSE";