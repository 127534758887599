import React, {Component} from 'react';
import './postCommHealth.css';
import logger from 'utils/logger';
import RESTApi from 'utils/RESTApi';
import {APIURL} from 'Constants/urlConstant';
import { AuthenticatedDefaultHeaders} from 'Constants/HeadersConstants';


class PostCommHealth extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            sobUrl : "",
            smartUrl : "",
            homesUrl : "",
        }
    }

    getMetabaseIframeToken = async (id,urlKey) => {
        var METABASE_SITE_URL = "https://insights.oyorooms.com";
        let relativeURL = APIURL.SWITCH_GET_METABASE_IFRAME_TOKEN + "?id=" + id;
        try{
            let response = await RESTApi.GET(APIURL.SWITCH_BASE_URL,relativeURL, AuthenticatedDefaultHeaders());
            if(response.status===200) {
                this.setState({
                    [urlKey]:METABASE_SITE_URL + "/embed/dashboard/" + response.data + "#bordered=true&titled=true"
                })
            }
        }
        catch(error){
            logger.log(error)
            alert("Unable to retrieve password at the moment. Please try later");
        }
    }

    componentDidMount(){
        this.getMetabaseIframeToken(2213,"sobUrl");
        this.getMetabaseIframeToken(2207,"smartUrl");
        this.getMetabaseIframeToken(2214,"homesUrl");
    }

    render(){
        return (
            <div className="iframe-container">
                <div className="tabs">
                    <input type="radio" name="tabs" id="tab1" />
                    <label htmlFor="tab1">SOB</label>

                    <input type="radio" name="tabs" id="tab2" />
                    <label htmlFor="tab2">Smart</label>

                    <input type="radio" name="tabs" id="tab3" />
                    <label htmlFor="tab3">Homes</label>

                    <div class="tab content1">
                        <iframe title="sob" className="iframe" src={this.state.sobUrl}></iframe>  
                    </div>

                    <div className="tab content2">
                        <iframe title="smart" className="iframe" src={this.state.smartUrl}></iframe>
                    </div>

                    <div className="tab content3">
                        <iframe title="homes" className="iframe" src={this.state.homesUrl}></iframe>
                    </div>
                </div>
            </div>
        )
    }
}
export default PostCommHealth;