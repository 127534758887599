import * as aes from 'aes-js';

const key_128 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
const counter = 3;

export function encrypt(plaintext) {
    if(plaintext) {
        let textBytes = aes.utils.utf8.toBytes(plaintext);

        let aesCtr = new aes.ModeOfOperation.ctr(key_128, new aes.Counter(counter));
        let encryptedBytes = aesCtr.encrypt(textBytes);

        var encryptedHex = aes.utils.hex.fromBytes(encryptedBytes);
        return(encryptedHex);
    }
    else {
        return null;
    }
}

export function decrypt(encryptedHex) {
    if(encryptedHex) {
        let encryptedBytes = aes.utils.hex.toBytes(encryptedHex);

        var aesCtr = new aes.ModeOfOperation.ctr(key_128, new aes.Counter(counter));
        var decryptedBytes = aesCtr.decrypt(encryptedBytes);

        var decryptedText = aes.utils.utf8.fromBytes(decryptedBytes);
        return(decryptedText);
    }
    else {
        return null;
    }
}