import React, { Component } from 'react';
import { connect } from 'react-redux';
import SearchBar from '../../_utilComponents/SearchBar/searchBar';
import Recomm from '../recomm';
import Decomm from '../decomm';
import * as actions from '../redux/actions';
import './decommByHotel.css';
import logger from "utils/logger"

class DecommByHotel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCity : "",
            options : ""
        }
    }

    async componentDidMount(){
        logger.log("in component did mount")
        await this.props.clearDecommRecommResponse();
        await this.props.getHotelList();
        let options;
        if(this.props.hotelList) {
            let oyoIdList = this.props.hotelList.map(hotel => {
                return hotel.oyo_id.slice(0, 3);
            })
            let uniqueList = [...new Set(oyoIdList)];
            logger.log(uniqueList);
            options = uniqueList.map(city => {
                return {value: city, label: city}
            })
            logger.log(options);
            this.setState({
                options: options
            }, ()=> {logger.log(this.state)})
        }
    }

    listHotels = (selectedOption) => {
        if(selectedOption!==null) {
            this.props.setSelectedCityRedux(selectedOption.value);
            this.setState({
                selectedCity : selectedOption.value
            })
        }  
    }

    render() {
        return (
            <React.Fragment>
                <div className="search-container">
                    <SearchBar options={this.state.options} handleChange={this.listHotels} />
                    <br></br>
                    {this.props.isLoading ? <div className="loader"></div> : <div></div>}
                    {this.props.recommResponse!=="" ? 
                     <div className="global-decomm-response" style={{color: "green"}}>{this.props.recommResponse}</div>
                     :
                     <div></div>}
                    {this.props.decommResponse!=="" ? 
                     <div className="global-decomm-response" style={{color: "green"}}>{this.props.decommResponse}</div>
                     :
                     <div></div>}
                    {this.props.selectedCity &&
                    <React.Fragment>
                        <div className="decommission-container">
                            <div className="tabs">
                                <h2 className="hotel-name">{this.props.selectedCity}</h2>
                                <input type="radio" name="tabs" id="tab1" />
                                <label htmlFor="tab1">Decommission</label>

                                <input type="radio" name="tabs" id="tab2" />
                                <label htmlFor="tab2">Recommission</label>

                                <div className="tab content1">
                                    <Decomm type={"hotel"}/>
                                </div>

                                <div className="tab content2">
                                    <Recomm type={"hotel"}/>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    }
                </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        hotelList: state.decomm.hotelList,
        isLoading : state.decomm.isLoading,
        decommResponse : state.decomm.decommRoomsResponse,
        recommResponse : state.decomm.recommRoomsResponse,
        selectedCity : state.decomm.selectedCity,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setSelectedCityRedux : async (payload) => await dispatch(actions.setSelectedCity(payload)),
        clearDecommRecommResponse : async () => await dispatch(actions.clearDecommRecommResponse()),
        getHotelList : async () => await dispatch(actions.getHotelList())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DecommByHotel);