import { ENV_PRODUCTION } from "server/config";

let log, error;

if (process.env.REACT_APP_MESH_ENV !== ENV_PRODUCTION) {
  log = console.log.bind(window.console);
  error = console.error.bind(window.console);
} else {
  log = function () {};
  error = function () {};
}

export default { log, error };
