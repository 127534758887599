import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../redux/actions';
import Popup from 'reactjs-popup';
import CheckBox from '../../_utilComponents/Checkboxes/Checkboxes';
import DateParsingUtil from '../../../../utils/DateParsingUtil';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './decommHistory.css';
import logger from "utils/logger"

let arrowMap = new Map(
    [
        ['Hotels Decommissioned', ''],
        ['By Whom', ''],
        ['Reason', ''],
        ['Time', '']
    ]
);

class DecommHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedItems : new Map(),
            reason : "",
            time : ""
        }
    }

    async componentDidMount(){
        logger.log("In decomm history");
        await this.props.getHotelList();
        this.setState({
            length: this.props.hotelList.length,
            checkedItems : new Map(),
            checkedAll : false
        }, ()=>{logger.log(this.state)});
        this.props.clearResponse();
    }

    sortBy = async (key) => {
        logger.log("Sort has been called");
        let i;
        let copyArray = [];
        if(this.props.hotelList.length!==0 && this.props.hotelList!==null) {
            let len = this.props.hotelList.length;
            for(i=0;i<len;i++) {
                copyArray[i] = this.props.hotelList[len-i-1];
            }
            logger.log("After: ", copyArray);
            await this.props.setHotelList(copyArray);
            arrowMap.set(key, !arrowMap.get(key));
        }
    };

    handleRowClick = async (id, time) => {
        logger.log(id);
        let newCheckedItems = new Map();
        if(this.state.checkedItems!==null) {
            if(this.state.checkedItems.get(id)){
                newCheckedItems.set(id, false);
                time = "";
            }
            else {
                newCheckedItems.set(id, true);
            }
            this.setState({
                checkedItems: newCheckedItems,
                time : time
            });
        }
    }

    setReason = (e) => {
        if(e.target.value!==null) {
            this.setState({
                reason : e.target.value
            })
        }
    }

    handleSubmit = async (close) => {
        let finalIds = [];
        this.state.checkedItems.forEach((value, key) => {
            if(value===true) {
                finalIds.push(key);
            }
        });
        let body = {
            decommissionObjectId : finalIds[0],
            reason : this.state.reason,
            requester : this.props.email,
            allProperties : false,
            oyoIds : [],
            cities : [],
        }
        logger.log(body);
        close();
        await this.props.recommission(body);
        await this.props.getHotelList();
        await this.setState({
            length: this.props.hotelList.length,
            checkedItems : new Map(),
            checkedAll : false
        }, ()=>{logger.log(this.state)})
    }
    arrow = keyValue => (
        arrowMap.get(keyValue)
            ? <FontAwesomeIcon icon="sort-alpha-down" style={{color: 'grey'}} className="arrow-sort"/>
            : <FontAwesomeIcon icon="sort-alpha-up" style={{color: 'grey'}} className="arrow-sort"/>
    );

    render() {
        return (
            this.props.hotelList.length!==0 ?
            <div className="decommission-container">
                <h1 className="decomm_heading">Decommission History</h1>
                {this.props.recommResponse!=="" ? 
                 <div className="global-decomm-response" style={{color: "green"}}>{this.props.recommResponse}</div>
                 :
                 <div></div>}
                {this.props.isLoading ? <div className="loader"></div> : <div></div>}
                <div className="tableAlign">
                    <Popup trigger = {
                        <button className="recomm-button">
                            Recommission hotels
                        </button>
                    } modal>
                        {close => (
                            <div className="activityLayout">
                                <div className="activity-heading">
                                    <div className="activity-label"> Recommission
                                        <button className="close-button" onClick={close}>
                                            <i className="fa fa-close"/>
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    {this.state.time==="" ? 
                                        <div className="selected-error">Select decommission transaction to recommission</div>
                                        :
                                        <React.Fragment>
                                            <div className="selected">
                                                Time of decommissioned transaction to be recommissioned : 
                                                {this.state.time}
                                            </div>
                                            <div>
                                                If you are sure you want to recommission, enter reason:
                                                <div>
                                                <input type= "text" placeholder="Enter reason" className="text-box"
                                                    value={this.state.reason} onChange={(e)=>{this.setReason(e)}}>
                                                </input>
                                                </div>
                                                <div>
                                                    <button className="submit-button-decomm" onClick={()=>{this.handleSubmit(close)}}>
                                                        Recommission
                                                    </button>
                                                    <button className="cancel-button-decomm" onClick={close}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        )}
                    </Popup>
                    <table className="hotel-Admin-layout">
                        <thead className="tableHead">
                            <tr>
                                <td className="table-heading"></td>
                                <td className="table-heading" /*onClick={() => this.sortBy('Hotels Decommissioned')}*/>Hotels Decomissioned</td>
                                <td className="table-heading" /*onClick={() => this.sortBy('By Whom')}*/>By Whom</td>
                                <td className="table-heading" /*onClick={() => this.sortBy('Reason')}*/>Reason</td>
                                <td className="table-heading" onClick={() => this.sortBy('Time')}>Time of decommission {this.arrow('Time')}</td>
                            </tr>
                        </thead>
                        <tbody className="body-layout">
                            {
                                this.props.hotelList.map(hotel => {
                                    return (
                                    <tr className="trColumn" key={hotel.id} onClick={()=>{this.handleRowClick(hotel.id, DateParsingUtil.changeEpochToStandard(hotel.decommisionedAt).toString().slice(0,-34))}}>
                                        <td className="tableColumn">
                                            <CheckBox name={hotel.id} checked={this.state.checkedItems.get(hotel.id)}/>
                                        </td>
                                        <td className="tableColumn">{JSON.stringify(hotel.hotelId).slice(2,-2)}</td>
                                        <td className="tableColumn">{hotel.requester}</td>
                                        <td className="tableColumn">{hotel.reason}</td>
                                        <td className="tableColumn">{DateParsingUtil.changeEpochToStandard(hotel.decommisionedAt).toString().slice(0,-34)}</td>
                                    </tr> 
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            :
            <div className="search-container">
                <h1 className="decomm_heading">
                    No Decommission History to show. Please decommission some hotels to view.
                </h1>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        hotelList : state.decomm.decommHotelList,
        email : state.login.email,
        recommResponse : state.decomm.recommRoomsResponse,
        isLoading : state.decomm.isLoading
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getHotelList : async () => dispatch(actions.getDecommissionedHotelList()),
        setHotelList : async (payload) => dispatch(actions.setDecommissionedHotelList(payload)),
        recommission : async (payload) => dispatch(actions.recommissionHotels(payload)),
        clearResponse : async () => dispatch(actions.clearDecommRecommResponse())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DecommHistory);