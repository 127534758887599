import React from 'react';
import { connect } from 'react-redux';
import './login.css';
import * as actions from './redux/actions';
import { Constants } from '../../Constants/Constants';
import { decrypt } from './../../utils/EncryptDecryptUtil';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import queryString from 'query-string';
import Cookies from 'js-cookie';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: 'vacationhomes',
      productListOpen: false,
      password: '',
      showPassword: false
    };
  }

  componentDidMount() {
    const queryParamsMap = queryString.parse(this.props.location.search);
    const token = queryParamsMap.authToken;
    const cookieSsoToken = Cookies.get('ssoToken');

    if (cookieSsoToken !== null && cookieSsoToken !== undefined) {
      let product = decrypt(localStorage.getItem(Constants.localStorageKeys.PRODUCT));
      let ssoLoginAttempted = decrypt(
        localStorage.getItem(Constants.localStorageKeys.SSO_LOGIN_ATTEMPTED)
      );

      if (Boolean(ssoLoginAttempted) === true && product !== undefined) {
        this.props.getSsoRole(cookieSsoToken, product);
      }
    } else if (token !== null && token !== undefined) {
      let product = decrypt(localStorage.getItem(Constants.localStorageKeys.PRODUCT));
      let ssoLoginAttempted = decrypt(
        localStorage.getItem(Constants.localStorageKeys.SSO_LOGIN_ATTEMPTED)
      );

      if (Boolean(ssoLoginAttempted) === true && product !== undefined) {
        this.props.getSsoRole(token, product);
      }
    }
  }

  initiateLogin = async (e) => {
    let form = e.target;
    e.preventDefault();
    const email = form.elements.email.value.toLowerCase();
    const password = form.elements.password.value;
    const product = form.elements.productType.value;

    if (!product) {
      alert('Please select the product category');
    } else {
      this.props.genericUserLogin(email, password, product);
    }
  };

  initiateSsoLogin = (e) => {
    e.preventDefault();

    if (!this.state.product) {
      alert('Please select the product category');
    } else {
      this.props.loginUserViaSso(this.state.product);
    }
  };

  handleProductListClose = () => {
    this.setState({ productListOpen: false });
  };

  handleProductListOpen = () => {
    this.setState({ productListOpen: true });
  };

  handleProductChange = (event) => {
    this.setState({ product: event.target.value });
  };

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  toggleShowPassword = () => {
    let currentShowPassword = this.state.showPassword;
    this.setState({ showPassword: !currentShowPassword });
  };

  render() {
    return (
      <div className="login-main">
        <div className="login-container">
          <div className="login-logo-container"></div>
          <form onSubmit={this.initiateLogin} className="login-form">
            <div className="login-input-container">
              <TextField
                id="email-imput"
                className="login-input"
                name="email"
                variant="outlined"
                autoComplete="on"
                placeholder="Email"
                required
              />
              <OutlinedInput
                id="password-input"
                name="password"
                type={this.state.showPassword ? 'text' : 'password'}
                value={this.state.password}
                onChange={this.handlePasswordChange}
                className="login-input"
                placeholder="Password"
                autoComplete="current-password"
                labelWidth={0}
                required
              />
            </div>
            <div className="product-type-container">
              <InputLabel id="product-label">Product</InputLabel>
              <FormControl>
                <Select
                  className="product-type-list"
                  name="productType"
                  open={this.state.productListOpen}
                  onClose={this.handleProductListClose}
                  onOpen={this.handleProductListOpen}
                  value={this.state.product}
                  onChange={this.handleProductChange}
                >
                  {/* Commented because of invalid options currently */}
                  {/* <MenuItem value={Constants.PRODUCT_TYPE_SWITCH}>OYO Switch</MenuItem>
                  <MenuItem value={Constants.PRODUCT_TYPE_UK_SMARTLOCK}>OYO UK Smartlock</MenuItem> */}
                  <MenuItem value={Constants.PRODUCT_TYPE_VACATIONHOMES}>
                    OYO Vacation Homes
                  </MenuItem>
                  {/* <MenuItem value={Constants.PRODUCT_TYPE_SMARTROOM}>OYO Smart Room</MenuItem> */}
                </Select>
              </FormControl>
            </div>
            <div className="login-button-container">
              <button type="submit" name="sign-in" className="login-button">
                Sign In
              </button>
            </div>
            <label className="login-error"> {this.props.errorMessage} </label>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { login } = state;
  return {
    email: login.email,
    product: login.productType,
    errorMessage: login.errorMessage
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    genericUserLogin: (email, password, product) =>
      dispatch(actions.genericUserLogin(email, password, product)),
    loginUserViaSso: (product) => dispatch(actions.loginUserViaSso(product)),
    getSsoRole: (ssoToken, productType) => dispatch(actions.getSsoRole(ssoToken, productType))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
