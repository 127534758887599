import {ovhOyoLifeEspDetails as initialState} from "./state";
import * as actionType from "./actionTypes";

const reducer = (state=initialState, action) => {
    switch(action.type) {
        case actionType.SET_IS_LOADING_TRUE:
            return {
                ...state,
                isLoading: true
            }
        case actionType.SET_IS_LOADING_FALSE:
            return {
                ...state,
                isLoading: false
            }
        case actionType.FETCH_ALL_DEVICES_SUCCESS:
            return{
                ...state,
                devices: action.payload
            }
        default:
            return state;
    }
}

export default reducer;