import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../OyoLifeDashboard/redux/actions';

import './deviceDetails.css';
import 'react-toastify/dist/ReactToastify.css';
import { Constants } from '../../../Constants/Constants';
import { decrypt } from '../../../utils/EncryptDecryptUtil';
import DateParsingUtil from "../../../utils/DateParsingUtil";
import logger from "utils/logger"

class DeviceDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredItems: [],
            newUserMacAddress: ''
        };
    }

    componentDidMount = async () => {
        let userId = decrypt(localStorage.getItem(Constants.localStorageKeys.USERID));
        await this.props.getDeviceList(userId);

        if (this.props.deviceList.length !== 0) {
            this.setState({ filteredItems: this.props.deviceList });
        }
    };

    changeMacAddress = (e) => {
        if (e.target.value !== null || e.target.value !== undefined || e.target.value !== '') {
            this.setState({ newUserMacAddress: e.target.value });
        }
    };

    handleAuthorizeUser = () => {
        const userMacAddress = this.state.newUserMacAddress;

        if (userMacAddress === '' || userMacAddress === null) {
            alert('Please enter a valid MAC Address');
            return;
        }

        let payload = {
            user_id: userMacAddress,
            device_id: "null",
            oyo_life_user: "true",
            is_auth_user: "true",
            first_seen: "0",
            last_seen: "0",
        };
        logger.log('payload for api is ', JSON.stringify(payload));
        this.props.updateUserMacAddress(payload);
    };

    handleUnauthorizeUser = () => {
        const userMacAddress = this.state.newUserMacAddress;

        if (userMacAddress === '' || userMacAddress === null) {
            alert('Please enter a valid MAC Address');
            return;
        }

        let payload = {
            user_id: userMacAddress,
            device_id: "null",
            oyo_life_user: "true",
            is_auth_user: "false",
            first_seen: "0",
            last_seen: "0",
        };
        logger.log('payload for api is ', JSON.stringify(payload));
        this.props.updateUserMacAddress(payload);
    };


    render() {
        const { filteredItems } = this.state;
        return (
            <div className="tableAlign">
                <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
                ></link>
                <div className="hotel-Admin-layout-container-layout">
                    {/*<div className="OVH-input-container">*/}
                        {/*<input*/}
                            {/*placeholder="Enter Lock ID, Property Code"*/}
                            {/*type="text"*/}
                            {/*className="input-bar"*/}
                            {/*onChange={(e) => {*/}
                                {/*this.filterItems(e.target.value.toLowerCase());*/}
                            {/*}}*/}
                        {/*/>*/}

                        {/*<input*/}
                            {/*placeholder="Enter Booking Id"*/}
                            {/*type="text"*/}
                            {/*className="booking-input-bar"*/}
                            {/*onChange={(e) => {*/}
                                {/*this.setState({ bookingId: e.target.value });*/}
                            {/*}}*/}
                        {/*/>*/}

                        {/*<Popup trigger={<button className="search-button">Search</button>} modal>*/}
                            {/*{(close) => <BookingIdSearch close={close} bookingId={bookingId} />}*/}
                        {/*</Popup>*/}

                        {/* <Popup trigger={<button className="staff-code-button">Generate 6 Month Staff Code</button>} modal>
                        {close=>(
                            <GenStaffCode close={close} propList={this.props.hotelList} userId={this.props.email} months=6/>
                        )}
                    </Popup> */}

                        {/*<button*/}
                            {/*className="search-button"*/}
                            {/*onClick={() => {*/}
                                {/*if (window.confirm('Are you Sure?')) {*/}
                                    {/*this.handleExportToCSV();*/}
                                {/*}*/}
                            {/*}}*/}
                        {/*>*/}
                            {/*Export to CSV*/}

                        {/*</button>*/}
                        {/*<ToastContainer />*/}

                        {/*{this.props.isLoading ? (*/}
                            {/*<div className="sk-circle">*/}
                                {/*<div className="sk-circle1 sk-child"></div>*/}
                                {/*<div className="sk-circle2 sk-child"></div>*/}
                                {/*<div className="sk-circle3 sk-child"></div>*/}
                                {/*<div className="sk-circle4 sk-child"></div>*/}
                                {/*<div className="sk-circle5 sk-child"></div>*/}
                                {/*<div className="sk-circle6 sk-child"></div>*/}
                                {/*<div className="sk-circle7 sk-child"></div>*/}
                                {/*<div className="sk-circle8 sk-child"></div>*/}
                                {/*<div className="sk-circle9 sk-child"></div>*/}
                                {/*<div className="sk-circle10 sk-child"></div>*/}
                                {/*<div className="sk-circle11 sk-child"></div>*/}
                                {/*<div className="sk-circle12 sk-child"></div>*/}
                            {/*</div>*/}
                        {/*) : (*/}
                            {/*<></>*/}
                        {/*)}*/}
                    {/*</div>*/}

                    <div className="form-label">
                        <label>Enter User MAC Address: </label>
                        <input type="text" onChange={this.changeMacAddress}/>

                        <button
                            type="submit"
                            className="submit-button"
                            onClick={() => {
                                this.handleAuthorizeUser();
                            }}
                        >
                            Authorize User
                        </button>

                        <button
                            type="submit"
                            className="submit-button"
                            onClick={() => {
                                this.handleUnauthorizeUser();
                            }}
                        >
                            Unauthorize User
                        </button>

                    </div>
                    <br></br>
                    <div className="hotel-Admin-layout-container">
                        {filteredItems.length !== 0 || this.props.deviceList.length !== 0 ? (
                            <>
                                <table className="hotel-Admin-layout">
                                    <thead className="tableHead">
                                    <tr>
                                        <td className="table-heading">User Mac Address</td>
                                        <td className="table-heading">Device Mac Address</td>
                                        <td className="table-heading">First Seen</td>
                                        <td className="table-heading">Last Seen</td>
                                        <td className="table-heading">Connect Status</td>
                                        <td className="table-heading">Authenticated User</td>
                                        {/*<td className="table-heading">Estimated Next Battery Change Date</td>*/}
                                        {/*<td className="table-heading">Service partner PIN</td>*/}
                                        {/*<td className="table-heading">Master PIN</td>*/}
                                        {/*<td className="table-heading">Edit Last Battery Changed Date</td>*/}
                                        {/*<td className="table-heading">Edit Service Partner Pin</td>*/}
                                        {/*<td className="table-heading">Edit Master Pin</td>*/}
                                    </tr>
                                    </thead>
                                    <tbody className="body-layout">
                                    {filteredItems.map((device) => {
                                        return (
                                            <tr className="trColumn" key={device.user_id}>
                                                <td className="tableColumn">{device.user_id}</td>
                                                <td className="tableColumn">{device.device_id}</td>
                                                <td className="tableColumn">
                                                    {device.first_seen !== undefined && device.first_seen !== null
                                                        ? DateParsingUtil.changeEpochToStandard(device.first_seen / 1000)
                                                            .toString()
                                                            .slice(0, -34)
                                                        : 'NOT AVAILABLE'}
                                                </td>
                                                <td className="tableColumn">
                                                    {device.last_seen !== undefined && device.last_seen !== null
                                                        ? DateParsingUtil.changeEpochToStandard(device.last_seen / 1000)
                                                            .toString()
                                                            .slice(0, -34)
                                                        : 'NOT AVAILABLE'}
                                                </td>
                                                <td className="tableColumn">
                                                    {(device.last_seen - device.first_seen <= 15 * 60 * 1000) ? "Connected" : "Disconnected"}
                                                </td>
                                                <td className="tableColumn">
                                                    {device.is_auth_user === true ? "Yes" : "No"}

                                                </td>
                                                {/*<td className="tableColumn">*/}
                                                {/*</td>*/}
                                                {/*<td className="tableColumn">*/}
                                                {/*</td>*/}
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </>
                        ) : this.props.isLoading ? (
                            <div></div>
                        ) : (
                            <div>No Device data available</div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        deviceList: state.ovhOyoLifeDetails.devices,
        email: state.login.email,
        userId: state.login.userId,
        userProfileId: state.login.userProfileId,
        response: state.ovhOyoLifeDetails.response,
        isLoading: state.ovhOyoLifeDetails.isLoading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDeviceList: async (userId) => await dispatch(actions.getDevices(userId)),
        updateUserMacAddress: async (payload) => await dispatch(actions.updateUserMacAddress(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetails);
