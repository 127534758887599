import axios from 'axios';
import logger from 'utils/logger';
import { logoutUser } from "../Components/Login/redux/actions";
import {store} from "../store/index"

const GET = async (baseURL, relativeURL, headers) => {
    let response;
    try {
        response = await axios.get(baseURL + relativeURL, {headers});
        return response;
    } catch (error) {
        logger.log("In [utils/RESTApi.js] Error in GET Api call. Error is: " + JSON.stringify(error));
        if (error.response !== undefined && error.response.status===401) {	
            alert("Session has expired. Please login again to view the data");	 
            store.dispatch(logoutUser());  	
        }
        throw error;
    }
};

const POST = async (baseURL, relativeURL, payload, headers) => {
    let response;
    try {
        headers = {
            ...headers,
            "Content-Type": "application/json"
        };
        response = await axios.post(baseURL + relativeURL, payload, {headers});
        return response;
    } catch (error) {
        logger.log("In [utils/RESTApi.js] Error in POST Api call. Error is: " + JSON.stringify(error));
        if (error.response !== undefined && error.response.status===401) {	
            alert("Session has expired. Please login again to view the data");	 
            store.dispatch(logoutUser());  	
        }
        throw error;
    }
};


export default {
    GET,
    POST
}
