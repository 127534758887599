import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Constants } from '../../../Constants/Constants';
import { decrypt } from '../../../utils/EncryptDecryptUtil';
import { AuthenticatedSmartlockHeaders } from '../../../Constants/HeadersConstants';
import { APIURL } from '../../../Constants/urlConstant';
import RESTApi from '../../../utils/RESTApi';
import DateParsingUtil from './../../../utils/DateParsingUtil';

let profileID = decrypt(localStorage.getItem(Constants.localStorageKeys.USER_PROFILE_ID));

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const ViewCodeModal = ({ bookingID }) => {
  const [response, setResponse] = useState([]);
  const [errorStatus, setErrorStatus] = useState(false);
  const payload = {
    booking_id: bookingID,
    params_type: 'guest',
    user_id: profileID
  };
  useEffect(() => {
    async function fetchData() {
      try {
        let result = await RESTApi.POST(
          APIURL.OVH_BASE_URL,
          APIURL.OVH_VIEW_CODE,
          payload,
          AuthenticatedSmartlockHeaders()
        );
        if (result?.data?.success) {
          setResponse(result?.data);
        } else {
          setErrorStatus(true);
        }
      } catch (error) {
        setErrorStatus(true);
      }
    }

    fetchData();
  }, []);
  return (
    <>
      {errorStatus && <h1> No Valid codes for the given combination </h1>}
      {response.success && (
        <>
          <div className="text-center mt-5 text-xl">
            Generated Code is <span className="text-green-500 font-bold">{response?.code}</span>
          </div>
          <div className="text-center mt-5">
            For Booking ID <span className="font-bold">{response?.booking_id}</span> and Property{' '}
            <span className="font-bold">{response?.property_id}</span>
          </div>
          <div className="text-center mt-5">
            Which is Valid from{' '}
            <span className="font-bold">
              {DateParsingUtil.changeEpochToStandard(
                (response.valid_from + new Date().getTimezoneOffset() * 60 * 1000) / 1000
              )
                .toString()
                .slice(0, -34)}{' '}
            </span>
            to{' '}
            <span className="font-bold">
              {DateParsingUtil.changeEpochToStandard(
                (response.valid_upto + new Date().getTimezoneOffset() * 60 * 1000) / 1000
              )
                .toString()
                .slice(0, -34)}{' '}
            </span>
            <span className="text-blue-500">(GMT)</span>
          </div>
          <div className="text-center mt-5">
            Valid from <span className="font-bold"> {response.started_at} </span> and{' '}
            <span className="font-bold">{response.ending_At}</span>{' '}
            <span className="text-blue-500">(Property Local Time)</span>
          </div>
        </>
      )}
    </>
  );
};

Modal.setAppElement('#root');

const ViewCode = ({ bookingID }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <button
        className="ml-5 flex-wrap text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
        onClick={openModal}
      >
        View Code
      </button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <ViewCodeModal bookingID={bookingID} />
      </Modal>
    </div>
  );
};

export default ViewCode;
