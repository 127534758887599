export const SET_IS_LOADING_TRUE = "SET_IS_LOADING_TRUE";
export const SET_IS_LOADING_FALSE = "SET_IS_LOADING_FALSE";

export const LOAD_HOTELS_LIST_SUCCESS = "LOAD_HOTELS_LIST_SUCCESS";
export const LOAD_ROOM_LIST_SUCCESS = "LOAD_ROOM_LIST_SUCCESS";
export const CLEAR_ROOM_LIST = "CLEAR_ROOM_LIST";
export const SET_SELECTED_HOTEL = "SET_SELECTED_HOTEL";
export const CLEAR_HOTEL_AND_ROOMS = "CLEAR_HOTEL_AND_ROOMS";

export const LOAD_ACTIVITY_REASONS = "LOAD_ACTIVITY_REASONS";
export const SET_ACTIVITY_RESPONSE_DATA = "SET_ACTIVITY_RESPONSE_DATA";
export const CLEAR_V2_RESPONSE = "CLEAR_V2_RESPONSE";

export const SET_STAFF_ENTRY_RESPONSE = "SET_STAFF_ENTRY-RESPONSE";
export const SET_OFFLINE_CODES_RESPONSE = "SET_OFFLINE_CODES_RESPONSE";
export const V1_CHECK_IN = "V1_CHECK_IN";

export const SET_BULK_CHECKIN_DATA = "SET_BULK_CHECKIN_DATA";
export const BULK_CHECK_IN_RESPONSE ="BULK_CHECK_IN_RESPONSE";
export const CLEAR_BULK_RESPONSE = "CLEAR_BULK_RESPONSE";