import React from 'react';
import { connect } from 'react-redux';
import { ExportToCsv } from 'export-to-csv';
import Popup from 'reactjs-popup';
import * as actions from '../DeviceDetails/redux/actions';
import UpdateMasterPin from './../../../CommonUI/SmartlockButtons/updateMasterPin';
import UpdateServicePartnerPin from './../../../CommonUI/SmartlockButtons/updateServicePartnerPin';
import UpdateLastBatteryChangedDate from './../../../CommonUI/SmartlockButtons/updateLastBatteryChangedDate';

import './deviceDetails.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Constants } from '../../../Constants/Constants';
import { decrypt } from '../../../utils/EncryptDecryptUtil';
import DateParsingUtil from '../../../utils/DateParsingUtil';

class DeviceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredItems: [],
      bookingId: ''
    };
  }

  componentDidMount = async () => {
    let userId = decrypt(localStorage.getItem(Constants.localStorageKeys.USERID));
    await this.props.getDeviceList(userId);

    if (this.props.deviceList.length !== 0) {
      this.props.deviceList.sort((a) => (a.master_pin === null ? 1 : -1));
      this.setState({ filteredItems: this.props.deviceList });
    }
  };

  handleExportToCSV = () => {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: '',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.props.deviceList);
  };

  filterItems = (text) => {
    if (text !== '') {
      let tempFilteredItems = this.props.deviceList.filter(
        (item) =>
          (item.device_id && item.device_id.toLowerCase().includes(text)) ||
          (item.property_id && item.property_id.toLowerCase().includes(text))
      );
      this.setState({ filteredItems: tempFilteredItems });
    } else {
      this.setState({ filteredItems: this.props.deviceList });
    }
  };

  render() {
    const { filteredItems } = this.state;
    const EditClass =
      'ml-2 py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700';
    return (
      <div className="tableAlign">
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        ></link>
        <div className="hotel-Admin-layout-container-layout">
          <div className="OVH-input-container flex">
            <input
              placeholder="Enter Lock ID, Property Code"
              type="text"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              onChange={(e) => {
                this.filterItems(e.target.value.toLowerCase().trim());
              }}
            />

            <button
              className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              onClick={() => {
                if (window.confirm('Are you Sure?')) {
                  this.handleExportToCSV();
                }
              }}
            >
              Export to CSV
            </button>
            <ToastContainer />

            {this.props.isLoading ? (
              <div className="sk-circle">
                <div className="sk-circle1 sk-child"></div>
                <div className="sk-circle2 sk-child"></div>
                <div className="sk-circle3 sk-child"></div>
                <div className="sk-circle4 sk-child"></div>
                <div className="sk-circle5 sk-child"></div>
                <div className="sk-circle6 sk-child"></div>
                <div className="sk-circle7 sk-child"></div>
                <div className="sk-circle8 sk-child"></div>
                <div className="sk-circle9 sk-child"></div>
                <div className="sk-circle10 sk-child"></div>
                <div className="sk-circle11 sk-child"></div>
                <div className="sk-circle12 sk-child"></div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="hotel-Admin-layout-container">
            {filteredItems.length !== 0 || this.props.deviceList.length !== 0 ? (
              <>
                <table className="hotel-Admin-layout">
                  <thead className="tableHead">
                    <tr>
                      <td className="table-heading">Lock ID</td>
                      <td className="table-heading">Property Code</td>
                      <td className="table-heading">Lock Type</td>
                      <td className="table-heading">Last Sync Time</td>
                      <td className="table-heading">Last Battery Changed Date</td>
                      <td className="table-heading">Estimated Next Battery Change Date</td>
                      <td className="table-heading">Service partner PIN</td>
                      <td className="table-heading">Master PIN</td>
                      {/*<td className="table-heading">Edit Last Battery Changed Date</td>*/}
                      {/*<td className="table-heading">Edit Service Partner Pin</td>*/}
                      {/*<td className="table-heading">Edit Master Pin</td>*/}
                    </tr>
                  </thead>
                  <tbody className="body-layout">
                    {filteredItems.map((device) => {
                      return (
                        <tr className="trColumn" key={device.device_id}>
                          <td className="tableColumn">{device.device_id}</td>
                          <td className="tableColumn">{device.property_id}</td>
                          <td className="tableColumn">{device.device_type}</td>
                          <td className="tableColumn">
                            {device.updated_at !== undefined
                              ? DateParsingUtil.changeEpochToStandard(device.updated_at)
                                  .toString()
                                  .slice(0, -34)
                              : 'NOT AVAILABLE'}
                          </td>
                          <td className="tableColumn">
                            {device.last_battery_changed_date !== undefined &&
                            device.last_battery_changed_date !== null
                              ? DateParsingUtil.changeEpochToStandard(
                                  device.last_battery_changed_date
                                )
                                  .toString()
                                  .slice(0, -34)
                              : 'NOT AVAILABLE'}
                            <Popup trigger={<button className={EditClass}>Edit</button>} modal>
                              {(close) => (
                                <UpdateLastBatteryChangedDate
                                  close={close}
                                  userId={this.props.userId}
                                  userProfileId={this.props.userProfileId}
                                  email={this.props.email}
                                  propId={device.property_id}
                                  deviceId={device.device_id}
                                  currLastBatteryChangedDate={device.last_battery_changed_date}
                                />
                              )}
                            </Popup>
                          </td>
                          <td className="tableColumn">
                            {device.last_battery_changed_date !== undefined &&
                            device.last_battery_changed_date !== null
                              ? DateParsingUtil.addMonthToCurrentEpoch(
                                  device.last_battery_changed_date,
                                  4
                                )
                                  .toString()
                                  .slice(0, -34)
                              : 'NOT AVAILABLE'}
                          </td>
                          <td className="tableColumn">
                            {device.service_partner_pin}
                            <Popup trigger={<button className={EditClass}>Edit</button>} modal>
                              {(close) => (
                                <UpdateServicePartnerPin
                                  close={close}
                                  userId={this.props.userId}
                                  userProfileId={this.props.userProfileId}
                                  email={this.props.email}
                                  propId={device.property_id}
                                  deviceId={device.device_id}
                                  currServicePartnerPin={device.service_partner_pin}
                                />
                              )}
                            </Popup>
                          </td>
                          <td className="tableColumn">
                            {device.master_pin}
                            <Popup trigger={<button className={EditClass}>Edit</button>} modal>
                              {(close) => (
                                <UpdateMasterPin
                                  close={close}
                                  userId={this.props.userId}
                                  userProfileId={this.props.userProfileId}
                                  email={this.props.email}
                                  propId={device.property_id}
                                  deviceId={device.device_id}
                                  currMasterPin={device.master_pin}
                                />
                              )}
                            </Popup>
                          </td>
                          {/*<td className="tableColumn">*/}
                          {/*</td>*/}
                          {/*<td className="tableColumn">*/}
                          {/*</td>*/}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : this.props.isLoading ? (
              <div></div>
            ) : (
              <div>No Device data available</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deviceList: state.ovhDeviceDetails.devices,
    email: state.login.email,
    userId: state.login.userId,
    userProfileId: state.login.userProfileId,
    response: state.ovhDeviceDetails.response,
    isLoading: state.ovhDeviceDetails.isLoading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDeviceList: async (userId) => await dispatch(actions.getDevices(userId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetails);
