export const ENV_PRODUCTION = 'production';
export const ENV_STAGING = 'staging';

const URL = {
  dev: {
    PLATFORM_SSO_REDIRECT_URL:
      'http://sso-api.plcore.oyorooms.ms/clientLogin?client=localTest&client_path=login',

    // SMARTLOCK_BASE_URL: "http://localhost:8080/mesh/smartlock",
    SMARTLOCK_BASE_URL: 'http://iot-1.oyo-smartlock.incubate.oyorooms.ms/mesh/smartlock',

    //SWITCH_BASE_URL: "http://localhost:8080/mesh",
    SWITCH_BASE_URL: 'http://iot-1.iot-java-server.incubate.oyorooms.ms/mesh',

    SMART_ROOM_BASE_URL:
      'http://iot-0.iot-concept-room.incubate.oyorooms.ms/mesh/concept-room/internal'
  },
  staging: {
    PLATFORM_SSO_REDIRECT_URL:
      'http://sso-api.plcore.oyorooms.ms/clientLogin?client=localTest&client_path=login',

    SMARTLOCK_BASE_URL: 'http://zs-smartlock-api-1.iot.internal.oyorooms.ms/mesh/smartlock',

    SWITCH_BASE_URL: 'http://zs-oyo-switch-api-1.iot.internal.oyorooms.ms/mesh',

    SMART_ROOM_BASE_URL:
      'http://smart-room-api-1.iot.internal.oyorooms.ms/mesh/concept-room/internal'
  },
  production: {
    PLATFORM_SSO_REDIRECT_URL:
      'https://sso.oyorooms.com/clientLogin?client=mesh_support&client_path=login',

    SMARTLOCK_BASE_URL: 'https://api.oyorooms.com/mesh/smartlock',

    SWITCH_BASE_URL: 'https://api.oyorooms.com/mesh',

    SMART_ROOM_BASE_URL: 'https://api.oyorooms.com/mesh/concept-room/internal'
  }
};

let finalConfig = URL.dev;
if (process.env.REACT_APP_MESH_ENV === ENV_PRODUCTION) {
  finalConfig = URL.production;
} else if (process.env.REACT_APP_MESH_ENV === ENV_STAGING) {
  finalConfig = URL.staging;
}

export default finalConfig;
