import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

const progressBar = props => {
    const BorderLinearProgress = withStyles({
        root: {
          height: 20,
          width:100,
          background:'#cfcfcf',
          
        },
        bar: {
          borderRadius: 20,
          backgroundColor:'#ff8c8c',
          borderColor: '#000'
        },
    })(LinearProgress);

    return (
        <div>
            <BorderLinearProgress
                variant="determinate"
                color="secondary"
                value={props.battery}
            />
        </div>
    )
}

export default progressBar;