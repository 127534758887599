import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from './redux/actions';
import TableRoom from './ByRoom/Table/table';
import TableHotel from './ByHotel/Table/table';
import {Constants} from '../../../Constants/Constants';
import logger from "utils/logger"

class Decomm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trigger_rerender : true
        }
    }
    onDecomm = async (arr, checkedAll, reason, decommType) => {
        if(arr.length===0 && checkedAll===false) {
            if(decommType===undefined)
                logger.log("No rooms selected");
            else
                logger.log("No hotels selected");
        } else {
            if(reason!=="") {
                if(decommType === Constants.decommConstants.ROOM) {
                    let body = {
                        "all_rooms" : checkedAll,
                        "room_numbers" : arr,
                        "oyo_id" : this.props.selectedHotel.oyoId,
                        "decommission_reason": reason,
                        "decommissioned_by": this.props.email
                    }
                    logger.log(body);
                    this.props.clearResponse();
                    await this.props.decommissionRooms(body);
                    await this.props.getRoomList(this.props.selectedHotel.hotelId);
                } 
                else if(decommType === Constants.decommConstants.HOTEL){
                    logger.log(arr.length)
                    if(arr.length!==1) {
                        let body = {
                            "decommissionObjectId" : null,
                            "allProperties" : false,
                            "oyoIds" : arr,
                            "cities" : [],
                            "reason" : reason,
                            "requester" : this.props.email
                        }
                        this.props.clearResponse();
                        await this.props.decommissionHotels(body);
                        await this.props.getHotelList();
                        this.setState({
                            trigger_rerender : !this.state.trigger_rerender
                        }, ()=>{logger.log("Re-render has been triggered")})
                    }
                    else {
                        let body = {
                            "all_rooms" : true,
                            "room_numbers" : [],
                            "oyo_id" : arr[0],
                            "recommission_reason": reason,
                            "recommissioned_by": this.props.email
                        }
                        this.props.clearResponse();
                        await this.props.decommissionRooms(body);
                        await this.props.getHotelList();
                        this.setState({
                            trigger_rerender : !this.state.trigger_rerender
                        }, ()=>{logger.log("Re-render has been triggered")})
                    }
                }
            }
            else {
                alert("Please enter reason to decommission")
            }
        }
    }
    render(){
        return (
            <React.Fragment>
                {this.props.type === Constants.decommConstants.ROOM ? 
                <TableRoom isCommissioned={true} onSubmit={this.onDecomm} />
                : this.props.type === Constants.decommConstants.HOTEL ?
                <TableHotel isCommissioned={true} onSubmit={this.onDecomm} />
                : 
                // <TableCity isCommissioned={true} onSubmit={this.onDecomm}/>}
                <div></div>}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        selectedHotel : state.decomm.selectedHotel,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        decommissionRooms : async (payload) => dispatch(actions.decommissionRooms(payload)),
        decommissionHotels : async (payload) => dispatch(actions.decommissionHotels(payload)),
        getRoomList : async (hotelId) => await dispatch(actions.getRooms(hotelId)),
        getHotelList : async () => await dispatch(actions.getHotelList()),
        clearResponse : async () => await dispatch(actions.clearDecommRecommResponse())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Decomm);