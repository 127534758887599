import React, {Component} from 'react';
import {connect} from 'react-redux';
import { getMacDigitsFromDeviceId } from '../../../../commonFunctions/updateDeviceId';
import * as actions from '../redux/actions';
import './table.css';
import logger from 'utils/logger';

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedItems: new Map(),
        }
        this.handleRowClick = this.handleRowClick.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedHotel.hotelId !== this.props.selectedHotel.hotelId) {
            this.setState({
                checkedItems: new Map()
            });
        }
    }

    handleRowClick = (roomNo, isWhiteListed, isSevOffline) => {
        const item = roomNo;
        let isChecked = this.state.checkedItems.get(roomNo);
        if(isWhiteListed===null || isWhiteListed===undefined) {
            isWhiteListed = false;
        }
        if(isSevOffline===null || isSevOffline===undefined) {
            isSevOffline = false;
        }
        if(isChecked === undefined||isChecked === false) {
            if(!isWhiteListed) {
                isChecked = true;
            }
            else if(isWhiteListed && isSevOffline) {
                isChecked = true;
            }
        }
        else if(isChecked===true) {
            isChecked = false;
            if(this.state.checkedAll===true) {
                this.setState({
                    checkedAll:false
                })
            }
        }
        if (item !== undefined && item !== null) {
            this.setState(prevState => 
                ({checkedItems: prevState.checkedItems.set(item, isChecked)}));
        }
    }

    handleSubmit = () => {
        let arr = [];
        this.state.checkedItems.forEach((value, key) => {
            if(value)
                arr.push(key);
        })
        if(arr.length!==0) {
            logger.log("whitelist the devices");    
        }
        else{
            alert("Select devices to whitelist");
        }
    }

    render() {
        if(this.props.roomData.length!==0) {
            return (
                <div className="tableAlign">
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                    <div>
                        <div className="table-option-heading">
                            <h3 className="hotel-name">{this.props.selectedHotel.hotelName} | {this.props.selectedHotel.hotelId}</h3>
                            <h3 className="hotel-name">HOTEL TYPE: {this.props.selectedHotel.hotelType}</h3>
                            <h1>{this.props.deviceType === '1-ch-v2' ? "Version 2 Device is installed" : "Version 1 Device is installed"} </h1>
                            <h3>{this.props.selectedHotel.isCommissioned ? "Commissioned" : "Not Commissioned"} </h3>
                            {/* <button className="bulk-access-button" onClick={this.handleSubmit}>Whitelist devices</button> */}
                        </div>
                    </div>
                    <div className="hotel-Admin-layout-container-layout">
                        <div className="hotel-Admin-layout-container">
                            <div className="hotel-Admin-layout-container-header">
                                <button className="refresh-button" onClick={this.handleRefresh}>
                                    Refresh <i className="fa fa-refresh"/>
                                </button>
                            </div>
                            <table className="hotel-Admin-layout">
                                <thead className="tableHead">
                                    <tr>
                                        {<td className="table-heading">Room No </td>}
                                        {<td className="table-heading">Device Id </td>}
                                        {<td className="table-heading">Online </td>}
                                        {<td className="table-heading">Whitelisted</td>}
                                        {<td className="table-heading">Keycard Status</td>}
                                        {<td className="table-heading">Wifi Details</td>}
                                        {<td className="table-heading">Is Mapping Tested</td>}
                                        {<td className="table-heading">Commissioned</td>}
                                        {<td className="table-heading">Is Severe Offline</td>}
                                    </tr>
                                </thead>
                                <tbody className="body-layout">
                                    {this.props.roomData.map(tableRow => {
                                        return (
                                            <tr className="trColumn" onClick={()=>{
                                                return
                                                }}>
                                                {<td className="tableColumn">{tableRow.room_no}</td>}
                                                {<td className="tableColumn">
                                                    {getMacDigitsFromDeviceId(tableRow.device_id)}
                                                </td>}
                                                {<td className="tableColumn">{tableRow.is_connected ?
                                                    <p style={{color: 'green'}}>Yes</p> :
                                                    <p style={{color: 'red'}}>No</p>}</td>}
                                                {<td className="tableColumn">{tableRow.is_whitelisted ?
                                                    <p style={{color: 'green'}}>Yes</p> :
                                                    <p style={{color: 'red'}}>No</p>}</td>}
                                                {<td className="tableColumn">{tableRow.keycard_state === '0' ?
                                                    <p style={{color: 'red'}}>Removed</p> :
                                                    <p style={{color: "green"}}>Inserted</p>}</td>}
                                                {<td>
                                                    <p className="wifi-details-label">SSID:</p>
                                                    <p className="wifi-details"><b>{tableRow.wifi_ssid}</b></p>
                                                    <p className="wifi-details-label">PASSWORD:</p>
                                                    <p className="wifi-details"><b>{tableRow.wifi_password}</b></p>
                                                </td>}
                                                {<td className="tableColumn">{tableRow.is_mapping_tested ?
                                                    <p style={{color: 'green'}}>Yes</p> :
                                                    <p style={{color: 'red'}}>No</p>}</td>}
                                                {<td className="tableColumn">{tableRow.is_enabled ? "true" : "false"}</td>}
                                                {<td className="tableColumn">{tableRow.severe_offline ?
                                                    <p style={{color: 'green'}}>Yes</p> :
                                                    <p style={{color: 'red'}}>No</p>}</td>}
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return (
                <div></div>
            ) 
        }
    }
}

const mapStateToProps = state => {
    return {
        hotelId: state.installation.hotelData.hotelId,
        roomData: state.installation.hotelData.roomList,
        deviceType: state.installation.hotelData.deviceType,
        selectedHotel: state.installation.selectedHotel
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getRooms: async (payload) => await dispatch(actions.getRooms(payload)),
        setRoomList: async (payload) => dispatch(actions.getRoomList(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
