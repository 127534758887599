import React, { Component } from "react";
import { connect } from "react-redux";
import DateParsingUtil from "./../../utils/DateParsingUtil";
import * as actions from "./redux/actions";
import { Constants } from "./../../Constants/Constants";
import logger from "utils/logger";

class ViewCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingId: "",
      userId: "",
      codeType: "",
    };
  }

  componentDidMount() {
    this.props.clearResponse();
    this.setState({
      bookingId: "",
      userId: "",
    });

    if (this.props.codeType === Constants.CustomerType.staff) {
      this.handleSubmit();
    }
  }

  handleBookingIdChange = (e) => {
    if (e.target.value !== null || e.target.value !== undefined) {
      this.setState({
        bookingId: e.target.value,
      });
    }
  };
  changeUserId = (e) => {
    if (
      e.target.value !== null ||
      e.target.value !== undefined ||
      e.target.value !== ""
    ) {
      this.setState({
        userId: e.target.value,
      });
    }
  };
  handleSubmit = async () => {
    if (
      this.props.codeType === Constants.CustomerType.guest &&
      (this.state.bookingId === null || this.state.bookingId === "")
    ) {
      alert("Please enter a booking ID");
    } else {
      let payload = {
        property_id: this.props.propId,
        room_no: this.props.roomNo,
        booking_id: this.state.bookingId,
        params_type: this.props.codeType,
        user_id: this.props.userId,
      };
      logger.log(payload);
      await this.props.viewCode(payload);
      this.setState({
        bookingId: "",
        userId: "",
      });
    }
  };
  render() {
    return (
      <>
        <div className="activityLayout">
          <div className="activity-heading">
            <div className="activity-label">
              View Code
              <button className="close-button" onClick={this.props.close}>
                <i className="fa fa-close" />
              </button>
            </div>
          </div>
          {this.props.codeType === Constants.CustomerType.guest ? (
            <div>
              <div>
                <label>Booking Id: </label>
                <input
                  type="text"
                  onChange={this.handleBookingIdChange}
                  value={this.state.bookingId}
                />
              </div>
              <br />
              <button
                type="submit"
                className="submit-button"
                onClick={this.handleSubmit}
              >
                Submit
              </button>
            </div>
          ) : null}
          <br />
          <br />
          {this.props.isLoading ? <div className="loader"></div> : <div></div>}
          <br />
          {this.props.response ? (
            <div>
              {this.props.response.success ? (
                <div className="response">
                  {this.props.codeType === Constants.CustomerType.guest ? (
                    <p>{"Booking ID : " + this.props.response.booking_id}</p>
                  ) : null}
                  <p style={{ color: "green" }}>
                    Code : <b>{this.props.response.code}</b>
                  </p>
                  <p style={{ color: "red" }}>
                    {"Valid from : " +
                      DateParsingUtil.changeEpochToStandard(
                        (this.props.response.valid_from +
                          new Date().getTimezoneOffset() * 60 * 1000) /
                          1000
                      )
                        .toString()
                        .slice(0, -34) +
                      "(UTC)"}
                  </p>
                  <p style={{ color: "red" }}>
                    {"Valid upto : " +
                      DateParsingUtil.changeEpochToStandard(
                        (this.props.response.valid_upto +
                          new Date().getTimezoneOffset() * 60 * 1000) /
                          1000
                      )
                        .toString()
                        .slice(0, -34) +
                      "(UTC)"}
                  </p>
                  {/* <p style={{color: "red"}}>{"Valid from : " + DateParsingUtil.changeEpochToStandard(DateParsingUtil.getTimeZoneOffsetEpoch(this.props.response.valid_from, (new Date().getTimezoneOffset()) * (-1))/1000).toString().slice(0, -34) + "(Local Time)"}</p>
                                    <p style={{color: "red"}}>{"Valid upto : " + DateParsingUtil.changeEpochToStandard(DateParsingUtil.getTimeZoneOffsetEpoch(this.props.response.valid_upto, (new Date().getTimezoneOffset()) * (-1))/1000).toString().slice(0, -34) + "(Local Time)"}</p>  */}
                  <p style={{ color: "red" }}>
                    {"Valid from : " +
                      this.props.response.started_at +
                      "(Local Time)"}
                  </p>
                  <p style={{ color: "red" }}>
                    {"Valid upto : " +
                      this.props.response.ending_At +
                      "(Local Time)"}
                  </p>
                </div>
              ) : (
                <p style={{ color: "red" }}>{this.props.response.message}</p>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.smartlockButtons.response,
    isLoading: state.smartlockButtons.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    viewCode: async (payload) => dispatch(actions.viewCode(payload)),
    clearResponse: async () => dispatch(actions.clearResponse()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewCode);
