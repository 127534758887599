const changeStandardToEpoch = (date) => {
    try {
        let epoch = date.getTime();
        // epoch = epoch/1000;
        // return (epoch + 19800);
        return epoch;
    } catch (error) {
        return 0;
    }
};

const changeEpochToStandard = (epoch) =>  {
    if(epoch === null) {
        return 0
    } else {
        return new Date(epoch * 1000);
    }
};

const addMonthToCurrentEpoch = (epoch, month) =>  {
    if(epoch === null) {
        return 0
    } else {
        var date = new Date(epoch * 1000);
        date.setMonth(date.getMonth() + month);
        return date;
    }
};

const getCurrentTimeInEpoch = () => {
    return new Date().getTime()/1000;
};

const getTimeZoneOffsetEpoch = (epoch, timeZoneOffSetInMinutes) => {

    return epoch + (timeZoneOffSetInMinutes * 60 * 1000);
}

const getMinutesToAddforUTC = (timeZone) => {
    //timeZone will be of the form "-11:00", "+05:30", "+ 02:00"

    // logger.log("timeZone received = ", timeZone);
    let neg = -1;
    let arr = [];
    arr = timeZone.split(":");
    if (arr.length !== 2) {
        return null;
    }
    let hr = parseInt(arr[0]);
    let min = parseInt(arr[1]);

    if (hr < 0) {
        neg = 1;
        hr = hr * -1;
    }
    let reqMinutes = neg * (hr * 60  + min);
    // logger.log("Converted seconds = ", reqMinutes);
    return reqMinutes;
}

const getSecondsToAddforUTC = (timeZone) => {
    return getMinutesToAddforUTC(timeZone) * 60 ;
}

export default {
    changeStandardToEpoch,
    changeEpochToStandard,
    addMonthToCurrentEpoch,
    getCurrentTimeInEpoch,
    getMinutesToAddforUTC,
    getSecondsToAddforUTC,
    getTimeZoneOffsetEpoch
}