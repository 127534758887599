import React, {Component} from "react";
class error extends Component {
    render() {
        return (
            <div>
               <img src="../../assets/images/error-page.png" alt = "This is the text"/>
            </div>
        )
    }
}

export default error;