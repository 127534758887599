import RESTApi from '../../../../utils/RESTApi';
import {APIURL} from '../../../../Constants/urlConstant';
import {AuthenticatedDefaultHeaders, AuthenticatedSmartlockHeaders} from '../../../../Constants/HeadersConstants';
import * as actionType from './actionTypes';
import logger from 'utils/logger';
import {clearResponse, setResponse} from "../../../../CommonUI/SmartlockButtons/redux/actions";

const _setIsLoadingTrue = () => {
    return {
        type: actionType.SET_IS_LOADING_TRUE,
    }
}
const _setIsLoadingFalse = () => {
    return {
        type: actionType.SET_IS_LOADING_FALSE,
    }
}

const _setDeviceData = (payload) => {
    return {
        type: actionType.FETCH_ALL_DEVICES_SUCCESS,
        payload: payload
    }
}

export const getDevices = (userId) => {
    return async function(dispatch) {
        try {
            dispatch(_setIsLoadingTrue());
            let relativeUrl = APIURL.RENDER_OYOLIFE_DEVICE_DETAILS_URL + "?userId=" + userId + "&hotelType=OYO-VACATION-HOME";
            let response = await RESTApi.GET(APIURL.SWITCH_BASE_URL, relativeUrl, AuthenticatedDefaultHeaders());
            logger.log(response);
            if(response.status===200) {
                dispatch(_setDeviceData(response.data));
                dispatch(_setIsLoadingFalse());
            }
        }
        catch(error) {
            alert("Unable to fetch device details");
            dispatch(_setIsLoadingFalse());
        }
    }
}

export const updateUserMacAddress = (payload) => {
    return async function (dispatch) {
        try {
            dispatch(clearResponse());
            dispatch(_setIsLoadingTrue());
            let response = await RESTApi.POST(
                APIURL.SWITCH_BASE_URL,
                APIURL.SWITCH_UPDATE_USER_MAC_ADDRESS,
                payload,
                AuthenticatedDefaultHeaders()
            );
            if (response.status === 200) {
                dispatch(setResponse(response.data));
                window.location.reload();
            }
            dispatch(_setIsLoadingFalse());
        } catch (error) {
            if (error.response && error.response.status === 400) {
                dispatch(setResponse(error.response.data));
            } else {
                alert("Unable to Add MAC Address at the moment");
            }
            dispatch(_setIsLoadingFalse());
        }
    };
};