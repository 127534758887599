import React from 'react';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import DatePicker from 'react-date-picker';
import DateParsingUtil from '../../utils/DateParsingUtil';
import logger from "utils/logger"

class GenStaffCode extends React.Component {
  constructor(props) {
    super(props);

    let startTime = new Date();
    startTime.setHours(0);
    startTime.setMinutes(0);
    startTime.setSeconds(0);
    startTime.setMilliseconds(0);

    let endTime = new Date();
    endTime.setDate(new Date().getDate() + 30 * 6);
    endTime.setHours(0);
    endTime.setMinutes(0);
    endTime.setSeconds(0);
    endTime.setMilliseconds(0);

    this.state = {
      validFrom: startTime,
      validUpto: endTime
    };
  }

  componentDidMount() {
    this.props.clearResponse();
    // handleSubmit();
  }

  changeStartDate = (date) => {
    const { validUpto } = this.state;

    if (date !== null) {
      if (validUpto !== '' && validUpto < date) {
        alert(
          'Valid Upto Date cannot be earlier than ValidFrom Date. Please enter the correct date'
        );
      } else {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        this.setState({ validFrom: date });
      }
    } else {
      this.setState({ validFrom: '' });
    }
  };

  changeEndDate = (date) => {
    const { validFrom } = this.state;

    if (date !== null) {
      if (validFrom !== '' && validFrom > date) {
        alert('Valid From Date cannot be after Valid Upto Date. Please enter the correct date');
      } else {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        this.setState({ validUpto: date });
      }
    } else {
      this.setState({ validUpto: '' });
    }
  };

  handleSubmit = () => {
    const { validFrom, validUpto } = this.state;

    let startTime = DateParsingUtil.changeStandardToEpoch(validFrom);
    logger.log('Start Time is ', startTime);
    let endTime = DateParsingUtil.changeStandardToEpoch(validUpto);
    logger.log('End Time is ', endTime);

    let payload = {
      property_id: this.props.propertyId,
      room_no: this.props.roomNo,
      start_time: startTime,
      end_time: endTime,
      user_id: this.props.userId,
      internal_type: 'staff'
    };
    this.props.genStaffCode(payload);
  };

  render() {
    return (
      <>
        <div className="activityLayout">
          <div className="activity-heading">
            <div className="activity-label">
              Generate PSP(Staff) Code
              <button className="close-button" onClick={this.props.close}>
                <i className="fa fa-close" />
              </button>
            </div>
          </div>

          <div>
            <div>
              <div className="form-label">
                Select Start Date:
                <DatePicker
                  onChange={this.changeStartDate}
                  value={this.validFrom}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  required
                />
              </div>
              <div className="form-label">
                Select End Date:
                <DatePicker
                  onChange={this.changeEndDate}
                  value={this.state.validUpto}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  required
                />
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="submit-button"
            onClick={() => {
              this.handleSubmit(this.props.propId, this.props.roomNo);
            }}
          >
            Submit
          </button>

          <div>
            {this.props.response ? (
              <div>
                {this.props.response.success ? (
                  <>
                    <p style={{ color: 'green' }}>
                      {this.props.response.message != null &&
                      this.props.response.message.includes('409')
                        ? 'Code Already Exists'
                        : this.props.response.message}
                    </p>
                    <p style={{ color: 'green' }}>
                      {this.props.response.code != null
                        ? 'Code : ' + this.props.response.code
                        : null}
                    </p>{' '}
                  </>
                ) : (
                  <p style={{ color: 'red' }}>{this.props.response.message}</p>
                )}
              </div>
            ) : (
              <div></div>
            )}
            {this.props.isLoading ? <div className="loader"></div> : <div></div>}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    response: state.smartlockButtons.response,
    isLoading: state.smartlockButtons.isLoading
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    genStaffCode: async (payload) => await dispatch(actions.genStaffCode(payload)),
    clearResponse: async () => dispatch(actions.clearResponse())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GenStaffCode);
