
import DateParsingUtil from '../utils/DateParsingUtil';
// import moment from 'moment-timezone';

export const allTimeZones = [
    {
        value : DateParsingUtil.getMinutesToAddforUTC("-12:00"),
        text : "(GMT -12:00) Eniwetok, Kwajalein"
    },
    {
        value : DateParsingUtil.getMinutesToAddforUTC("-11:00"),
        text : "(GMT -11:00) Midway Island, Samoa"
    },
    {
        value : DateParsingUtil.getMinutesToAddforUTC("-10:00"),
        text : "(GMT -10:00) Hawaii"
    },
    {
        value : DateParsingUtil.getMinutesToAddforUTC("-09:30"),
        text : "(GMT -9:30) Taiohae"
    },
    {
        value : DateParsingUtil.getMinutesToAddforUTC("-08:00"),
        text : "(GMT -8:00) Pacific Time (US &amp; Canada)"
    },
    {
        value : DateParsingUtil.getMinutesToAddforUTC("-07:00"),
        text : "(GMT -7:00) Mountain Time (US &amp; Canada)"
    },
    {
        value : DateParsingUtil.getMinutesToAddforUTC("-06:00"),
        text : "(GMT -6:00) Central Time (US &amp; Canada), Mexico City"
    },
    {
        value : DateParsingUtil.getMinutesToAddforUTC("-03:00"),
        text : "(GMT -3:00) Brazil, Buenos Aires, Georgetown"
    },
    {
        value : DateParsingUtil.getMinutesToAddforUTC("+00:00"),
        text : "(GMT) Western Europe Time, London, Lisbon, Casablanca"
    },
    {
        value : DateParsingUtil.getMinutesToAddforUTC("+01:00"),
        text : "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris"
    },
    {
        value : DateParsingUtil.getMinutesToAddforUTC("+02:00"),
        text : "(GMT +2:00) Kaliningrad, South Africa"
    },
    {
        value : DateParsingUtil.getMinutesToAddforUTC("+03:00"),
        text : "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg"
    },
    {
        value : DateParsingUtil.getMinutesToAddforUTC("+03:30"),
        text : "(GMT +3:30) Tehran"
    },
    {
        value : DateParsingUtil.getMinutesToAddforUTC("+04:00"),
        text : "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi"
    },
    {
        value : DateParsingUtil.getMinutesToAddforUTC("+05:30"),
        text : "(GMT +5:30) India, Kolkata"
    },
    {
        value : DateParsingUtil.getMinutesToAddforUTC("+09:00"),
        text : "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk"
    }

    

];

// export const getAlltimeZones = () => {

//     const tzNames = moment.tz.names();
//     logger.log(JSON.stringify(tzNames));
//     logger.log("Total names are : ", tzNames.length);

//     const map = new Map();

//     for (const name of tzNames) {
//         const offsets = moment.tz.zone(name).offsets;
//         logger.log("offset for " + name + " is : ", offsets);
        
//         for (const offset of offsets) {
//             if (!map.has(offset)) {
//                 map.set(offset, new Set());
//             }
      
//             map.get(offset).add(name);
//             // map.push({
//             //     offset : offset,
//             //     name : name
//             // });
//         }
//     }

//     logger.log("Total Diff Timezones are: ", map.size);
//     let count = 0;

//     Array.from(map).forEach(([key, value]) => {

//         logger.log("key = ", key);
//         logger.log("val = ", value);
//         count += value.size;
//     })

//     logger.log("count = ", count);
// }

                            // <option value="-12:00">(GMT -12:00) Eniwetok, Kwajalein</option>
	                        // <option value="-11:00">(GMT -11:00) Midway Island, Samoa</option>
                            // <option value="-10:00">(GMT -10:00) Hawaii</option>
                            // <option value="-09:30">(GMT -9:30) Taiohae</option>
                            // <option value="-09:00">(GMT -9:00) Alaska</option>
                            // <option value="-08:00">(GMT -8:00) Pacific Time (US &amp; Canada)</option>
                            // <option value="-07:00">(GMT -7:00) Mountain Time (US &amp; Canada)</option>
                            // <option value="-06:00">(GMT -6:00) Central Time (US &amp; Canada), Mexico City</option>
                            // <option value="-05:00">(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</option>
                            // <option value="-04:30">(GMT -4:30) Caracas</option>
                            // <option value="-04:00">(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</option>
                            // <option value="-03:30">(GMT -3:30) Newfoundland</option>
                            // <option value="-03:00">(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
                            // <option value="-02:00">(GMT -2:00) Mid-Atlantic</option>
                            // <option value="-01:00">(GMT -1:00) Azores, Cape Verde Islands</option>
                            // <option value="+00:00" selected="selected">(GMT) Western Europe Time, London, Lisbon, Casablanca</option>
                            // <option value="+01:00">(GMT +1:00) Brussels, Copenhagen, Madrid, Paris</option>
                            // <option value="+02:00">(GMT +2:00) Kaliningrad, South Africa</option>
                            // <option value="+03:00">(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
                            // <option value="+03:30">(GMT +3:30) Tehran</option>
                            // <option value="+04:00">(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
                            // <option value="+04:30">(GMT +4:30) Kabul</option>
                            // <option value="+05:00">(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</option>
                            // <option value="+05:30">(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</option>
                            // <option value="+05:45">(GMT +5:45) Kathmandu, Pokhara</option>
                            // <option value="+06:00">(GMT +6:00) Almaty, Dhaka, Colombo</option>
                            // <option value="+06:30">(GMT +6:30) Yangon, Mandalay</option>
                            // <option value="+07:00">(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
                            // <option value="+08:00">(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
                            // <option value="+08:45">(GMT +8:45) Eucla</option>
                            // <option value="+09:00">(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
                            // <option value="+09:30">(GMT +9:30) Adelaide, Darwin</option>
                            // <option value="+10:00">(GMT +10:00) Eastern Australia, Guam, Vladivostok</option>
                            // <option value="+10:30">(GMT +10:30) Lord Howe Island</option>
                            // <option value="+11:00">(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
                            // <option value="+11:30">(GMT +11:30) Norfolk Island</option>
                            // <option value="+12:00">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
                            // <option value="+12:45">(GMT +12:45) Chatham Islands</option>
                            // <option value="+13:00">(GMT +13:00) Apia, Nukualofa</option>
                            // <option value="+14:00">(GMT +14:00) Line Islands, Tokelau</option>  