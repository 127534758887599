import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import * as loginActions from '../../Login/redux/actions';
import TopBar from '../../../CommonUI/TopBar/topBar';
import Menubar from '../_utilComponents/MenuBar/menubar';
import urlConstant from '../../../Constants/urlConstant';
import ErrorPage from '../../ErrorPage/error-page';
import MortiseLock from '../MortiseLock/mortise-lock';
import UKHotelDetails from '../UKHotelDetails/ukHotelDetails';

class Homepage extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <header>
            <TopBar />
          </header>
          <nav>
            <Menubar />
          </nav>
        </div>
        {/* Routes for Smartlock Product */}
        <React.Fragment>
          <Switch>
            <Route path={urlConstant.routeURL.URL_NOT_FOUND} component={ErrorPage} />
            <Route path={urlConstant.routeURL.URL_SMARTLOCK_MORTISE_LOCK} component={MortiseLock} />
            <Route path={urlConstant.routeURL.URL_SMARTLOCK_UK_HOTELS} component={UKHotelDetails} />
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(loginActions.logoutUser())
  };
};
export default connect(mapDispatchToProps)(Homepage);
