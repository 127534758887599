export const hotelDetails = {
    isLoading: false,
    selectedHotel : {
        hotelName : "",
        hotelId : ""
    },
    hotelData : {
        roomList: [],
        deviceType: "",
        hotelId : ""
    },
    bulkDurationsAndReasons : {
        allowedDurations : [],
        allowedReasons : {}
    },
    bulkCheckInResponse: {},
    activityReasons: {
        reasons: {}
    },
    v2ActivityResponse : {},
    v1ActivityResponse : {
        staffEntry : "",
        offlineCodes : {},
        checkIn : {}
    }
}