export const decommHotel = {
    isLoading: false,
    hotelList: [],
    decommHotelList: [],
    hotelData : {
        roomList: [],
        deviceType: "",
        hotelId: ""
    },
    selectedHotel : {
        hotelName : "",
        hotelId : "",
        oyoId : ""
    },
    seelctedCity : "",
    recommRoomsResponse : "",
    decommRoomsResponse : ""
}