import * as actions from "./actionTypes";
import intialState from "./state";
import {checkInListProcessing, hotelListProcessing, roomListProcessing} from '../DataProcessing';

const reducer = (state = intialState, action) => {
    switch (action.type) {
        case actions.FETCH_HOTEL_LIST:
            return {
                ...state,
                hotel: [],
                rooms: [],
            };
        case actions.HOTEL_LIST_FETCHED:
            return {
                ...state,
                hotel: hotelListProcessing(action.payload),
                dataFetched: true
            };
        case actions.ERROR_HOTEL_LIST_FETCH:
            return {
                ...state,
                error: true,
            };
        case actions.FETCH_HOTEL_DETAILS:
            return {
                ...state,
                checkins: [],
                rooms: [],
                loading: true,
                error: false
            };
        case actions.HOTEL_DETAILS_FETCHED:
            return {
                ...state,
                checkins: checkInListProcessing(action.payload),
                rooms: roomListProcessing(action.payload),
                loading: false,
                error: false
            };
        case actions.ERROR_HOTEL_DETAILS_FETCH:
            return {
                ...state,
                checkins: [],
                loading: false,
                error: true
            };
        case actions.SMARTROOM_NEW_CHECKIN:
            return {
                ...state,
                loading: true,
                checkInOut: false,
                error: false
            };
        case actions.SMARTROOM_NEW_CHECKIN_SUCCESS:
            return {
                ...state,
                loading: false,
                checkInOut: true,
                error: false
            };
        case actions.ERROR_SMARTROOM_NEW_CHECKIN:
            return {
                ...state,
                loading: false,
                error: true,
                checkInOut: false
            };
        case actions.SMARTROOM_CHECKOUT:
            return {
                ...state,
                loading: true,
                checkInOut: false,
                error: false
            };
        case actions.SMARTROOM_CHECKOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                checkInOut: true,
                error: false
            };
        case actions.ERROR_SMARTROOM_CHECKOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: true,
                checkInOut: false
            };
        default:
            return state;
    }
};

export default reducer;
