import RESTApi from '../../../../utils/RESTApi';
import { APIURL } from '../../../../Constants/urlConstant';
import * as actionType from './actionTypes';
import { AuthenticatedDefaultHeaders } from '../../../../Constants/HeadersConstants';
import logger from 'utils/logger';

const _setIsLoadingTrue = () => {
  return {
    type: actionType.SET_INSTALLATION_IS_LOADING_TRUE
  };
};
const _setIsLoadingFalse = () => {
  return {
    type: actionType.SET_INSTALLATION_IS_LOADING_FALSE
  };
};
const _clearRoomList = () => {
  return {
    type: actionType.CLEAR_INSTALLATION_ROOM_LIST
  };
};

export const getRoomList = (payload) => {
  return {
    type: actionType.LOAD_INSTALLATION_ROOM_LIST_SUCCESS,
    payload: payload
  };
};
export const getRooms = (hotelId) => {
  return async function (dispatch) {
    try {
      await dispatch(_setIsLoadingTrue());
      await dispatch(_clearRoomList());
      let relativeUrl = APIURL.SWITCH_GET_ROOMS + '?hotel-id=' + hotelId;
      let response = await RESTApi.GET(
        APIURL.SWITCH_BASE_URL,
        relativeUrl,
        AuthenticatedDefaultHeaders()
      );
      logger.log(response);
      if (response.status === 200) {
        await dispatch(getRoomList(response.data));
        dispatch(_setIsLoadingFalse());
      }
    } catch (error) {
      // logger.log(error);
        alert('Error loading list of rooms');
        dispatch(_setIsLoadingFalse());
    }
  };
};

const _setSelectedHotel = (payload) => {
  return {
    type: actionType.SET_INSTALLATION_SELECTED_HOTEL,
    payload: payload
  };
};
export const setSelectedHotel = (payload) => {
  return async function (dispatch) {
    dispatch(_setSelectedHotel(payload));
  };
};
