import { decrypt } from '../../../utils/EncryptDecryptUtil';
import { Constants } from '../../../Constants/Constants';
import ViewCode from './ViewCode';
import Popup from 'reactjs-popup';
import DeleteCode from './../../../CommonUI/SmartlockButtons/deleteCode';
import GenNewCode from './../../../CommonUI/SmartlockButtons/genNewCode';
import DateParsingUtil from '../../../utils/DateParsingUtil';

let profileID = decrypt(localStorage.getItem(Constants.localStorageKeys.USER_PROFILE_ID));

export const COLUMNS_HOTEL_DETAILS = [
  {
    Header: 'Property name',
    accessor: 'property_label'
  },
  {
    Header: 'Property Code',
    accessor: 'property_id'
  },
  {
    Header: 'Lock ID',
    accessor: 'device_id'
  },
  {
    Header: 'Booking ID',
    accessor: 'booking_id'
  },
  {
    Header: 'Property Type',
    accessor: 'property_type'
  },
  {
    Header: 'Last Sync Time',
    accessor: (value) => value,
    Cell: ({ value }) => (
      <div>
        {value.updated_at !== undefined
          ? DateParsingUtil.changeEpochToStandard(value.updated_at).toString().slice(0, -34)
          : 'NOT AVAILABLE'}
      </div>
    )
  },
  {
    Header: 'Delete Code',
    accessor: (value) => value,
    Cell: ({ value }) => (
      <Popup
        trigger={
          <button className="ml-5 flex-wrap text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
            Delete Code
          </button>
        }
        modal
      >
        {(close) => <DeleteCode close={close} propId={value.property_id} userId={profileID} />}
      </Popup>
    )
  },
  {
    Header: 'Generate Code',
    accessor: (value) => value,
    Cell: ({ value }) => (
      <Popup
        trigger={
          <button className="ml-5 flex-wrap text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
            Generate New Code
          </button>
        }
        modal
      >
        {(close) => (
          <GenNewCode
            close={close}
            propId={value.property_id}
            userId={profileID}
            timeZoneOffset={value.time_zone_offset}
          />
        )}
      </Popup>
    )
  },
  {
    Header: 'View Code',
    accessor: (value) => value,
    Cell: ({ value }) => (
      <div>
        <ViewCode bookingID={value?.booking_id} />
      </div>
    )
  }
];
