import React, {Component} from 'react';
import {connect} from 'react-redux';
import SearchBar from '../_utilComponents/SearchBar/searchBar';
import * as actions from './redux/actions';
import './bleAppPassword.css';
import logger from "utils/logger"

class GetBLEPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error : true,
            success : false,
            errorMessage : "",
            username : "",
            password : "",
            oyoId : ""
        }
    }
    componentDidMount(){
        if(this.props.passwordData && this.props.selectedHotel) {
            if(this.props.passwordData!==null && this.props.passwordData.success === true) {
                this.setState({
                    error: false,
                    success: true,
                    username : this.props.selectedHotel.oyoId+'@oyoproperties.com',
                    password : this.props.passwordData.message,
                    oyoId: this.props.selectedHotel.oyoId
                })
            }
            else {
                this.setState({
                    error: false,
                    success: false,
                    errorMessage: "Please commission hotel to get the password"
                })
            }
        }
    }
    handleChange = async (selectedOption) => {
        if(selectedOption===null) {
            logger.log("Field has been cleared")
        } else {
            if(selectedOption.value!==null && selectedOption.value!==undefined) {
                await this.props.getPassword(selectedOption.value.hotelId)
                // logger.log("logging after: ", this.props.passwordData.message)
                if(this.props.passwordData!==null && this.props.passwordData.success === true) {
                    this.setState({
                        error: false,
                        success: true,
                        username : selectedOption.value.oyoId+'@oyoproperties.com',
                        password : this.props.passwordData.message,
                        oyoId: selectedOption.value.oyoId
                    })
                }
                else {
                    this.setState({
                        error: false,
                        success: false,
                        errorMessage: "Please commission hotel to get the password"
                    })
                }
            }
        
            let selectedHotel = {
                hotelId : selectedOption.value.hotelId,
                oyoId : selectedOption.value.oyoId, 
                hotelName : selectedOption.label
            }
            this.props.setSelectedHotelRedux(selectedHotel);
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="search-container">
                    <SearchBar handleChange={this.handleChange}/>
                </div>
                <br></br>
                <br></br>
                {
                    this.props.isLoading ? 
                    <div><br></br><div className="loader"></div></div>
                    :
                    <div></div>
                }
                { this.props.passwordData && !this.props.isLoading ? 
                    this.state.success ? 
                    <div className="password-details">
                        <div className="password-content">
                            <span>Username: </span>
                            <div>
                                <b>{this.state.username}</b> 
                            </div>
                        </div>

                        <div className="password-content">
                            <span>Password: </span>
                            <span>
                                <b>{this.state.password}</b>
                            </span>
                        </div>

                        <div className="password-content">
                            <span>OYO ID: </span>
                            <div>
                                <b>{this.state.oyoId}</b>
                            </div>
                        </div>
                    </div> 
                    :
                    <div className="password-details-error"> <b>{this.state.errorMessage}</b> </div>
                    :
                    <div></div>
                }
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        hotelData : state.hotel.onLoadHotelData,
        isLoading : state.hotel.isLoading,
        roomList : state.hotel.hotelData.roomList,
        passwordData : state.bleApp.passwordData,
        selectedHotel : state.bleApp.selectedHotel
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getHotelList : async () => await dispatch(actions.getHotels()),
        getPassword : async (hotelId) => await dispatch(actions.getBlePassword(hotelId)),
        setSelectedHotelRedux : async (payload) => await dispatch(actions.setSelectedHotelRedux(payload))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(GetBLEPassword);