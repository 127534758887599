import React, {Component} from 'react';
import ReactPlayer from 'react-player';
import {Constants} from '../../../Constants/Constants';
import './supportVideos.css';

class SupportVideos extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="search-container">
                    <h1 className="decomm_heading">
                        Support videos
                    </h1>
                    <div className="flex-row-container">
                        <div>
                            <h3>Installation and configuration</h3>
                            <ReactPlayer url={Constants.videoLink.INSTALLATION_AND_CONFIGURATION}
                                className = "video"
                                playing={true}
                                controls={true}
                                light={true}
                                />
                        </div>

                        <div>
                            <h3>Switch V2 Presentation</h3>
                            <ReactPlayer url={Constants.videoLink.SWITCH_V2_PRESENTATION}
                                className = "video"
                                playing={true}
                                controls={true}
                                light={true}
                                />
                        </div>

                        <div>
                            <h3>Switch Installation Guide</h3>
                            <ReactPlayer url={Constants.videoLink.SWITCH_INSTALLATION_GUIDE}
                                className = "video"
                                playing={true}
                                controls={true}
                                light={true}
                                />
                        </div>

                        <div>
                            <h3>Staff Entry Working</h3>
                            <ReactPlayer url={Constants.videoLink.STAFF_ENTRY_WORKING}
                                className = "video"
                                playing={true}
                                controls={true}
                                light={true}
                                />
                        </div>

                        <div>
                            <h3>Test Mapping Intranet</h3>
                            <ReactPlayer url={Constants.videoLink.TEST_MAPPING_INTRANET}
                                className = "video"
                                playing={true}
                                controls={true}
                                light={true}
                                />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default SupportVideos;