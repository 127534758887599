import React, { Component } from 'react';
import CheckBox from '../../_utilComponents/Checkboxes/Checkboxes';
import { connect } from 'react-redux';
import {getHotels} from '../../_utilComponents/SearchBar/redux/actions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Popup from 'reactjs-popup';
import {Constants} from '../../../../Constants/Constants';
import * as actions from '../redux/actions';
import './decommByCity.css';
import logger from "utils/logger"

let arrowMap = new Map(
    [
        ['city', '']
    ]
);

class DecommByCity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hotelList : [],
            checkedAll : false,
            checkedItems : new Map(),
            reason : ""
        }
    }

    async componentDidMount (){
        await this.props.getHotels();
        let oyoIdList = this.props.hotelList.map(hotel => {
            return hotel.oyo_id.slice(0, 3);
        })
        let uniqueList = [...new Set(oyoIdList)];
        await this.setState({
            hotelList : uniqueList,
            checkedItems : new Map(),
            checkedAll : false
        })
        this.props.clearResponse();
    }

    sortBy = async (key) => {
        let i;
        let copyArray = [];
        let len = this.state.hotelList.length;
        for(i=0;i<len;i++) {
            copyArray[i] = this.state.hotelList[i];
        }
        logger.log("Before: ", copyArray)
        let arrayKey = Array.from(arrowMap.keys());
        if (key === arrayKey[arrayKey.indexOf(key)]) {
            if (arrowMap.get(key) === '') {
                arrowMap.set(key, true);
                copyArray.sort();
            } else if (arrowMap.get(key) === true) {
                arrowMap.set(key, false);
                copyArray.sort();
                copyArray.reverse();
            } else if (arrowMap.get(key) === false) {
                arrowMap.set(key, true);
                copyArray.sort();
            }
            await this.setState({
                hotelList : copyArray
            })
        }
    };

    arrow = (keyValue) => (
        arrowMap.get(keyValue)
            ? <FontAwesomeIcon icon="sort-alpha-down" style={{color: 'grey'}} className="arrow-sort"/>
            : <FontAwesomeIcon icon="sort-alpha-up" style={{color: 'grey'}} className="arrow-sort"/>
    )

    setCheckedAll = () => {
        let valueArray = this.state.checkedItems.values();
        let ele, count=0, flag=0;
        for(ele of valueArray){
            if(ele===false) {
                flag = 1;
                break;
            }
            else {
                count++;
            }
        }

        if(flag===1 && this.state.checkedAll===true) {
            this.setState({
                checkedAll: false,
            })
        }
        else if(flag===0 && this.state.checkedAll===false && count===this.state.hotelList.length) {
            this.setState({
                checkedAll: true,
            })
        }
    }
    handleHeadChange = () => {
        let val;
        let i;
        let newCheckedItems = new Map();
        if(!this.state.checkedAll && this.state.hotelList.length!==0) {
            let len = this.state.hotelList.length;
            for(i=0;i<len;i++) {
                newCheckedItems.set(this.state.hotelList[i], true)
            }
            val = true;
        } else {
            val = false;
        }
        logger.log(newCheckedItems);
        this.setState ({
            checkedItems: newCheckedItems,
            checkedAll: val
        })
    }
    handleRowClick = async (city) => {
        const item = city;
        let isChecked = this.state.checkedItems.get(city);
        // if(!isChecked.contains()||isChecked===false) {
        if(isChecked===undefined||isChecked===false) {
            isChecked = true;
        }
        else if(isChecked) {
            isChecked = false;
            if(this.state.checkedAll===true) {
                await this.setState({
                    checkedAll : false
                })
            }
        }
        if(item!==undefined && item!==null) {
            await this.setState(prevState=>
                ({checkedItems: prevState.checkedItems.set(item, isChecked)}))
        }
        this.setCheckedAll();
    }

    setReason = (e) => {
        if(e.target.value!==null || e.target.value!==undefined) {
            this.setState({
                reason : e.target.value
            })
        }
    }

    handleSubmit = async (type, close) => {
        if(this.state.reason==="") {
            alert('Please enter reason to proceed');
            return;
        }
        let arr=[];
        if(this.state.checkedItems.length!==0) {
            this.state.checkedItems.forEach((value, key) => {
                if(value === true) {
                    arr.push(key);
                }
            })
        } 
        this.props.clearResponse();
        close();
        if(type===Constants.decommConstants.DECOMM) {
            let body = {
                "allProperties":false,
                "oyoIds":[],
                "cities":arr,
                "reason": this.state.reason,
                "requester": this.state.email
            }
            await this.props.decommission(body);
        }
        else {
            let body = {
                "allProperties":false,
                "oyoIds":[],
                "cities":arr,
                "reason": this.state.reason,
                "requester": this.state.email
            }
            await this.props.recommission(body);
        }
        this.setState({
            checkedItems : new Map(),
            checkedAll : false,
            reason : ""
        })
    }

    render() {
        let arr=[];
        if(this.state.checkedItems.length!==0) {
            this.state.checkedItems.forEach((value, key) => {
                if(value === true) {
                    arr.push(key);
                }
            })
        } 
        logger.log(arr);
        return (
            this.state.hotelList ?
            <React.Fragment>
            <div className="search-container decommission-city">
                <div >
                    <h1 className="decomm_heading">Decommission By City</h1>
                </div>
                {this.props.recommResponse!=="" ? 
                 <div className="global-decomm-response" style={{color: "green"}}>{this.props.recommResponse}</div>
                 :
                 <div></div>}
                {this.props.decommResponse!=="" ? 
                 <div className="global-decomm-response" style={{color: "green"}}>{this.props.decommResponse}</div>
                 :
                 <div></div>}
                {this.props.isLoading ? <div className="loader"></div> : <div></div>}
                <div className="decommission-container ">
                    <Popup trigger={
                        <button className="refresh-button">
                            Decommission Cities
                        </button>} modal>
                        {close => (
                            <div className="activityLayout">
                                <div className="activity-heading">
                                    <div className="activity-label">Decommission
                                        <button className="close-button" onClick={close}>
                                            <i className="fa fa-close"/>
                                        </button>
                                    </div>
                                </div>
                                <div>
                                {arr.length===0 ? 
                                        <div className="selected-error">Select city to decommission</div>
                                        :
                                        <React.Fragment>
                                            <div className="selected">
                                                List of cities to be decommissioned : 
                                                {JSON.stringify(arr).slice(1,-1)}
                                            </div>
                                            <div>
                                                If you are sure you want to decommission, enter reason:
                                                <div>
                                                <input type= "text" placeholder="Enter reason" className="text-box"
                                                    value={this.state.reason} onChange={(e)=>{this.setReason(e)}}>
                                                </input>
                                                </div>
                                                <div>
                                                    <button className="submit-button-decomm" onClick={()=>{this.handleSubmit(Constants.decommConstants.DECOMM, close)}}>
                                                        Decommission
                                                    </button>
                                                    <button className="cancel-button-decomm" onClick={close}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        )}
                    </Popup>
                    <Popup trigger={
                        <button className="refresh-button">
                            Recommission Cities
                        </button>} modal>
                        {close => (
                            <div className="activityLayout">
                                <div className="activity-heading">
                                    <div className="activity-label">Recommission
                                        <button className="close-button" onClick={close}>
                                            <i className="fa fa-close"/>
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    {arr.length===0 ? 
                                        <div className="selected-error">Select city to recommission</div>
                                        :
                                        <React.Fragment>
                                            <div className="selected">
                                                List of cities to be recommissioned : 
                                                {JSON.stringify(arr).slice(1,-1)}
                                            </div>
                                            <div>
                                                If you are sure you want to recommission, enter reason:
                                                <div>
                                                <input type= "text" placeholder="Enter reason" className="text-box"
                                                    value={this.state.reason} onChange={(e)=>{this.setReason(e)}}>
                                                </input>
                                                </div>
                                                <div>
                                                    <button className="submit-button-decomm" onClick={()=>{this.handleSubmit(Constants.decommConstants.RECOMM, close)}}>
                                                        Recommission
                                                    </button>
                                                    <button className="cancel-button-decomm" onClick={close}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        )}
                    </Popup>
                    <div className="tableAlign">
                        <table className="hotel-Admin-layout">
                            <thead className="tableHead">
                                <tr>
                                    <td className="table-heading">
                                        <CheckBox name="All" onChange={this.handleHeadChange} checked={this.state.checkedAll}/>
                                    </td>
                                    <td className="table-heading" onClick={()=>{this.sortBy('city')}}>City{this.arrow('city')}</td>
                                </tr>
                            </thead>
                            <tbody className="body-layout">
                                {
                                    this.state.hotelList.map(city => {
                                        return (
                                            <tr className="trColumn" key={city} onClick={()=>{this.handleRowClick(city)}}>
                                                <td className="tableColumn" width="10%"><CheckBox name={city} checked={this.state.checkedItems.get(city)}/></td>
                                                <td className="tableColumn">{city}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            </React.Fragment>
            :
            <React.Fragment>
                <div className="search-container">
                <div >
                    <h1 className="decomm_heading" style={{'color':'red'}}>
                        Unable to get the list of cities for decommissioning
                    </h1>
                </div>
                </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        hotelList : state.onLoad.onLoadHotelData,
        email : state.login.email,
        isLoading : state.decomm.isLoading,
        recommResponse : state.decomm.recommRoomsResponse,
        decommResponse : state.decomm.decommRoomsResponse
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getHotels : async() => dispatch(getHotels()),
        decommission : async(payload) => dispatch(actions.decommissionHotels(payload)),
        recommission : async(payload) => dispatch(actions.recommissionHotels(payload)),
        clearResponse : async () => dispatch(actions.clearDecommRecommResponse())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DecommByCity);