import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../redux/actions';
import Checkbox from '../../_utilComponents/Checkboxes/Checkboxes';
import './bulkCheckIn.css';

class Bulk extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedItems: new Map(),
            duration: "",
            reason: "",
            bookingId: "",
            response: "",
            success: false,
            color: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        this.props.clearBulkCheckInResponse();
        await this.props.getBulkDurationsAndReasons();
        let reasonArray = Object.values(this.props.bulkReasons);

        if ((reasonArray[0] !== undefined) && (this.props.bulkDuration[0] !== undefined)) {
            this.setState({
                ...this.state,
                duration: this.props.bulkDuration[0],
                reason: reasonArray[0]
            })
        }
    }

    handleChange(e) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        if (item !== undefined && item !== null)
            this.setState(prevState => ({checkedItems: prevState.checkedItems.set(item, isChecked)}));
    };

    handleDuration = (e) => {
        this.setState({
            ...this.state,
            duration: e.target.value
        })
    };
    handleBookingId = (e) => {
        this.setState({
            ...this.state,
            bookingId: e.target.value
        })
    };
    handleReason = (e) => {
        this.setState({
            ...this.state,
            reason: e.target.value
        })
    };
    handleSubmit = async (e) => {
        e.preventDefault();
        let selectedRooms = [...this.state.checkedItems.keys()];
        if (selectedRooms.length !== 0) {
            let arr=[];
            this.state.checkedItems.forEach((value, key) => {
                if(value === true) {
                    arr.push(key);
                }
            })
            if(arr.length===0) {
                alert("Please select at least one room");
            }
            else {
                let reasonId = Object.keys(this.props.bulkReasons).find(key => this.props.bulkReasons[key] === this.state.reason)
                let bulk_data = {
                    hotel_id: this.props.selectedHotel.hotelId,
                    booking_id: this.state.bookingId,
                    duration: this.state.duration,
                    reason: this.state.reason,
                    room_ids: arr,
                    reason_id: reasonId
                };

                await this.props.bulkCheckIn(bulk_data);
                this.responseReceived(this.props.bulkCheckInResponse)
            }
        } else {
            alert("Please select at least one room")
        }
    };
    responseReceived = (bulkData) => {
        let isSuccess = false;
        let roomId = [];
        bulkData.room_ids.map(room => {
            if (room.is_success === false) {
                roomId = roomId.concat(room.room_id);
                isSuccess = true;
            }
            return "";
        });
        if (isSuccess === true) {
            let reason = "Room " + roomId + " not available for bulk checkin";
            this.setState({
                success: false,
                response: reason,
                color: false
            })
        }
        if (isSuccess === false) {
            let reason = "Bulk Check In Successful";
            this.setState({
                success: true,
                response: reason,
                color: true
            })
        }
    };

    render() {
        // let checkedInValues = this.props.roomList.map(room => {
        //     return room.is_checked_in
        // });
        // logger.log(checkedInValues);
        // let checkedInValuesLen = checkedInValues.length;

        // let trueCheckedInValues = checkedInValues.map(value => {
        //     if (value === true) 
        //         return value
        //     return null;
        // });
        // logger.log(trueCheckedInValues);
        // trueCheckedInValues = trueCheckedInValues.filter(function (element) {
        //     return element !== null;
        // });
        // let trueValuesLen = trueCheckedInValues.length;
        // let bulkActivity = !(trueValuesLen === checkedInValuesLen && trueValuesLen !== 0);

        let notCheckedInRooms = this.props.roomList.filter(room => {
            if (room.is_checked_in === false) {
                return (room)
            }
            return null;
        });
        let bulkActivity = (notCheckedInRooms.length>0);
        let checkBoxList = notCheckedInRooms.map(room => {
            return (
                <label key={room.room_id} className="room-checkbox">
                    Room {room.room_no}
                    <Checkbox name={room.room_no} checked={this.state.checkedItems.get(room.room_no)}
                              onChange={this.handleChange} />
                </label>
            )
        });
        return (
            <React.Fragment>
                {bulkActivity ?
                    this.props.selectedHotel.hotelId === "" ?
                        <div className="Error"> Please select a Hotel </div>
                        :
                        <div className="Bulk-CheckIn-Form">
                            <form className="activity-form" onSubmit={this.handleSubmit}>

                                <div className="select-rooms">{checkBoxList}</div>

                                <div className="form-input">
                                    <label className="form-label">Booking Id: </label>
                                    <input className = "text-box" type="text" placeholder="Enter booking ID"
                                            onChange={this.handleBookingId} required/>
                                </div>

                                <div className="form-input">
                                    <label className="form-label">Duration: </label>
                                    <select className="dropdown" onChange={this.handleDuration}>
                                        {this.props.bulkDuration.map(duration => {
                                            if(duration<60) {
                                                return (
                                                    <option>{duration} minutes</option>
                                                )
                                            }
                                            else {
                                                let hours = Math.floor(duration/60);
                                                let minutes = duration - (hours * 60);
                                                if(hours===1) {
                                                    hours = hours + " hour";
                                                } else {
                                                    hours = hours + " hours";
                                                }
                                                if(minutes===1) {
                                                    minutes = minutes + " minute"
                                                } else {
                                                    minutes = minutes + " minutes";
                                                }
                                                if(minutes === 0) {
                                                    return (
                                                        <option>{hours} {minutes}</option>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <option>{hours}</option>
                                                    )
                                                }
                                            }
                                        })
                                        }
                                    </select>
                                </div>

                                <div className="form-input">
                                    <label className="form-label">Reasons: </label>
                                    <select className="dropdown" onChange={this.handleReason}>
                                        {Object.values(this.props.bulkReasons).map(reason => {
                                            return (
                                                <option>{reason}</option>
                                            )
                                        })
                                        }
                                    </select>
                                </div>
                                <div>
                                    <button type="submit" className="submit-button-bulk-checkin">Turn Lights On
                                    </button>
                                    {this.props.isLoading ? <div className="bulk-loader"></div> : <span></span>}
                                </div>
                            </form>
                            <br/>
                            {this.state.success &&
                            <div>{this.state.color ?
                                <span style={{color: 'green'}}>{this.state.response}</span> :
                                <span style={{color: 'red'}}>{this.state.response}</span> }</div>}
                        </div>
                    : <div>All rooms already checked in</div>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        bulkDuration: state.hotel.bulkDurationsAndReasons.allowedDurations,
        bulkReasons: state.hotel.bulkDurationsAndReasons.allowedReasons,
        roomList: state.hotel.hotelData.roomList,
        bulkCheckInResponse: state.hotel.bulkCheckInResponse,
        selectedHotel: state.hotel.selectedHotel.hotelId,
        isLoading: state.hotel.isLoading
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBulkDurationsAndReasons: async () => await dispatch(actions.getBulkDurationsAndReasons()),
        bulkCheckIn: async (payload) => await dispatch(actions.postBulkCheckIn(payload)),
        clearBulkCheckInResponse: async () => await dispatch(actions.clearBulkResponse())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Bulk);