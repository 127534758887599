import React from 'react';
import { connect } from 'react-redux';
import { decrypt } from '../../utils/EncryptDecryptUtil';
import './topBar.css';
import { logoutUser } from '../../Components/Login/redux/actions';
import { Constants } from '../../Constants/Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class topbar extends React.Component {
  handleLogout = () => {
    this.props.logoutUser();
  };

  render() {
    let productName = decrypt(localStorage.getItem(Constants.localStorageKeys.PRODUCT));
    if (!productName) {
      productName = '';
    }

    return (
      <div className="top-bar">
        <div className="top-bar-logo-container">
          <div className="top-bar-logo"></div>
          <div className="top-bar-divider"></div>
          <div className="top-bar-text">{productName.toLocaleUpperCase()}</div>
        </div>
        <div className="user-dropdown">
          <button className="top-bar-userbutton">
            <span className="top-bar-username">
              {decrypt(localStorage.getItem(Constants.localStorageKeys.EMAIL))}
            </span>
            <div>
              <FontAwesomeIcon icon="user" className="top-bar-usericon" />
            </div>
          </button>
          <div className="user-content">
            <button onClick={this.handleLogout}>
              Logout
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    username: state.login.email
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(topbar);
