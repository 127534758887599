export const hotelListProcessing = (APIDataResponse) => {
    if(APIDataResponse === null || APIDataResponse.hotels === null) {
        return [];
    }
    let finalHotelList = [];
    APIDataResponse.hotels.forEach((hotel, i) => {
        if(hotel === null) {
            return;
        }
        finalHotelList.push({
            label: hotel.hotel_name,
            value: hotel.hotel_id
        })
    });
    return finalHotelList;
};

export const checkInListProcessing = (APIDataResponse) => {
    if(APIDataResponse === null || APIDataResponse.rooms === null) {
        return [];
    }
    let finalCheckInList = [];
    APIDataResponse.rooms.forEach((room, i) => {
        if(room === null) {
            return;
        }
        if(room.checkedIn) {
            finalCheckInList.push({
                name : ((room.guest_name === null) ? "Guest" : room.guest_name),
                room : room.room_no
            })
        }
    });

    return finalCheckInList;
};

export const roomListProcessing = (APIDataResponse) => {
    if(APIDataResponse === null || APIDataResponse.rooms === null) {
        return [];
    }
    let finalRoomList = [];
    APIDataResponse.rooms.forEach((room, i) => {
        if(room === null) {
            return;
        }
        if(room.checkedIn === null || !room.checkedIn) {
            finalRoomList.push({
                label: room.display_str,
                value: room.room_no
            })
        }
    });

    return finalRoomList;
};

