import RESTApi from "./../../../utils/RESTApi";
import { APIURL } from "./../../../Constants/urlConstant";
import { AuthenticatedSmartlockHeaders } from "./../../../Constants/HeadersConstants";
import * as actionType from "./actionTypes";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const _setIsLoadingTrue = () => {
  return {
    type: actionType.SET_IS_LOADING_TRUE,
  };
};

const _setIsLoadingFalse = () => {
  return {
    type: actionType.SET_IS_LOADING_FALSE,
  };
};

const _setUpdateLockLoading = (payload) => {
  return {
    type: actionType.UPDATE_LOCK_LOADING,
    payload: payload,
  };
};

export const clearResponse = () => {
  return {
    type: actionType.CLEAR_SMARTLOCK_BUTTON_RESPONSE,
  };
};
export const setResponse = (payload) => {
  return {
    type: actionType.SET_SMARTLOCK_BUTTON_RESPONSE,
    payload: payload,
  };
};
export const getBatteryStatus = (payload) => {
  return async function (dispatch) {
    let finalPayload = {
      device_id: payload,
    };
    try {
      dispatch(clearResponse());
      dispatch(_setIsLoadingTrue());
      let response = await RESTApi.POST(
        APIURL.OVH_BASE_URL,
        APIURL.OVH_BATTERY_STATUS,
        finalPayload,
        AuthenticatedSmartlockHeaders()
      );
      if (response.status === 200) {
        dispatch(setResponse(response.data));
        dispatch(_setIsLoadingFalse());
      }
    } catch (error) {
      alert("Unable to get battery status");
      dispatch(_setIsLoadingFalse());
    }
  };
};

export const genNewCode = (payload) => {
  return async function (dispatch) {
    try {
      dispatch(clearResponse());
      dispatch(_setIsLoadingTrue());
      let response = await RESTApi.POST(
        APIURL.OVH_BASE_URL,
        APIURL.OVH_GEN_NEW_CODE,
        payload,
        AuthenticatedSmartlockHeaders()
      );
      if (response.status === 200) {
        dispatch(setResponse(response.data));
      }
      dispatch(_setIsLoadingFalse());
    } catch (error) {
      if (error.response && error.response.status === 400) {
        dispatch(setResponse(error.response.data));
      } else {
        alert("Unable to generate new codes at the moment");
      }
      dispatch(_setIsLoadingFalse());
    }
  };
};

export const updateMasterPin = (payload) => {
  return async function (dispatch) {
    try {
      dispatch(clearResponse());
      dispatch(_setIsLoadingTrue());
      let response = await RESTApi.POST(
          APIURL.OVH_BASE_URL,
          APIURL.OVH_UPDATE_DEVICE_MASTER_PIN,
          payload,
          AuthenticatedSmartlockHeaders()
      );
      if (response.status === 200) {
        dispatch(setResponse(response.data));
        window.location.reload();
      }
      dispatch(_setIsLoadingFalse());
    } catch (error) {
      if (error.response && error.response.status === 400) {
        dispatch(setResponse(error.response.data));
      } else {
        alert("Unable to update master pin at the moment");
      }
      dispatch(_setIsLoadingFalse());
    }
  };
};

export const updateServicePartnerPin = (payload) => {
  return async function (dispatch) {
    try {
      dispatch(clearResponse());
      dispatch(_setIsLoadingTrue());
      let response = await RESTApi.POST(
          APIURL.OVH_BASE_URL,
          APIURL.OVH_UPDATE_DEVICE_SERVICE_PARTNER_PIN,
          payload,
          AuthenticatedSmartlockHeaders()
      );
      if (response.status === 200) {
        dispatch(setResponse(response.data));
        window.location.reload();
      }
      dispatch(_setIsLoadingFalse());
    } catch (error) {
      if (error.response && error.response.status === 400) {
        dispatch(setResponse(error.response.data));
      } else {
        alert("Unable to update service partner pin at the moment");
      }
      dispatch(_setIsLoadingFalse());
    }
  };
};

export const updateLastBatteryChangedDate = (payload) => {
  return async function (dispatch) {
    try {
      dispatch(clearResponse());
      dispatch(_setIsLoadingTrue());
      let response = await RESTApi.POST(
          APIURL.OVH_BASE_URL,
          APIURL.OVH_UPDATE_DEVICE_LAST_BATTERY_CHANGED_DATE,
          payload,
          AuthenticatedSmartlockHeaders()
      );
      if (response.status === 200) {
        dispatch(setResponse(response.data));
        window.location.reload();
      }
      dispatch(_setIsLoadingFalse());
    } catch (error) {
      if (error.response && error.response.status === 400) {
        dispatch(setResponse(error.response.data));
      } else {
        alert("Unable to update last battery changed date at the moment");
      }
      dispatch(_setIsLoadingFalse());
    }
  };
};


export const genStaffCode = (payload) => {
  return async function (dispatch) {
    try {
      dispatch(clearResponse());
      dispatch(_setIsLoadingTrue());
      let response = await RESTApi.POST(
        APIURL.OVH_BASE_URL,
        APIURL.OVH_GEN_STAFF_CODE,
        payload,
        AuthenticatedSmartlockHeaders()
      );
      if (response.status === 200) {
        dispatch(setResponse(response.data));
      }
      dispatch(_setIsLoadingFalse());
    } catch (error) {
      if (error && error.response && error.response.status === 400) {
        dispatch(setResponse(error.response.data));
      } else if (error && error.response && error.response.status === 409) {
        alert("Code Already exists at this property");
      } else {
        alert("Unable to generate staff codes at the moment");
      }
      dispatch(_setIsLoadingFalse());
    }
  };
};

export const viewCode = (payload) => {
  return async function (dispatch) {
    try {
      dispatch(clearResponse());
      dispatch(_setIsLoadingTrue());
      let response = await RESTApi.POST(
        APIURL.OVH_BASE_URL,
        APIURL.OVH_VIEW_CODE,
        payload,
        AuthenticatedSmartlockHeaders()
      );
      if (response.status === 200) {
        dispatch(setResponse(response.data));
        dispatch(_setIsLoadingFalse());
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        dispatch(setResponse(error.response.data));
      } else {
        alert("Unable to view codes at the moment");
      }
      dispatch(_setIsLoadingFalse());
    }
  };
};

export const updateLocks = (payload) => {
  return async function (dispatch) {
    try {
      dispatch(clearResponse());
      dispatch(_setUpdateLockLoading(false));
      let response = await RESTApi.POST(
        APIURL.OVH_BASE_URL,
        APIURL.OVH_UPDATE_LOCKS + "?updateDB=true&deviceType=IGLOO-KEY-BOX",
        payload,
        AuthenticatedSmartlockHeaders()
      );
      if (response.status === 200) {
        let successResponse = {
          success: true,
          res_code: 1,
        };
        toast.success("Locks Successfully synced !");
        dispatch(setResponse(successResponse));
      }
      if (response.status === 400) {
        let errorResponse = {
          success: false,
          res_code: 0,
        };
        toast.error("Some Error occured in lock syncing :" + response.data.message);
        dispatch(setResponse(errorResponse));
      }
      dispatch(_setUpdateLockLoading(false));
    } catch (error) {
      let gatewayTimeout = {
        success: true,
        res_code: 2,
      };
      toast.error("Some Error occurred in lock syncing, please Try again ");
      dispatch(setResponse(gatewayTimeout));
      dispatch(_setUpdateLockLoading(false));
    }
  };
};

export const deleteCode = (payload) => {
  return async function (dispatch) {
    try {
      dispatch(clearResponse());
      let response = await RESTApi.POST(
        APIURL.OVH_BASE_URL,
        APIURL.OVH_DELETE_CODE,
        payload,
        AuthenticatedSmartlockHeaders()
      );
      if (response.status === 200) {
        dispatch(setResponse(response.data));
      }
    } catch (error) {
        alert("Unable to delete code at the moment");
    }
  };
};
