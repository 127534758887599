import React, {Component} from "react";
import {connect} from "react-redux";
import "./styles.css";
import Select from "react-select";
import {Button, TextField} from "@material-ui/core";
import TopBar from "../../../CommonUI/TopBar/topBar";
import ClipLoader from "react-spinners/ClipLoader";
import { Base64 } from 'js-base64';
import * as actions from "./redux/actions";

const PASSWORD_HASHING_CHARACTERS = ["m", "I", "o", "T", "S", "r"];

class HomePage extends Component {
    state = {
        selectedHotel: null,
        selectedRoom: null,
        dataFetched: false,
        checkinName: null,
        passwordData: {
            hotel: null,
            room: null,
            password: null
        }
    };

    async componentWillMount() {
        await this.props.getAllHotels();
    }

    next = async () => {
        if(this.state.selectedHotel === null) {
            alert("Please select a hotel first");
            return;
        }
        await this.props.getHotelDetails({
            hotelId: this.state.selectedHotel
        });
        this.setState({
            dataFetched : true
        })
    };

    generatePassword = (rNo) => {
        if(rNo === null) {
            return;
        }

        let roomNo = rNo.toString();
        let iterations = 6 - roomNo.length;
        for(let i = 0; i < iterations; i++) {
            roomNo += PASSWORD_HASHING_CHARACTERS[i];
        }

        let base64String = Base64.encode(roomNo);

        iterations = base64String.length;
        let passwordString = "";
        for(let i = 0; i < iterations; i+=2) {
            passwordString += base64String[i+1];
            passwordString += base64String[i];
        }
        this.setState({
            passwordData : {
                hotel: this.state.selectedHotel,
                room: rNo,
                password: passwordString.split('').reverse().join('')
            }
        })
    };

    checkin = async () => {
        if (this.state.selectedRoom === null || this.state.checkinName === null) {
            alert("Please input name and select room number");
            return;
        }

        await this.props.newGuestCheckIn({
            hotelId: this.state.selectedHotel,
            roomNo: this.state.selectedRoom,
            checkInName: this.state.checkinName
        });

        if(this.props.error) {
            alert("Could not Check-in");
        }
        else {
            await this.props.getHotelDetails({
                hotelId: this.state.selectedHotel
            });
            alert("Check-in successful");
        }
    };

    checkOut = async (rNo, name) => {
        if(rNo == null || name == null) {
            return;
        }

        let confirmation = window.confirm("Are you sure you want to checkout Room " + rNo);
        if(confirmation) {
            await this.props.guestCheckOut({
                hotelId: this.state.selectedHotel,
                roomNo: rNo,
                checkInName: name
            });
            if(this.props.error) {
                alert("Could not Check-Out");
            }
            else {
                await this.props.getHotelDetails({
                    hotelId: this.state.selectedHotel
                });
                alert("Check-Out successful");
            }
        }
    };

    render() {
        return (
            <div>
                <div>
                    <TopBar/>
                </div>
                {this.state.selectedHotel === null &&
                <p style={{fontSize: "18px", textAlign: "center"}}> Please select your Hotel below</p>
                }
                <div id="hotel-search-bar">
                    <Select
                        onChange={(e) => {
                            if(e === null || e.value === null) {
                                return;
                            }
                            this.setState({selectedHotel: e.value, dataFetched: false})
                        }}
                        options={this.props.hotel}
                        id='react-select'
                        placeholder = "Select hotel..."
                    />
                    <Button variant="contained" color="primary" onClick={this.next} id='details-button' size={"large"}>
                        Fetch Details
                    </Button>
                </div>
                {
                    this.props.error &&
                    <div style={{marginTop: "50px", textAlign: "center"}}>
                        <p>Error Encountered: </p>
                        <p>Some error</p>
                    </div>
                }
                {
                    this.props.loading && !this.props.error &&
                    <div style={{marginTop: "50px", textAlign: "center"}}>
                        <ClipLoader
                            size={50}
                            color={"black"}
                            loading={this.props.loading}
                        />
                    </div>
                }
                {this.state.dataFetched && !this.props.loading && !this.props.error &&
                <div id="bottom-division">
                    <div id="checkin-table">
                        <table>
                            <thead>
                            <tr>
                                <td className="table-head">Name</td>
                                <td className="table-head">Room No.</td>
                                <td className="table-head">Wi-Fi Password</td>
                                <td className="table-head" />
                            </tr>
                            </thead>

                            {this.props.checkins.map((row, i) => {
                                return (
                                    <tbody>
                                    <tr>
                                        <td className="table-button">{row.name}</td>
                                        <td className="table-button">{row.room}</td>
                                        <td>{(this.state.passwordData.room === row.room && this.state.passwordData.hotel === this.state.selectedHotel) ?
                                            this.state.passwordData.password :
                                            <Button variant="outlined" color="inherit" onClick={ () => {this.generatePassword(row.room)}}>GENERATE</Button>}
                                        </td>
                                        <td>
                                            <Button variant="outlined" color="secondary" onClick={() => {this.checkOut(row.room, row.name)}}>CHECK-OUT</Button>
                                        </td>
                                    </tr>
                                    </tbody>
                                )
                            })}
                        </table>
                    </div>
                    <div id="new-checkin">
                        <div id="checkin-form">
                            <div id="new-checkin-text">
                                <p style={{textAlign: "center", fontWeight: "bold", fontSize: "20px"}}> New checkin
                                    ?</p>
                            </div>
                            <div id="form-data">
                                <form>
                                <TextField label="Name" onChange={(e) => {
                                    this.setState({checkinName: e.target.value})
                                }} fullWidth required={true}/>
                                <Select onChange={(e) => {
                                    this.setState({selectedRoom: e.value})
                                }}
                                        options={this.props.rooms}
                                        placeholder="Select Room"
                                        id="input-name"
                                />
                                <Button variant="outlined" color="primary" onClick={this.checkin}>
                                    CHECK-IN
                                </Button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        hotel: state.smartRoomData.hotel,
        rooms: state.smartRoomData.rooms,
        checkins: state.smartRoomData.checkins,
        error: state.smartRoomData.error,
        loading: state.smartRoomData.loading,
        checkInOut: state.smartRoomData.checkInOut
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllHotels: async () => dispatch(actions.getAllHotels()),
        getHotelDetails: async (payload) => dispatch(actions.getHotelDetails(payload)),
        newGuestCheckIn: async (payload) => dispatch(actions.newGuestCheckIn(payload)),
        guestCheckOut: async (payload) => dispatch(actions.guestCheckOut(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
