import { APIURL } from '../../../../Constants/urlConstant';
import { AuthenticatedSmartlockHeaders } from '../../../../Constants/HeadersConstants';
import RESTApi from '../../../../utils/RESTApi';
import * as actionType from './actionTypes';
import logger from 'utils/logger';

const _hideHotelRoomBar = () => {
  return {
    type: 'HotelInput/hideHotelAndRoomNumberBar'
  };
};
export const hideHotelRoomBar = () => {
  return function (dispatch) {
    dispatch(_hideHotelRoomBar());
  };
};

const _setIsLoadingTrue = () => {
  return {
    type: actionType.SET_IS_LOADING_TRUE
  };
};
const _setIsLoadingFalse = () => {
  return {
    type: actionType.SET_IS_LOADING_FALSE
  };
};

const _setRoomList = (payload) => {
  return {
    type: actionType.SET_MORTISE_PROPERTY_DATA,
    payload: payload
  };
};
export const getRoomList = (payload) => {
  return async function (dispatch) {
    try {
      dispatch(_setIsLoadingTrue());
      let relativeUrl = APIURL.SMARTLOCK_GET_MORTISE_DETAILS + '?property-id=' + payload;
      let response = await RESTApi.GET(
        APIURL.SMARTLOCK_BASE_URL,
        relativeUrl,
        AuthenticatedSmartlockHeaders()
      );
      if (response.status === 200) {
        dispatch(_setRoomList(response.data));
      }
      dispatch(_setIsLoadingFalse());
    } catch (error) {
      logger.log(error);
      dispatch(_setIsLoadingFalse());
      alert('Unable to retrieve the property data at the moment');
    }
  };
};

export const clearResponse = () => {
  return {
    type: actionType.CLEAR_RESPONSE
  };
};
const _setResponse = (payload) => {
  return {
    type: actionType.SET_RESPONSE,
    payload: payload
  };
};

export const genCode = (payload) => {
  return async function (dispatch) {
    try {
      dispatch(_setIsLoadingTrue());
      let response = await RESTApi.POST(
        APIURL.SMARTLOCK_BASE_URL,
        APIURL.SMARTLOCK_MORTISE_GEN_CODE,
        payload,
        AuthenticatedSmartlockHeaders()
      );
      if (response.status === 200) {
        dispatch(_setResponse(response.data));
      }
      dispatch(_setIsLoadingFalse());
    } catch (error) {
        logger.log(error);
        dispatch(_setIsLoadingFalse());
        alert('Unable to generate code at the moment');
    }
  };
};

export const viewCode = (payload) => {
  return async function (dispatch) {
    try {
      dispatch(_setIsLoadingTrue());
      let response = await RESTApi.POST(
        APIURL.SMARTLOCK_BASE_URL,
        APIURL.SMARTLOCK_MORTISE_VIEW_CODE,
        payload,
        AuthenticatedSmartlockHeaders()
      );
      if (response.status === 200) {
        dispatch(_setResponse(response.data));
        dispatch(_setIsLoadingFalse());
      }
    } catch (error) {
      logger.log(error);
      dispatch(_setIsLoadingFalse());
      alert('Unable to view code for this room at the moment. Please try later');
    }
  };
};
