import React, { Component } from "react";
import { connect } from "react-redux";
import DateParsingUtil from "./../../utils/DateParsingUtil";
import * as actions from "./redux/actions";
import logger from "utils/logger"

class BookingIdSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingId: "",
      userId: "",
      propId: "",
      roomNo: "",
    };
  }

  componentDidMount() {
    this.props.clearResponse();
    this.setState({
      userId: "",
      propId: this.props.propId,
      roomNo: this.props.roomNo,
      bookingId: this.props.bookingId,
    });

    this.handleSubmit();
  }

  handleBookingIdChange = (e) => {
    if (e.target.value !== null || e.target.value !== undefined) {
      this.setState({
        bookingId: e.target.value,
      });
    }
  };
  changeUserId = (e) => {
    if (e.target.value !== null || e.target.value !== undefined || e.target.value !== "") {
      this.setState({
        userId: e.target.value,
      });
    }
  };
  handleSubmit = async () => {
    let header = {
      email: this.props.email,
      authToken: this.props.authToken,
    };
    let payload = {
      booking_id: this.props.bookingId,
      params_type: "guest",
      user_id: this.props.userId,
    };
    logger.log(payload);
    await this.props.viewCode(payload, header);
    this.setState({
      bookingId: "",
      userId: "",
    });
  };
  render() {
    return (
      <>
        <div className="activityLayout">
          <div className="activity-heading">
            <div className="activity-label">
              Booking Id
              <button className="close-button" onClick={this.props.close}>
                <i className="fa fa-close" />
              </button>
            </div>
          </div>
          {/* <div> */}
          {/* <label>Booking Id: </label>
                        <input type="text" onChange={this.handleBookingIdChange} value={this.state.bookingId}/> */}
          {/* <table className="hotel-Admin-layout">
                            <thead className="tableHead">
                            <tr>
                                <td className="table-heading">Booking ID</td>
                                <td className="table-heading">Code</td>
                                <td className="table-heading">Start Time</td>
                                <td className="table-heading">End Time</td>
                            </tr>
                            <tr>
                                <td className="tableColumn"></td>
                            </tr>
                            </thead>

                        </table> */}
          {/* </div> */}
          {this.props.response ? (
            <div>
              {this.props.response.success ? (
                <div className="response">
                  <p>{"Booking ID : " + this.props.response.booking_id}</p>
                  <p>{"Property ID : " + this.props.response.property_id}</p>
                  {this.props.response.room_no ? (
                    <p>{"Room Number : " + this.props.response.room_no}</p>
                  ) : (
                    <></>
                  )}
                  <p style={{ color: "green" }}>
                    Code : <b>{this.props.response.code}</b>
                  </p>
                  <p style={{ color: "red" }}>
                    {"Valid from : " +
                      DateParsingUtil.changeEpochToStandard(
                        (this.props.response.valid_from +
                          new Date().getTimezoneOffset() * 60 * 1000) /
                          1000
                      )
                        .toString()
                        .slice(0, -34) +
                      "(GMT)"}
                  </p>
                  <p style={{ color: "red" }}>
                    {"Valid upto : " +
                      DateParsingUtil.changeEpochToStandard(
                        (this.props.response.valid_upto +
                          new Date().getTimezoneOffset() * 60 * 1000) /
                          1000
                      )
                        .toString()
                        .slice(0, -34) +
                      "(GMT)"}
                  </p>
                  <p style={{ color: "red" }}>
                    {"Valid from : " + this.props.response.started_at + "(Property Local Time)"}
                  </p>
                  <p style={{ color: "red" }}>
                    {"Valid upto : " + this.props.response.ending_At + "(Property Local Time)"}
                  </p>
                </div>
              ) : (
                <p style={{ color: "red" }}>{this.props.response.message}</p>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    email: state.login.email,
    authToken: state.login.authToken,
    response: state.smartlockButtons.response,
    isLoading: state.smartlockButtons.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    viewCode: async (payload, header) => dispatch(actions.viewCode(payload, header)),
    clearResponse: async () => dispatch(actions.clearResponse()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingIdSearch);
