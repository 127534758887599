import {bleAppDetails as initialState} from './state';

const reducer = (state=initialState, action) => {
    switch(action.type) {
        case "SET_BLE_APP_PASSWORD":
            return {
                ...state,
                passwordData : action.payload
            }
        case "SET_SELECTED_HOTEL_BLE":
            return {
                ...state,
                selectedHotel : action.payload
            }
        default :
            return {
                ...state
            }
    }
}

export default reducer;