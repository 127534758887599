export const FETCH_HOTEL_DETAILS = "SmartRoom/HomePage/FetchHotelDetails";
export const HOTEL_DETAILS_FETCHED = "SmartRoom/HomePage/HotelDetailsFetched";
export const ERROR_HOTEL_DETAILS_FETCH = "SmartRoom/HomePage/ErrorHotelDetailsFetch";

export const FETCH_HOTEL_LIST = "SmartRoom/HomePage/FetchHotelList";
export const HOTEL_LIST_FETCHED = "SmartRoom/HomePage/HotelListFetched";
export const ERROR_HOTEL_LIST_FETCH = "SmartRoom/HomePage/ErrorHotelListFetch";

export const SMARTROOM_NEW_CHECKIN = "SmartRoom/HomePage/NewCheckIn";
export const SMARTROOM_NEW_CHECKIN_SUCCESS = "SmartRoom/HomePage/NewCheckInSuccess";
export const ERROR_SMARTROOM_NEW_CHECKIN = "SmartRoom/HomePage/ErrorNewCheckIn";

export const SMARTROOM_CHECKOUT = "SmartRoom/HomePage/CheckOut";
export const SMARTROOM_CHECKOUT_SUCCESS = "SmartRoom/HomePage/CheckOutSuccess";
export const ERROR_SMARTROOM_CHECKOUT_SUCCESS = "SmartRoom/HomePage/ErrorCheckOut";
