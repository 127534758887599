import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Constants} from '../../../../../Constants/Constants';
import * as actions from '../../redux/actions';
import './V2ActivityButton.css';

class Activity2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedForWhom : "",
            duration: "",
            reasonList: "",
            bookingId: "",
        }
    }

    componentDidMount() {
        let selectedForWhom = Object.keys(this.props.reasons)[0];
        this.setLocalState(selectedForWhom);
        this.props.clearActivityResponse();
    }

    changeMinutesToHours = (duration) => {
        if(duration<60) {
            return (
                <option>{duration} minutes</option>
            )
        }
        else {
            let hours = Math.floor(duration/60);
            let minutes = duration - (hours * 60);
            if(hours===1) {
                hours = hours + " hour";
            } else {
                hours = hours + " hours";
            }
            if(minutes===1) {
                minutes = minutes + " minute"
            } else {
                minutes = minutes + " minutes";
            }
            if(minutes === 0) {
                return (
                    <option>{hours} {minutes}</option>
                )
            }
            else {
                return (
                    <option>{hours}</option>
                )
            }
        }
    }

    setLocalState = (selectedForWhom) => {
        let duration = selectedForWhom === Constants.CustomerType.guest ? false : true;
        let reasonList = selectedForWhom === Constants.CustomerType.staff ? false : true;
        let bookingId = selectedForWhom === Constants.CustomerType.guest ? true : false;

        if(duration) {
            if(selectedForWhom===Constants.CustomerType.agm) {
                duration = this.props.reasons.agm.allowedDurations.map(duration => {
                    return this.changeMinutesToHours(duration)
                })
            }
            else if(selectedForWhom===Constants.CustomerType.staff) {
                duration = this.props.reasons.staff.allowedDurations.map(duration => {
                    return this.changeMinutesToHours(duration)
                })
            }
            else if(selectedForWhom===Constants.CustomerType.owner) {
                duration = this.props.reasons.owner.allowedDurations.map(duration => {
                    return this.changeMinutesToHours(duration)
                })
            }
        }

        if(bookingId) {
            bookingId = <input name="bookingId" type="text" ref="bookingId"></input>
        }

        if(reasonList) {
            if(selectedForWhom===Constants.CustomerType.agm) {
                reasonList = Object.values(this.props.reasons.agm.allowedReasons).map(reason => {
                    return (
                        <option>{reason}</option>
                    )
                })
            }
            else if(selectedForWhom===Constants.CustomerType.guest) {
                reasonList = Object.values(this.props.reasons.guest.allowedReasons).map(reason => {
                    return (
                        <option>{reason}</option>
                    )
                })
            }
            else if(selectedForWhom===Constants.CustomerType.owner) {
                reasonList = Object.values(this.props.reasons.owner.allowedReasons).map(reason => {
                    return (
                        <option>{reason}</option>
                    )
                })
            }
        }

        this.setState({
            ...this.state,
            selectedForWhom : selectedForWhom,
            duration : duration,
            bookingId : bookingId,
            reasonList : reasonList
        })
    }
    
    handleChange = (e) => {
        let selectedForWhom = e.target.value;
        this.setLocalState(selectedForWhom);
        if(this.props.activityResponse.message) {
            this.props.clearActivityResponse();
        }
    }

    getKeyByValue = (object, value) => {
        return Object.keys(object).find(key => object[key] === value);
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        let activityData = {
            hotel_id : this.props.hotelId,
            room_no : this.props.roomNo,
            for_whom : this.state.selectedForWhom,
        }
        let durInMinutes;
        if(this.refs.duration && this.state.selectedForWhom!==Constants.CustomerType.guest) {
            durInMinutes = this.refs.duration.value[0] * 60;
        }
        if(this.refs.duration && this.state.selectedForWhom===Constants.CustomerType.staff) {
            durInMinutes = this.refs.duration.value[0] * 10
        }
        if(this.state.selectedForWhom === Constants.CustomerType.guest) {
            if(this.props.reasons.guest.allowedReasons) {
                let reasonId = this.getKeyByValue(this.props.reasons.guest.allowedReasons, this.refs.reason.value);
                if(this.refs.bookingId!==undefined) {
                    activityData = {
                        hotel_id : this.props.hotelId,
                        room_id : this.props.roomId,
                        for_whom : this.state.selectedForWhom,
                        duration : null,
                        booking_id : this.refs.bookingId.value,
                        reason : this.refs.reason.value,
                        reason_id : reasonId
                    }
                } else {
                    activityData = {
                        hotel_id : this.props.hotelId,
                        room_id : this.props.roomId,
                        for_whom : this.state.selectedForWhom,
                        duration : null,
                        booking_id : null,
                        reason : this.refs.reason.value,
                        reason_id : reasonId
                    }
                }
            }
            await this.props.crsCheckIn(activityData);
            setTimeout(await this.props.getRoomList, 5000, this.props.hotelId);
        }
        else {
            if(this.state.selectedForWhom === Constants.CustomerType.owner) {
                if(this.props.reasons.owner.allowedReasons) {
                    let reasonId = this.getKeyByValue(this.props.reasons.owner.allowedReasons, this.refs.reason.value)
                    activityData = {
                        ...activityData,
                        duration : durInMinutes,
                        booking_id : null,
                        reason : this.refs.reason.value,
                        reason_id : reasonId,
                        for_whom : "co-owner"
                    }
                }
            }
            else if(this.state.selectedForWhom === Constants.CustomerType.staff) {
                activityData = {
                    ...activityData,
                    duration : durInMinutes,
                    booking_id : null,
                    reason : null,
                    reason_id : null
                }
            }
            else if(this.state.selectedForWhom === Constants.CustomerType.agm) {
                if(this.props.reasons.owner.allowedReasons) {
                    let reasonId = this.getKeyByValue(this.props.reasons.agm.allowedReasons, this.refs.reason.value)
                    activityData = {
                        ...activityData,
                        duration : durInMinutes,
                        booking_id : null,
                        reason : this.refs.reason.value,
                        reason_id : reasonId
                    }
                }
            }
            await this.props.getActivityResponse(activityData);
            setTimeout(await this.props.getRoomList, 5000, this.props.hotelId);
        }
    }
    render(){
        return(
            <div>
                <div className = "Hotel-Details">
                    <div className="Hotel-Details-Text">Room No: <b>{this.props.roomNo}</b></div>
                    <div className="Hotel-Details-Text">Device ID: <b>{this.props.deviceId}</b></div>
                    <div className="Hotel-Details-Text">Booking ID: <b>{this.props.bookingId}</b></div>
                </div>
                <div className = "Wifi-Details">
                    <div className="Wifi-Details-Text">Wifi Name: <b>{this.props.wifiSsid}</b></div>
                    <div className="Wifi-Details-Text">Wifi Password: <b>{this.props.wifiPassword}</b></div>
                </div>

                <form className="activity-form" onSubmit={this.handleSubmit}>
                    <div className="form-input">
                        <label className = "form-label">For Whom: </label>
                        <select name="forWhom" onChange={this.handleChange} className="dropdown" required>
                            {   Object.keys(this.props.reasons).map(forWhom => {
                                return (
                                    <option>{forWhom}</option>
                                )
                            }) 
                            }
                        </select>
                     </div>
                     { this.state.duration ? 
                       <div className="form-input">
                            <label className = "form-label">Duration: </label>
                            <select name="duration" ref="duration" className="dropdown" required>
                                {this.state.duration}
                            </select>
                        </div>
                        : <div></div>
                     }
                     { this.state.bookingId && this.props.isCommissioned ? 
                       <div className="form-input">
                            <label className = "form-label">Booking ID: </label>
                            <input className = "text-box" name="bookingId" type="text" ref="bookingId" placeholder="Enter booking ID" required></input>
                        </div>
                        : <div></div>
                     }
                     { this.state.reasonList ? 
                            <div className="form-input">
                                    <label className = "form-label">Reason: </label>
                                    <select name="reason" ref="reason" className="dropdown" required>
                                        {this.state.reasonList}
                                    </select>
                            </div>
                        : <div></div>
                     }
                     <button type="submit" className="submit-button-activity">Turn Lights On</button>
                </form>
                { this.props.activityResponse.message !== undefined ? 
                  <div className="Activity-Response">
                      { this.props.activityResponse.is_success ? 
                        this.props.deviceOnline ?
                        <p style={{color:'green'}}>{this.props.activityResponse.message}. Refreshing room list in 5s</p> 
                        : <p style={{color:'red'}}>Your request has been acknowledged. Refreshing room list in 5s</p>
                        : <p style={{color:'red'}}>{this.props.activityResponse.message}. Refreshing room list in 5s</p> 
                      }
                  </div> :
                  <div className="Activity-Response"></div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        reasons: state.hotel.activityReasons.reasons,
        activityResponse : state.hotel.v2ActivityResponse
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getActivityResponse : async (payload) => await dispatch(actions.getV2ActivityResponse(payload)),
        clearActivityResponse : async () => await dispatch(actions.clearV2ActivityResponse()),
        getRoomList : async (hotelId) => await dispatch(actions.getRooms(hotelId)),
        crsCheckIn : async (payload) => await dispatch(actions.crsCheckInSync(payload))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Activity2);