import {mortiseData as initialState} from './state';
import * as actionType from './actionTypes';

const reducer = (state=initialState, action) => {
    switch(action.type) {
        case actionType.SET_IS_LOADING_TRUE:
            return {
                ...state,
                isLoading: true
            }
        case actionType.SET_IS_LOADING_FALSE:
            return {
                ...state,
                isLoading: false
            }
        case actionType.SET_MORTISE_PROPERTY_DATA:
            return {
                ...state,
                roomList: action.payload
            }
        case actionType.CLEAR_RESPONSE:
            return {
                ...state,
                response: {}
            }
        case actionType.SET_RESPONSE:
            return {
                ...state,
                response: action.payload
            }
        default:
            return state;
    }
}

export default reducer;